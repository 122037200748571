import React, { useState } from 'react'
import {useHistory} from "react-router-dom"
import { CloudStorageAction, SpaceItem, SpaceItemType } from '../../../../../../stores/reducers/spaces.interface';
import Modal from 'react-modal';
import { Stores } from '../../../../../../stores/stores'
import DeleteIcon from "../../../../../../assets/SVG/style-dark-1/52.svg"

import './breadcrumb.scss'
import Tippy from '@tippyjs/react';

interface BreadcrumbProps {
     Selected? : boolean ;
     DeleteHandle? : any;
     LengthItem? : number ;
     ViewMode?: any ;
     ClearSelected ? : any ;
}

function Breadcrumb({Selected,DeleteHandle,LengthItem,ViewMode,ClearSelected}: BreadcrumbProps) {
     const [isOpenModal, setIsOpenModal] = useState(false)
     const history = useHistory()
     var Prefixes = window.location.pathname.split('/').slice(1).filter( e => e !== "" )

     function setLoad() {
          Stores.dispatch({type : CloudStorageAction.Load, payload : ""})
     }

     function ChangeDirectory(index:number){
          setLoad()
          const BreadcrumbPath = Prefixes.filter( (e,i) => i <= index).join('/') + "/"
          history.push(`/${BreadcrumbPath}`)
          ClearSelected()
     }

     function GoHome() {
          setLoad()
          history.push(`/`)
          ClearSelected()
     }

     function RenderItems() {
          return Prefixes.map( (prefix, index) => {
               return index === Prefixes.length - 1 ?
                    (<Tippy content="Reload" >
                         <li key={index+1} onClick={() => {ChangeDirectory(index)}} className="breadcrumb-item active" aria-current="page">
                              <span>{decodeURI(prefix)}</span>
                         </li>
                    </Tippy>
                    )
               :
                    (<Tippy content={decodeURI(prefix)} placement='top' disabled={decodeURI(prefix).length <= 20} >
                         <li key={index} onClick={() => {ChangeDirectory(index)}} className="breadcrumb-item">
                              <span>{decodeURI(prefix)}</span>
                         </li>
                    </Tippy>
                    )
               }
          )
     }

     return (
          <nav aria-label="breadcrumb" className="folder-tree d-flex">
               <ol className="breadcrumb ms-2">
                    <Tippy content="Reload SRF SPACES" placement='top'>
                         <li className="breadcrumb-item ms-1" onClick={() => {GoHome()}}>
                              <div className="link">Home</div>
                         </li>
                    </Tippy>
                    {RenderItems()}
               </ol>
               <div className="ms-auto me-2" >
                    <button className="btn btn-sm btn-dark me-1" style={{width:90}} hidden={ViewMode==="grid" || !Selected} onClick={()=>setIsOpenModal(true)} >
                         <i className="fad fa-trash me-2"></i>Delete
                    </button>
               </div>

               <Modal
                    isOpen={isOpenModal}
                    onRequestClose={()=>setIsOpenModal(false)}
                    closeTimeoutMS={250} 
                    style={{
                    overlay: {
                         zIndex: 10000,
                         position: 'fixed',
                         top: 35,
                         left: 0,
                         right: 0,
                         bottom: 0,
                         backgroundColor: 'rgba(0, 0, 0, 0.75)',
                    },
                    content: {
                         top: '50%',
                         left: '50%',
                         right: 'auto',
                         bottom: 'auto',
                         marginRight: '-50%',
                         background: '#3d3d3d',
                         transform: 'translate(-50%, -50%)',
                         border: 'none',
                         padding:'0',
                         cursor: 'default',
                    },
                    }}
                    contentLabel="Delete files modal"
                    >
                    <div className="p-5 mt-2">
                         <div className="modal-header">
                              <div className=" text-center ">
                                   <h4 className="fw-bold">Delete files request !</h4>
                                   <p> Are your sure to delete {LengthItem} files ?</p>
                              </div>
                         </div>
                         <div className="modal-body">
                              <div className="logo me-auto my-4 py-5" style={{backgroundSize:"cover",height:"135px",width:"165px",backgroundRepeat:"no-repeat", marginLeft:60,backgroundImage:`url("${DeleteIcon}")`}}/>
                              <div className="d-flex gap-4 pt-5">
                                   <button className="rounder-btn" onClick={()=>{setIsOpenModal(false)}}> cancel </button>
                                   <button className="rounder-btn" onClick={()=>{DeleteHandle();setIsOpenModal(false)}}> YES DELETE IT ! </button>
                              </div>
                         </div>
                       
                        
                    </div>
               </Modal>
          </nav>
     )
}
export default  Breadcrumb;