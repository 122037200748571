import React, { useEffect, useState } from 'react'
import './login-form.scss'
import logo from '../../assets/logo.png'
import bg from './bg2.png'
import axios from 'axios'
import { LoginApiResponse } from '../../Interfaces/Http'
import { IpcSender } from '../../Interfaces/IpcRenderer.enum'
import isOlderThanVersion from 'Helpers/version-compare'
import { decode } from 'jsonwebtoken'
const { ipcRenderer } = window.require('electron')
const electron = window.require('electron')
type LoginFormProps = {

}


export default function LoginForm(Props: LoginFormProps) {

    const [UserEmail, setUserEmail] = useState("")
    const [UserPassword, setUserPassword] = useState("")
    const [isValidateFailed, setIsCorrect] = useState(false)
    const [rememberMe, setRememberMe] = useState<boolean>(true)


    const toggleRememberMe = () => {
        setRememberMe(!rememberMe)
    }

    async function SignInHandler() {

        if (UserEmail.length === 0 || UserPassword.length === 0) {
            setIsCorrect(true)
            return;
        }
        try {
            let Response = await axios.post<LoginApiResponse>('https://olapi.superrendersfarm.com/api/user/login', {
                email: UserEmail,
                password: UserPassword
            })

            if (Response?.data?.token) {
                const token = Response?.data?.token;
                // get app version and check
                ipcRenderer.invoke('app-version')
                    .then(async (currentVersion: string) => {

                        // if the version larger than the 2.1.16 then update the region.
                        const data = decode(token) as any;
                        if (data.LATEST_REGION != data.AWS_REGION) {
                            await axios.post('https://olapi.superrendersfarm.com/api/uwf/update-region', { region: 'ap-southeast-1' }, {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                    "Content-Type": "application/json"
                                }
                            })
                                .finally(() => {
                                    ipcRenderer.send(IpcSender.ShowMessageBox, {
                                        title: "Incorrect login credentials",
                                        message: "Incorrect login credentials, please try again",
                                        buttons: ["OK", "Cancel"],
                                        icon: "warning",
                                        defaultId: 0,
                                        cancelId: 1,
                                    })
                                })

                        } else {
                            // Send token to main process
                            ipcRenderer.send("login-success", Response.data)
                            // clear recent logged in sessions
                            ipcRenderer.send("clear-authenticated-sessions")
                            // save jwt access token to local storage
                            localStorage.setItem("access_token", Response.data.token);
                            // Request to set user configuration
                            ipcRenderer.send(IpcSender.SetCredentials)
                        }

                    })
                return;
            }

            ipcRenderer.send(IpcSender.ShowMessageBox, {
                title: "Login Failed",
                message: "Your username, password or email is incorrect. Please try again.",
                buttons: ["OK", "Cancel"],
                defaultId: 0,
                cancelId: 1,
            })


        } catch (error: any) {
            console.log(error.response)
            ipcRenderer.send(IpcSender.ShowMessageBox, {
                title: error?.response?.statusText || "Critical Error",
                message: error?.response?.data?.message || "Something went wrong. Please try again.",
                buttons: ["OK", "Cancel"],
                icon: "warning",
                defaultId: 0,
                cancelId: 1,
            })
        }

    }
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            console.log('do validate')
            SignInHandler()
        }
    }
    return (
        <div className="login-form" style={{ backgroundSize: "cover", backgroundPosition: "left", backgroundRepeat: "no-repeat", backgroundImage: `url("${bg}")` }}>
            <div className="titlebar d-flex">
                <div className="drag"></div>
                <div className="ms-auto">
                    <div className="d-flex">
                        <button className="titlebar-button minimizeBtn" type="button" onClick={() => {
                            ipcRenderer.send(IpcSender.MinimizeThisWindow)
                        }} >
                            <i className="fal fa-window-minimize"></i>
                        </button>
                        <button className="titlebar-button closeBtn" type="button" onClick={() => window.close()}>
                            <i className="fal fa-times"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="row">
                    <div className="col-md-6">
                        <div className="display-5 fw-bold" style={{ marginTop: "7rem" }} >SUPER RENDERS<br />FARM</div>
                    </div>
                    <div className="col-md-6 ps-5">
                        <div className="d-flex mb-3">
                            <div className="col-10">
                                <div className="fw-light fs-6">Welcome,</div>
                                <div className="fw-bold fs-5">Have nice days</div>
                            </div>
                            <div className="col-2">
                                <div className="logo ms-auto" style={{ backgroundSize: "cover", height: "60px", width: "60px", backgroundRepeat: "no-repeat", backgroundImage: `url("${logo}")` }}></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-floating mb-3">
                                    <input
                                        type="email"
                                        value={UserEmail}
                                        onChange={e => {
                                            let Email = e.target.value as string
                                            setUserEmail(Email)
                                        }}
                                        onKeyDown={handleKeyDown}
                                        className={isValidateFailed ? "border border-danger text-danger form-control" : "form-control"}
                                        id="floatingInput"
                                        placeholder="name@example.com"
                                    />
                                    <label htmlFor="floatingInput">Email</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="password"
                                        value={UserPassword}
                                        onChange={e => {
                                            let Password = e.target.value as string
                                            setUserPassword(Password)
                                        }}
                                        onKeyDown={handleKeyDown}
                                        className={isValidateFailed ? "border border-danger text-danger form-control" : "form-control"}
                                        id="floatingPassword"
                                        placeholder="Password"
                                    />
                                    <label htmlFor="floatingPassword">Password</label>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" checked={rememberMe} id="remember-me" onChange={toggleRememberMe} />
                                    <label className="form-check-label" htmlFor="remember-me" >
                                        Remember me
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="d-grid mb-3">
                            <button onClick={SignInHandler} className="btn btn-success">Sign in</button>
                        </div>
                        <div className="d-flex">
                            <div className="me-auto sign-up">
                                {/* Sign up */}
                                <button className="btn btn-sm ps-0" onClick={() => {
                                    electron.shell.openExternal("https://superrendersfarm.com/account/register")
                                }}>Sign up</button>
                            </div>
                            <div className="ms-auto forgot-password">
                                <button className="btn btn-sm pe-0" onClick={() => {
                                    electron.shell.openExternal("https://superrendersfarm.com/account/forgot-password")
                                }}>Forgot password</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer content fixed-bottom">
                <div className="d-flex">
                    {/* 
                // Currently not in use
                // Srf was not support multiple zone yet
                <div className="me-auto">
                    <div className="server">
                        <i className="fad fa-globe-europe fa-lg"></i>
                        <span className="server-name">North Virginia</span>
                        <span className="server-quality">Good</span>
                    </div>
                </div> */}

                </div>
            </div>
        </div>
    )
}