import { IpcResponse } from "./Interfaces/IpcRenderer.enum";
import { ENotification } from "./stores/reducers/nofification.interface";
import { CloudStorageAction } from "./stores/reducers/spaces.interface";
import { Stores } from "./stores/stores";

const {ipcRenderer} = window.require('electron');

async function ReloadSrfSpaces(){
    Stores.dispatch({ type: CloudStorageAction.SagaFetch, payload :  window.location.pathname })
}

export async function IpcEventHandler() {
    ipcRenderer.on(IpcResponse.OpenFolderDialog, ()  => {
        Stores.dispatch( { type : ENotification.SendMessage , payload : "Uploading your files! To view detailed progress please click on the icon tray " })
    })

    // Request reload SRF Spaces
    ipcRenderer.on(IpcResponse.ReloadClouStorage, ReloadSrfSpaces )
}