import ChillIcon from "./Chill.svg"
export default function NoActions() {
    return (<div className="noAciton-trayBar" >
                <div className="m-auto">
                    <div className="logo ms-2 mt-3" style={{backgroundSize:"cover",height:"150px",width:"150px",backgroundRepeat:"no-repeat",backgroundImage:`url("${ChillIcon}")`}}/>
                    <p className="mt-3">
                        <h5>There is no actions</h5>
                    </p>
                </div>
            </div>)
}