// Idk what is this.
// :>
export enum PHPBOOL {
    True = "1",
    False = "0",
    Yes = "yes",
    No = "no",
    On = "on",
    Off = "off",
    One = "1",
    Zero = "0",
}

export enum CloudType {
    AwsS3 = "awss3",
    Dropbox = "dbx",
    GoogleDrive = "gdrive"
}

export enum JobType {
    Animation = "Animation",
    StillImage = "Stillimage",
    Single = "single"
}

export enum VrayGlobalIlluminationPrimaryName {
    IrradianceMap = "Irradiance Map",
    PhotonMap = "Photon Map",
    BruteForce = "Brute Force",
    LightCache = "Light Cache"
}

export enum VrayGlobalIlluminationSecondaryName {
    None = "None",
    PhotonMap = "Photon Map",
    BruteForce = "Brute Force",
    LightCache = "Light Cache"
}

export enum VrayGIPrimaryType {
    IrradianceMap = "0",
    PhotonMap = "1",
    BruteForce = "2",
    LightCache = "3"
}

export enum VrayGISecondaryType {
    None = "0",
    PhotonMap = "1",
    BruteForce = "2",
    LightCache = "3"
}

export enum LightCacheMode {
    None = "0",
}

export enum IrradMapMode {
    SingleFrame = 0,
    MultiFrameIncremental = 1,
    FromFile = 2,
    AddToCurrentMap = 3,
    IncrementalAddToCurrentMap = 4,
    Bucket = 5,
    AnimationPrepass = 6,
    AnimationRendering = 7,
}

export enum IrradMapModeName {
    SingleFrame = "Single frame",
    MultiFrameIncremental = "Multiframe incremental",
    FromFile = "From file",
    AddToCurrentMap = "Add to current map",
    IncrementalAddToCurrentMap = "Incremental add to current map",
    Bucket = "Bucket",
    AnimationPrepass = "Animation (Prepass)",
    AnimationRendering = "Animation (Rendering)",
    Path = "Path"
}

export enum LightCacheMode {
    SingleFrame = 0 ,
    FlyThrough = 1,
    FromFile = 2,
    ProgressivePathTracking = 3,
}

export enum LightCacheModeName {
    SingleFrame = "Single frame",
    FlyThrough = "Fly fhrough",
    FromFile = "From file",
    ProgressivePathTracking = "Progressive path tracking"
}

export enum ImageSamplerType {
    Bucket = 1,
    Progressive = 3
}
export enum ImageSamplerTypeName {
    Bucket = "Bucket",
    Progressive = "Progressive"
}

export enum FarmManagerSoftware {
    Deadline = "deadline"
}

export enum SceneAnalyzeStatus {
    SceneAnalyzed = "scene_analyze",
    FromLocal = "from_local"
}

export enum RenderProcessor {
    CPU = "cpu",
    GPU = "gpu"
}

export enum IndexOf {
    First = 0,
    Second = 1,
    Third = 2,
    NotFound = -1,
    NotListedIn = -1,
    NotMatch = -1,
}
export const SceneExtension = [
    "ma", // Maya
    "mb", // Maya
    "max",
    "hou",
    "blend",
    "fbx", //
    "c4d",
]

export enum SoftwareVersion {
    Undefined = "undefined",
    Autodesk3dsMax2022 = "3dsmax_2022",
    Autodesk3dsMax2021 = "3dsmax_2021",
    Autodesk3dsMax2020 = "3dsmax_2020",
    Autodesk3dsMax2019 = "3dsmax_2019",
    Autodesk3dsMax2018 = "3dsmax_2018",
    Autodesk3dsMax2017 = "3dsmax_2017",
    Autodesk3dsMax2016 = "3dsmax_2016",
    Autodesk3dsMax2015 = "3dsmax_2015",
}
export enum RenderEngineVersion {
    Undefined = "undefined",
    Redshift3_0_Gpu = "redshift3_0_gpu",
    Vray_5 = "vray_5",
    Vray_5_Cpu = "vray_5_cpu",
    Vray_5_Gpu = "vray_5_gpu",
    Vray_Next_Cpu = "vray_next_cpu",
    Vray_Next_Gpu = "vray_next_gpu",
    Vray_4_2_Cpu = "vray_4_2_cpu",
    Vray_3 = "vray_3",
    Vray4phoenix4 = "vray4phoenix4",
    Vray3phoenix3 = "vray3phoenix3",
    Corona6 = "corona6",
    Corona5 = "corona5",
    Corona4 = "corona4",
    Corona3 = "corona3",
    Corona2 = "corona2",
    Corona17 = "corona17",
    Corona16 = "corona16",
    Arnold4_0_1_Cpu = "arnold4_0_1_cpu",
    Arnold4_0_1_Gpu = "arnold4_0_1_gpu",
    Arnold3 = "arnold3",
    Quicksilver_Processors = "quicksilver_processors",
    Art = "art",
    Scanline = "scanline",
    Veu_File = "veu_file",
}

export enum Processor {
    Cpu = "cpu",
    Gpu = "gpu"
}