import React, { useState } from 'react'
import { connect } from 'react-redux'
import { OutputParamsPropertyKeys } from '../../../../../screens/RenderNow/RenderNow.interface'
import { RootState } from '../../../../../stores/stores'
import { OutputSettingsDistpatchs } from '../OutputSettings/OutputSettings.distpatchs'
import './gamma-correction.scss'

const MapStates = (States: RootState) => ({
     Renders : States.Render
})

type GammaCorrectionProps = ReturnType<typeof MapStates> & typeof OutputSettingsDistpatchs &  {
}

function GammaCorrection(Renders: GammaCorrectionProps) {
     const [gamma, setGamma] = useState(Renders?.Renders?.body?.scene?.params?.output_param?.gamma_correction)
     function switchGamma() {
          setGamma(!gamma)
          Renders.updateOutputProperties(OutputParamsPropertyKeys.gamma_correction, !gamma );
     }
     return (
          <>
               <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch" id="gamma" onClick={()=>switchGamma()}
                         checked={gamma}
                    />
                    <label className="form-check-label" htmlFor="gamma">Gamma Correction</label>
               </div>
               {
                    gamma?<div className="row">
                    <div className="form-group col-6">
                         <label htmlFor="input_gamma" className="control-label">
                              Input Gamma*
                         </label>
                         <input id="input_gamma" type="number"
                              className="form-control srf_require_field" placeholder="Input Gamma"
                              defaultValue="2.2"
                              onChange={ e => {
                                   Renders.updateOutputProperties(OutputParamsPropertyKeys.input_gamma, e.target.value);
                              }}
                              value={Renders?.Renders?.body?.scene?.params?.output_param?.input_gamma}
                              />
                    </div>
                    <div className="form-group col-6">
                         <label htmlFor="output_gamma" className="control-label">
                              Output Gamma*
                         </label>
                         <input id="output_gamma" type="number"
                              className="form-control srf_require_field" placeholder="Output Gamma"
                              defaultValue={1}
                              onChange={ e => {
                                   Renders.updateOutputProperties(OutputParamsPropertyKeys.output_gamma, e.target.value);
                              }}
                              value={Renders?.Renders?.body?.scene?.params?.output_param?.output_gamma} />
                    </div>
                    </div> : <></>
               }
          </>
     )
}

export default connect( MapStates,OutputSettingsDistpatchs)(React.memo(GammaCorrection))