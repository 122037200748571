import axios from "axios";
import { API } from "../../Interfaces/Api.enum";
import { AnalysisActionType } from "../reducers/analysis.interface";
import { ENotification } from "../reducers/nofification.interface";
import { Stores } from "../stores";
import { ApiListRendersResponse, IRender, RenderListActionType } from '../reducers/render_list.interface';
import { IpcSender } from "../../Interfaces/IpcRenderer.enum";
const {ipcRenderer} = window.require('electron')


export async function SagaFetchListRenderAsync() {
    //
    var startFetchTime = Date.now()
    // Get list scene analysis from api
    axios.get<ApiListRendersResponse>(API.ListRecentRenderJobs,{
        headers : {
            'Authorization' : `Bearer ${localStorage.getItem("access_token")}`
        }
    })
    .then( async res => {

        const mapperList = Promise.all(res.data.data.list.map( async ( j) => {
            // IpcSender.ValidateOutputFolders
            let jmapp = j

            const isHasFolderOutput = await ipcRenderer.invoke(IpcSender.ValidateOutputFolders, j.renderoutput_folder_name || "" )

            jmapp.is_output_folder_available = isHasFolderOutput
            jmapp.num_nodes_run = 0

            return jmapp
        }))
        // Set loading to default
        Stores.dispatch({type : RenderListActionType.SetLoading, payload : false})
        // Dispatch action to store
        Stores.dispatch({
            type : RenderListActionType.SetItems,
            payload : await mapperList
        })
        Stores.dispatch( { type : ENotification.SetLoadTime , payload : ( Date.now() - startFetchTime ) } )

    })
}