import React, {useState} from 'react'
import { SceneResponseInterface } from '../../../../../screens/RenderNow/RenderNow.interface'
import './camera.scss'
import {connect} from 'react-redux'
import { RootState, Stores } from '../../../../../stores/stores';
import { CameraDispatchs } from './Camera.dispatch';


const MapStates = (States:RootState) => ({
     RenderScene : States.Render
})

type CameraProps = (ReturnType<typeof MapStates>) & typeof CameraDispatchs

function Camera(Renders: CameraProps) {
     const [RenderScene, setRenderScene] = useState(Renders.RenderScene)
     // Render list cameras
     function RenderCamera() {

          // Handle Camera change
          const ActiveCameraChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
               const ActiveCamera : string = event.target.value
               Renders.SetRenderCamera(ActiveCamera)
               let NewCameraState = Stores.getState().Render.body.scene.params.camera_param.activeCamera
               console.log(`Active camera : ${NewCameraState}`)
          }
          // Render list of camera if scene json available
          return (
               <div className="row col">
                    <div className="col-8">
                         <select onChange={ActiveCameraChanged} className="form-select" aria-label="Default select example">
                              <option >Choose camera</option>
                              { RenderScene.body.scene.params.camera_param.cameras_list.map( (camera,camera_index) => (
                                   <option key={camera_index} selected={RenderScene.body.scene.params.camera_param.activeCamera == camera.camera_key} value={camera.camera_key} >{camera.camera_name}</option>
                              ))}
                         </select>
                    </div>
                    <div className="col-4">
                    </div>
               </div>
          )
     }

     return (
          <div className="d-flex mb-3">
               <div className="col-1 mt-auto">
                    <label className="form-label">Camera </label>
               </div>
               { RenderCamera() }
          </div>
     )
}

export default connect( MapStates, CameraDispatchs)(React.memo(Camera))