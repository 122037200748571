import { useState } from 'react';
import Modal from 'react-modal';
import { IpcSender } from '../../../../../Interfaces/IpcRenderer.enum';

const { ipcRenderer } = window.require('electron')


export type CreateFolderModalProps = {
}
export function CreateFolderModal({ }: CreateFolderModalProps) {
     const [createFolder, setIsOpenCreateFolder] = useState(false)
     const [createDirectory, setCreateDirectory] = useState<string>("")
     return (<>
          <button className="btn upload-button btn-sm" type="button" onClick={() => setIsOpenCreateFolder(true)}>
               <i className="fad fa-folder-plus"></i> Create Folder
          </button>
          <Modal
               isOpen={createFolder}
               onRequestClose={() => setIsOpenCreateFolder(false)}
               closeTimeoutMS={250}
               style={{
                    overlay: {
                         zIndex: 10000,
                         position: 'fixed',
                         top: 35,
                         left: 0,
                         right: 0,
                         bottom: 0,
                         backgroundColor: 'rgba(0, 0, 0, 0.75)',
                    },
                    content: {
                         top: '50%',
                         left: '50%',
                         right: 'auto',
                         bottom: 'auto',
                         marginRight: '-50%',
                         background: '#343434',
                         transform: 'translate(-50%, -50%)',
                         border: 'none',

                         width: '850px',
                         padding: '0',
                         cursor: 'default',
                    },
               }}
               contentLabel="Create Folder Modal"
          >
               <div className="close-button">
                    <button className="btn" onClick={() => setIsOpenCreateFolder(false)}><i className="fal fa-times"></i></button>
               </div>
               <div className="p-5">
                    <div className="modal-header pt-4">
                         <div className="px-3 mx-3">
                              <h4 className="fw-bold text-center mb-3">Create New Folder</h4>
                              <p className="text-center mb-3">Choose folder to simulate your local directory</p>
                              <p className="create-folder-text">
                                   - To simulate an absolute path just like one on your local computer, you can create corresponding folder and upload needed assets into it.
                                   <br /><br />
                                   - For example, your textures is located at "Z:\textures\texture_collection\", please type the path to the above field and hit Save. The folder will be created and ready for you to upload.
                                   <br /><br />
                                   - Supported formats: "Z:/textures/texture_collection", or "Z/textures/texture_collection" or "Z:\textures\texture_collection"
                              </p>
                         </div>
                    </div>
                    <div className="modal-body px-5" >
                         <div className="input-group input-group-sm mb-3 shadow">
                              <span className="input-group-text text-light bg-dark border-0" id="folder-name">Folder name or Path:</span>
                              <input
                                   type="text"
                                   className="form-control path-folder"
                                   aria-label="folder name or path"
                                   aria-describedby="folder-name"
                                   value={createDirectory}
                                   onChange={e => setCreateDirectory(e.target.value)}
                                   placeholder="e.g: D\projects\mybuilding"
                              />
                              <button className="btn btn-secondary" type="button" id="folder-name" onClick={
                                   async () => {
                                        // ipcRenderer.send(IpcSender.CreateFolder,"", window.location.pathname)
                                        setCreateDirectory(await ipcRenderer.invoke(IpcSender.RequestFolderPath, "",))
                                        ipcRenderer.send(IpcSender.createSrfFolder, "", window.location.pathname)
                                   }}>Choose folder</button>
                         </div>


                         <small className="fst-italic text-muted">
                              *Examples: "D:/projects/mybuilding" or "D/projects/mybuilding" or "D:\projects\mybuilding"
                         </small>
                         <div className="mt-5 d-flex gap-5">
                              <button className="rounder-btn ms-auto" onClick={() => setIsOpenCreateFolder(false)}>CANCEL</button>
                              <button className="rounder-btn me-auto" disabled={createDirectory === ""}
                                   onClick={
                                        () => {
                                             ipcRenderer.send(IpcSender.CreateFolder, createDirectory, window.location.pathname)
                                             setIsOpenCreateFolder(false)
                                        }
                                   }>CREATE FOLDER</button>
                         </div>
                    </div>
               </div>
          </Modal>
     </>
     )
}