import React, { useState } from 'react'
import { connect } from 'react-redux';
import { RootState } from '../../../../../stores/stores';
import './render-setting.scss'
import { RenderSettingDispatcher } from './RenderSettings.dispatchs';


const MapStates = (States:RootState) => ({
     Scene : States.Render
})

type RenderSettingProps = typeof RenderSettingDispatcher & ReturnType<typeof MapStates>
function RenderSetting(Renders: RenderSettingProps) {

     const [FramePerNode, setFramePerNode] = useState(Renders.Scene.render_configuration.frame_per_task)

     // Handle when input value has been changed
     function ChangeFramePerNode(event:React.ChangeEvent<HTMLInputElement>){
          // parse from string
          var _Frame = parseInt(event.target.value)
          // set value
          setFramePerNode(_Frame)
          // Update frame per task in redux store
          Renders.SetFramePerTask(_Frame)
     }
     return (
          <div className="row">
               <div className="col-6">
                    <span className="input-group-text">Frames Per Node<span className="text-danger">*</span></span>
                    <input value={FramePerNode} onChange={ChangeFramePerNode} type="number" className="form-control" id="frame_per_task" aria-describedby="frame_per_task_help" min="1" />
                    <div id="frame_per_task_help" className="form-text">Recommend set number of frames per node &gt; 1 if your scene require less than 10 minutes to render 1 frame.</div>
               </div> 
               <div className="col-6">
                    <span className="input-group-text">Render Priority</span>
                    <select id="render_priority" onChange={ e => {
                         var PriorityNumber:number = parseInt(e.target.value)
                         // Set renders
                         Renders.SetRenderPriority(PriorityNumber)
                    }} className="form-select" name="render_priority">
                         <option value="10">bronze - 0.004 Render Credits / GHz-hr - 20 render nodes</option>
                         <option value="20">silver - 0.008 Render Credits / GHz-hr - 80 render nodes</option>
                         <option value="30">gold - 0.016 Render Credits / GHz-hr - 200+ render nodes</option>
                    </select>
               </div>
          </div>
     )
}
export default connect( MapStates,RenderSettingDispatcher)(React.memo(RenderSetting))