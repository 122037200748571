import React, { useEffect, useState } from 'react'
import './plugins-manager.scss'
import Modal from 'react-modal';
import { Software3DTypes, InvokeHandleMutation, IpcResponse, IpcSender } from '../../../../Interfaces/IpcRenderer.enum';
import { AuthorizationRequest, RestApi } from '../../../../apis/default';

const { ipcRenderer } = window.require("electron")
interface PluginsManagerProps {
}

export interface I3dsApplication {
     name: string;
     version: number;
     path: string;
     mxp: string[];
     assets?: any[];
     interfaceUI?: string;
     interfaceUIs?: string[];
     installedPluginVersion?: string
}

export default function PluginsManager({ }: PluginsManagerProps) {
     const [isWaiting, setIsWaiting] = useState<boolean>(true);
     const [preference, setPreference] = useState(false)
     const [Applications, setApplications] = useState<Software3DTypes[]>([])
     const [A3dsMaxPluginVer, set3dsMaxPluginVer] = useState<string>("1.0.0")
     const [MayaPluginsVer, setMayaPluginVer] = useState<string>("1.0.0")
     useEffect(() => {

          ipcRenderer.invoke(InvokeHandleMutation.IsSwitchedFromAdmin)
               .then(async (isSwitchFromAdmin: boolean) => {
                    console.log('is switch from admin', isSwitchFromAdmin)
                    if (!isSwitchFromAdmin) {
                         // Update PC Infor
                         ipcRenderer.send(IpcSender.UpdatePcInfor)

                         // Update application infor
                         ipcRenderer.invoke("app-version").then(_ver => {
                              // send app version to api
                              AuthorizationRequest.post('/user/me/update-my-current-plugin-version', {
                                   current_client_app_version: _ver
                              })
                         })
                    }
               })

          setTimeout(() => {
               setIsWaiting(false)
          }, 500);

          ipcRenderer.invoke(InvokeHandleMutation.Scan3dsSoftware)
               .then((result: { lists: Software3DTypes[], versions: { a3dsMax: string, maya: string } }) => {
                    setApplications(result.lists)
                    console.log(result)
                    return result.versions
               })
               .then((data) => {
                    set3dsMaxPluginVer(data.a3dsMax.toString().split('').join('.'))
                    setMayaPluginVer(data.maya.toString().split('').join('.'))
               })

     }, [])
     if (isWaiting) {
          return (
               <div style={{ height: 'calc(100vh - 130px)' }}>
                    <div className="text-center d-flex h-100">
                         <div className="m-auto">
                              <h5 className="mb-3">Loading your plugins...</h5>
                              <div className="spinner-border spinner-border-lg fast" role="status">
                                   <span className="sr-only">Loading...</span>
                              </div>
                         </div>
                    </div>
               </div>
          )
     }
     return (
          <>
               <div className="plugins-manager">

                    <div className="plugin-topbar" >
                         <div className="alert alert-success" role="alert">
                              By default, the application automatically detects your software and automatically matches and installs the SuperRenders plugin. <br />
                              Please check the installation status following lists:
                         </div>
                         <div className="plugin-button">
                              <button type="button" onClick={() => {

                                   ipcRenderer.invoke(InvokeHandleMutation.Scan3dsSoftware)
                                        .then((result: { lists: Software3DTypes[], versions: { a3dsMax: string, maya: string } }) => {
                                             setApplications(result.lists)
                                             setIsWaiting(true)
                                             console.log(result)
                                             setTimeout(() => {
                                                  setIsWaiting(false)
                                             }, 500);
                                             return result.versions
                                        })
                              }} className="btn rescan-button">Scan to update</button>
                         </div>
                    </div>
                    <div className="list-plugins ">
                         <div className="row ps-3 me-0 pb-2">
                              {Applications.length == 0 ? "There are no plugin found!"
                                   :
                                   Applications.map(_app => (
                                        <div className="plugin-item">
                                             <div className="plugin-item-body">
                                                  <div>
                                                       <h6 className="plugin-title">
                                                            {_app.name}
                                                       </h6>
                                                       <div className="plugin-detail">
                                                            SuperRenders Plugin version: v{_app.installedPluginVersion.toString().split("").join(".")}
                                                       </div>
                                                  </div>
                                                  <div className="ms-auto my-auto">
                                                       {
                                                            (() => {
                                                                 const upToDate = <span className="badge bg-success">✅ Up to date</span>
                                                                 const outOfDate = <span className="badge bg-danger">🌱 Outdated</span>
                                                                 const thisVer = _app.installedPluginVersion.toString().split("").join(".")

                                                                 if (_app.name.startsWith("Maya")) {
                                                                      return thisVer === MayaPluginsVer ? upToDate : outOfDate
                                                                 }

                                                                 if (_app.name.startsWith("3ds Max")) {
                                                                      return thisVer === A3dsMaxPluginVer ? upToDate : outOfDate
                                                                 }
                                                            })()}
                                                  </div>
                                             </div>

                                        </div>
                                   ))
                              }
                         </div>

                    </div>
               </div>
          </>
     )

}