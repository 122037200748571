import React, { useState } from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../../../stores/stores'
import './render-type.scss'
import { MapDispatchs } from './RenderType.dispatch'


const ReduxMapState  = (States : RootState) =>( {
     RenderScene : States.Render
})
type RenderTypeProps = (ReturnType<typeof ReduxMapState>) & (typeof MapDispatchs)

function RenderType( Renders: RenderTypeProps) {
     const [RenderScene, setRenderScene] = useState(Renders.RenderScene)
     // Render options Render frames
     function RenderFrames(){

          // Handle start frame
          const [frameStart, setFrameStart] = useState<number>(RenderScene.body.scene.params.scene_param.startFrame)
          const ChangeStartFrame = ( event : React.ChangeEvent<HTMLInputElement>) => {
               let _startFrame = parseInt(event.target.value)
               setFrameStart(_startFrame)
               Renders.SetStartFrame(_startFrame)
          }

          // Handle end frame
          const [endFrame, setEndFrame] = useState<number>(RenderScene.body.scene.params.scene_param.endFrame)
          const ChangeEndFrame = ( event : React.ChangeEvent<HTMLInputElement>) => {
               let _endFrame = parseInt(event.target.value)
               setEndFrame(_endFrame)
               Renders.SetEndFrame(_endFrame)
          }

          // Hand incremental step
          const [incrementStep, setIncrementStep] = useState<number>(RenderScene.body.scene.params.scene_param.incrementStep)
          const ChangeIncrementStep = ( event : React.ChangeEvent<HTMLInputElement>) => {
               let _incrementStep = parseInt(event.target.value)
               setIncrementStep(_incrementStep)
               Renders.SetFrameStep(_incrementStep)
               // Test frame per task
               //Renders.SetFramePerTask(_incrementStep-1)
          }

          return (
               <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane show active" id="multi" role="tabpanel" aria-labelledby="multi-frames">
                         <div className="d-flex mb-3">
                              <div className="col-1 mt-auto">
                                   <label className="form-label">Frame Range </label>
                              </div>
                              <div className="row col">
                                   <div className="col-4">
                                        <span className="input-group-text" id="scene-path">Start Frame </span>
                                        <input type="number" value={frameStart} onChange={ ChangeStartFrame } className="form-control" id="scene-path" aria-describedby="scene-path" />
                                   </div>
                                   <div className="col-4">
                                        <span className="input-group-text" id="scene-path">End Frame </span>
                                        <input type="number" value={endFrame} onChange={ ChangeEndFrame } className="form-control" id="scene-path" aria-describedby="scene-path"  />
                                   </div>
                                   <div className="col-4">
                                        <span className="input-group-text" id="scene-path">Incremental Step </span>
                                        <input type="number" value={incrementStep} onChange={ChangeIncrementStep} className="form-control" id="scene-path" aria-describedby="scene-path"  />
                                   </div>
                              </div>
                         </div>
                    </div>
                    <div className="tab-pane" id="single" role="tabpanel" aria-labelledby="single-frames">
                         <div className="d-flex mb-3">
                              <div className="col-1 mt-auto">
                                   <label className="form-label">Frame</label>
                              </div>
                              <div className="row col">
                                   <div className="col-4">
                                        <span className="input-group-text" id="scene-path">&nbsp;</span>
                                        <input type="number" className="form-control" id="scene-path" aria-describedby="scene-path" value="" />
                                   </div>
                                   <div className="col-8 mt-auto">
                                        <div className="form-check mb-0">
                                             <input className="form-check-input mt-3" type="checkbox" value="" id="flexCheckDefault"/>
                                             <label className="form-check-label ms-2" htmlFor="flexCheckDefault">
                                                  <small>Render Tiles (Supply 30 render machines to render at the same time).<br/>Note: Render Elements is not support on Render Tiles mode.</small>
                                             </label>
                                        </div>
                                   </div>

                              </div>
                         </div>
                    </div>
               </div>)

     }
     return (
          <>
          <div className="d-flex">
               <div className="col-1 my-auto">
                    <label className="form-label">Render Type </label>
               </div>
               <ul className="nav nav-pills row col-8" id="render-type" role="tablist">
                    <li className="nav-item col-4 d-grid" role="presentation">
                         <div className="nav-link active"  data-bs-toggle="tab" data-bs-target="#multi"  role="tab" aria-controls="multi" aria-selected="true">
                              <h6 className="mb-0">Animation</h6><div style={{fontSize : ".8rem"}}>Multi-Frames</div>
                         </div> 
                    </li>
                    <li className="nav-item col-4 d-grid" role="presentation">
                         <div className="nav-link"  data-bs-toggle="tab" data-bs-target="#single"  role="tab" aria-controls="single" aria-selected="false">
                              <h6 className="mb-0">Single</h6><div style={{fontSize : ".8rem"}}>Single-Frames</div>
                         </div> 
                    </li>
               </ul>
          </div>
          {/* Content of Render Type  */}
          {RenderFrames()}
          </>
     )
}


export default connect(ReduxMapState,MapDispatchs)(React.memo(RenderType))