import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import DropableZone from '../../../../../components/DropableZone';
import { SuperTransferEvent } from '../../../../../Interfaces/IpcRenderer.enum';
import './style.scss'

const { ipcRenderer } = window.require("electron")


export type UploadModalProps = {
}
export function UploadModal({ }: UploadModalProps) {
     const [uploadModal, setUploadModal] = useState(false)

     useEffect(() => {
          ipcRenderer.on(SuperTransferEvent.UPLOAD_STARTED, (event, context) => {
               setUploadModal(false)
          })
     })

     return (
          <>
               <button className="btn upload-button btn-sm" type="button" onClick={() => setUploadModal(true)} style={{ width: 140 }}>
                    <i className="fad fa-upload"></i> Upload
               </button>
               <Modal
                    isOpen={uploadModal}
                    onRequestClose={() => setUploadModal(false)}
                    closeTimeoutMS={250}
                    style={{
                         overlay: {
                              zIndex: 10000,
                              position: 'fixed',
                              top: 35,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              backgroundColor: 'rgba(0, 0, 0, 0.8)',
                         },
                         content: {
                              top: '50%',
                              left: '50%',
                              right: 'auto',
                              bottom: 'auto',
                              marginRight: '-50%',
                              background: '#343434',
                              transform: 'translate(-50%, -50%)',
                              border: 'none',
                              width: '570px',
                              padding: '0',
                              cursor: 'default',
                              overflow: "hidden",
                         },
                    }}
                    contentLabel="Upload Modal"
               >
                    <DropableZone />
               </Modal>
          </>
     )
}