import Tippy from '@tippyjs/react'
import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { RootState, Stores } from '../../../../stores/stores';
import './cloud-storage.scss'
import { CloudStorageDispatch } from './CloudStorage.dispatch'
import { useHistory } from 'react-router-dom';
import { useShortcut } from '../../../../Helpers/Shortcut';
import FileManager from './FileManager';
import { UploadModal } from './Modals/UploadModal';
import { CreateFolderModal } from './Modals/CreateFolderModal';

import Dropable from '../../../../components/Dropable';

const { ipcRenderer } = window.require('electron')
const electron = window.require('electron')


enum SpaceGridState {
    Grid = "grid",
    List = "list"
}
// map redux state
const MapStates = ({ CloudStorage }: RootState) => ({
    CloudStorage
})
// Component props
type CloudStorageProps = ReturnType<typeof MapStates> & typeof CloudStorageDispatch & {
}

function usePrevious<Type>(value: Type) {
    const ref = useRef<Type>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

function CloudStorage(Storage: CloudStorageProps) {
    const [spaceView, setSpaceView] = useState(SpaceGridState.Grid)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [quickTip, setQuickTip] = useState<boolean>(localStorage.getItem('quick-tip') === 'true')
    const [Spaces, setSpaces] = useState(Storage.CloudStorage)
    const PreCloudStorage = usePrevious(Spaces)
    const history = useHistory()
    const Items = Storage.CloudStorage?.query ? Storage.CloudStorage.query_results || [] : Storage.CloudStorage.items
    const SearchStyles = Storage.CloudStorage?.query ? { background: "#fff", color: "#000" } : {}

    useEffect(() => {
        localStorage.setItem('quick-tip', quickTip.toString());
    }, [quickTip]);
    const ToggleQuickTips = () => {
        setQuickTip(!quickTip)
    };
    // Migration object
    useEffect(() => {
        // Listen url to render spaces
        if (localStorage.getItem("user_profiles") === null)
            setTimeout(() => {
                setIsLoading(false)
                Storage.SetItems(history.location.pathname)
            }, 2500);
        else {
            setIsLoading(false)
            Storage.SetItems(history.location.pathname)
        }

        // Listen ipc main
        ipcRenderer.on('open_storage_directory', (event, Prefix: string) => {
            var SliceTimes = window.location.pathname.split('/').slice(1).filter(e => e !== "").length + 1
            var SubKey = Prefix.split("/").slice(SliceTimes).join("/")
            if (history.location.pathname !== Prefix) {
                history.push(`/${Prefix}`)
                Storage.SetItems(Prefix)
            }
        })

        return history.listen((location) => {
            Storage.Load()
            var Prefix = location.pathname
            Storage.SetItems(Prefix)
        })
    }, [history])

    const SearchInputRef: React.RefObject<HTMLInputElement> = React.createRef()

    useShortcut('Ctrl+F', () => {
        if (SearchInputRef?.current) {
            SearchInputRef.current.focus()
            SearchInputRef.current.select()
            SearchInputRef.current.style.backgroundColor = "#fff7"
        }
    })

    useEffect(() => {
        // Listen for states changed between every render cycle
        if (PreCloudStorage !== Storage.CloudStorage) {
            // setIsLoading(false)
        }


    }, [Storage])

    if (isLoading) {
        return (
            <div style={{ height: 'calc(100vh - 130px)' }}>
                <div className="text-center d-flex h-100">
                    <div className="m-auto">
                        <h5 className="mb-3">Initializing your file storages...</h5>
                        <div className="spinner-border spinner-border-lg fast" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    return (
        <>
            <div className="quick-tips alert alert-success" role="alert">
                <div className={`content ${!quickTip ? "show" : "hide"}`}>
                    <div onClick={() => setQuickTip(false)}>
                        We strongly recommend using our 3D software plugins to Submit render jobs to SuperRenders. However, you can package it yourself and upload it to SuperRenders.</div>
                    Please refer to a few notes as below during the package project yourself :
                <ul className="m-0">
                    
                    <li>Make sure you <strong onClick={() => {electron.shell.openExternal('https://support.superrendersfarm.com/hc/en-us/articles/360023844234-Package-your-projects')}}>package your projects</strong> properly before uploading and rendering your scenes.</li>
                    <li>To use absolute paths on SuperRenders farm, use the feature Create Folder OR enable Auto Keep Local Path (in Upload section) below. <strong onClick={() => {electron.shell.openExternal('https://support.superrendersfarm.com/hc/en-us/articles/360062299047-how-to-add-an-absolute-path-with-simulate-local-path-feature')}}>(Learn more)</strong></li>
                    <li>Go to <strong onClick={() => {electron.shell.openExternal('https://superrendersfarm.com/account/render-dashboard')}}>our website</strong> and start your render job.</li>
                </ul>
                </div>
                <span className="toggle-button" onClick={ToggleQuickTips}>{!quickTip ? <i className="fad fa-times"></i> : <i className="fad fa-arrow-down"></i>}</span>
            </div>

            <div className="cloud-storage">
                <div className="search-bar">
                    <UploadModal />
                    <CreateFolderModal />
                    <span style={{ fontSize: ".9rem", backgroundColor: "rgba(255,255,255,.1)", borderColor: "#000", color: "#fff6", border: "1px dashed #fff5" }} className="rounded ms-1 py-1 px-2 my-auto">
                        <i>or drag and drop files and folders you want to upload here</i>
                    </span>
                    <div className="ms-auto my-auto" style={{ width: 300 }}>
                        <div className="d-flex">
                            <Tippy content={spaceView === SpaceGridState.List ? "Grid View" : "List View"} placement="left">
                                <button type="button" className="btn toggle-space" style={{ font: "icon" }}
                                    onClick={() => (spaceView === SpaceGridState.List ? setSpaceView(SpaceGridState.Grid) : setSpaceView(SpaceGridState.List))}>
                                    <i className={`fal ${spaceView === SpaceGridState.List ? "fa-th-large" : "fa-list-ul"}`}></i>
                                </button>
                            </Tippy>
                            <input ref={SearchInputRef} className="form-control" style={{ ...SearchStyles, ...{ font: "menu" } }} onChange={e => {
                                Storage.Search(e.target.value)
                            }} type="search" placeholder="Search file..." aria-label="Search file..." />
                        </div>

                    </div>

                </div>

                {/* File manager */}
                <Dropable>
                    <FileManager Items={Items} ViewMode={spaceView} QuickTips={!quickTip} />
                </Dropable>
            </div>
        </>
    )
}
export default connect(MapStates, CloudStorageDispatch)(React.memo(CloudStorage))