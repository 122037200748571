import React from 'react'
import './image-samples-and-filter.scss'

interface ImageSamplesAndFilterProps {
}

export default function ImageSamplesAndFilter({}: ImageSamplesAndFilterProps) {
     return (
          <div className="card mb-3" >
               <div className="card-header">
                    <h6 className="card-title">Image Samples and Image Filter</h6>
               </div>
               <div className="card-body">
                    <div className="row mb-3">
                         <div className="col-6">
                              <span className="input-group-text" id="scene-path">Image Samples( Antialiasing ) </span>
                              <select className="form-select" aria-label="Default select example">
                                   <option value="1" selected>Bucket</option>
                                   <option value="3">Progressive</option>
                              </select>
                         </div>
                         <div className="col-6">
                              <span className="input-group-text" id="scene-path">Image Filter </span>
                              <div className="form-check">
                                   <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked/>
                                   <label className="form-check-label" htmlFor="flexCheckChecked">
                                        Filter: VRayLanczosFilter:
                                   </label>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     )
}