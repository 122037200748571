/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect,useRef,useState } from 'react'
import './titlebar.scss'
import logo from '../../../../assets/logo.png'
import AccountTitleBar from './AccountTitleBar';
import { IndexOf } from '../../../../Interfaces/App.enums';
import ESImport from '../../../../esimport';
import { IpcSender } from '../../../../Interfaces/IpcRenderer.enum';
import { Stores } from '../../../../stores/stores';
const electron = window.require('electron')

const { ipcRenderer } = window.require("electron");

interface TitlebarProps {
    handleCollapsedChange?:any;
}


interface IActive3dsProgram {
    Name :string
    TimeLapsed :string
}

function usePrevious<Type>(value:Type) {
    const ref = useRef<Type>();
    useEffect(() => {
      ref.current = value;
      
    });
    return ref.current;
}

export default function Titlebar({handleCollapsedChange}: TitlebarProps) {
    const [ActiveApp, setActiveApp] = useState<IActive3dsProgram>({Name: "Unknown", TimeLapsed : '0'})
    const [windows,setWindow] = useState("min")
    function CloseWindow() {
        ipcRenderer.send("Window.Close")
    }
    
    function MinimizeWindow() {
        ipcRenderer.send("Window.Minimize")
        setWindow("min") 
    }
    
    
    function MaximizeWindow() {
        ipcRenderer.send("Window.Maximize")
        windows==="min"?setWindow("max") :setWindow("min") 
    }
    
    useEffect(() => {
        if (window.screen.width === window.innerWidth){
            setWindow("max") 
        }
        ipcRenderer.on("activeApp", (event:any, data:IActive3dsProgram) => {
            setActiveApp(data)
        })

    }, [])
    return (
        ["/tray", "/login" , "/setting"].indexOf(window.location.pathname) == IndexOf.NotFound ?
        <div className="title-bar">
            <div className="me-auto d-flex">
        
                <button type="button" className="titlebar-button ms-3 d-none" onClick={()=>handleCollapsedChange()}  >
                    <i className="fas fa-bars"></i>
                </button>
             
                <div className="title-brand my-auto d-flex ms-2">
                    <div className="logo ms-1" style={{backgroundSize:"cover",height:"25px",width:"25px",backgroundRepeat:"no-repeat",backgroundImage:`url("${logo}")`}}/>
                    <div className="mt-1 ms-1">
                        <span className="logo-title-bar-text-super">SUPER</span>
                        <span className="logo-title-bar-text-renders">RENDERS</span>
                    </div>
                </div>

            </div>
            <div className="drag d-flex"></div>
            <div className="ms-auto" >
                <div className="d-flex">
                    <button className="titlebar-button helpBtn me-2" type="button" onClick={() => { 
                        electron.shell.openExternal('https://support.superrendersfarm.com/hc/en-us/sections/360007451455-superrenders-client-app')
                    }}>
                    <i className="fad fa-question-circle"></i> How it works
                    </button>
                    <AccountTitleBar />
                    <div className="ms-5">
                        <button className="titlebar-button minimizeBtn" type="button" onClick={MinimizeWindow}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-dash" viewBox="0 0 16 16">
                                    <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                                </svg>
                        </button>
                        <button className="titlebar-button maximizeBtn" type="button" onClick={MaximizeWindow}>
                           
                            {
                               windows==="max" ? 
                               <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                               viewBox="0 0 50 50" enable-background="new 0 0 50 50" xmlSpace="preserve">
                               <path d="M15.243,19.194c0.553,0,1-0.447,1-1s-0.447-1-1-1h-3.77c-0.553,0-1,0.447-1,1v21.951c0,0.553,0.447,1,1,1h21.951
                                   c0.553,0,1-0.447,1-1v-3.765c0-0.553-0.447-1-1-1s-1,0.447-1,1v2.765H12.474V19.194H15.243z"/>
                               <path d="M41.474,9.146H19.522c-0.553,0-1,0.447-1,1v21.951c0,0.553,0.447,1,1,1h21.951c0.553,0,1-0.447,1-1V10.146
                               C42.474,9.593,42.026,9.146,41.474,9.146z M40.474,31.097H20.522V11.146h19.951V31.097z"/>
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-app" viewBox="0 0 16 16">
                                <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                                </svg>
                            }
                        </button>
                        <button className="titlebar-button closeBtn" type="button" onClick={CloseWindow}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </button>
                    </div>

                </div>
            </div>
        </div>
        :
        <div></div>
    )
}