import { combineReducers } from 'redux'
import { SceneInterface } from '../Interfaces/Renderer';
import { RenderReducer as Render } from './reducers/render.reducer';
import { AccountReducer as Account } from './reducers/accounting.reducer'
import { SpacesReducer as CloudStorage } from './reducers/spaces.reducer';
import { NotificationReducer as Notification} from './reducers/notification.reducer';
import { AnalysisReducer as Analysis} from './reducers/analysis.reducer';
import { RenderListReducer as RenderList } from './reducers/render_list.reducer'
import { ScreenModalReducer as ScreenModal} from './reducers/screen_modal.reducer'
/**
 * Combine all application reducer to one reducer
 */
export const ReduxReducers  = combineReducers({
    Render,
    Account,
    CloudStorage,
    Notification,
    Analysis,
    RenderList,
    ScreenModal
});