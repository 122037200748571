import React from 'react'
import Titlebar from '../MainWindow/Layout/Titlebar'
import './offline.scss'

interface OfflineProps {
}

export default function Offline({}: OfflineProps) {
    return (<>
        <Titlebar />
        <div style={{height :'100vh'}}>
            <div className="d-flex h-100">
                <div className="m-auto text-center">
                <h3> Please check your connection!!</h3>
                </div>
            </div>
        </div>
    </>)
}