import React, { useState } from 'react'
import { connect } from 'react-redux'
import { MaxLighting } from '../../../../../Interfaces/Renderer'
import { RootState } from '../../../../../stores/stores'
import { LightDispatchs } from './Light.Dispatchs'
import './lights-management.scss'

const MapStates = (States:RootState) => ({
     Scene : States.Render
})

type LightsManagementProps  = ReturnType<typeof MapStates> & typeof LightDispatchs

function LightsManagement(Renders: LightsManagementProps) {

     const Scene = Renders.Scene
     // Switch state of a light by light name
     function UpdateLightState(lightIndex: number) {
          Renders.ChangeLightState(lightIndex)
     }

     function ListLights() {
          return <>{
               Scene.body.scene.params.lighting_param.lighting_list.map( (Light, index) => {
                    return (
                         <tr>
                              <th scope="row">{index+1}</th>
                              <td>{Light.light_name}</td>
                              <td>
                                   <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" onChange={() => { UpdateLightState(index)}} role="switch" id="flexSwitchCheckChecked" checked={Light.light_state} />
                                   </div>
                              </td>
                         </tr>
                    )
               })
          }</>
     }

     return (
          <table className="table text-white">
               <thead>
                    <tr>
                         <th scope="col">No</th>
                         <th scope="col">Light name</th>
                         <th scope="col">Action turn On/Off</th>
                    </tr>
               </thead>
               <tbody>
                    <ListLights/>
               </tbody>
          </table>

     )
}

export default connect(MapStates, LightDispatchs)(React.memo(LightsManagement))