import React from 'react'
import './max.scss'
import Camera from '../components/Camera'
import Resolution from '../components/Resolution'
import ScenePath from '../components/ScenePath'
import LightsManagement from '../components/LightsManagement'
import RenderElements from '../components/RenderElements'
import OutputSettings from '../components/OutputSettings'

import RenderFlags from '../components/RenderFlags'
import RenderSetting from '../components/RenderSetting'
import Vray from './Vray'
import Corona from './Corona'
import Arnold from './Arnold'
import { connect } from 'react-redux'
import { RootState } from '../../../../stores/stores'
import { RenderOptionsInterface } from '../../../../screens/RenderNow/RenderNow.interface'
import RenderType from '../components/RenderType'

function Max( ) {
     return (
          <>
               <ScenePath/>
               <Resolution/>
               <RenderType />
               <Camera/>
               <div className="accordion pb-3" id="param-settings">
               {/* Lights management */}
               <div className="accordion-item">
                    <h2 className="accordion-header" id="heading1">
                         <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                              Lights management
                         </button>
                    </h2>
                    <div id="collapse1" className="accordion-collapse collapse" aria-labelledby="heading1" data-bs-parent="#param-settings">
                         <div className="accordion-body">
                              <LightsManagement />
                         </div>
                    </div>
               </div>

               {/* Render Elements */}
               <div className="accordion-item">
                    <h2 className="accordion-header" id="heading2">
                         <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                              Render elements
                         </button>
                    </h2>
                    <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#param-settings">
                         <div className="accordion-body">
                              <RenderElements />
                         </div>
                    </div>
               </div>
               
               {/* Output Settings */}
               <div className="accordion-item">
                    <h2 className="accordion-header" id="heading3">
                         <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                              Output setting
                         </button>
                    </h2>
                    <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#param-settings">
                         <div className="accordion-body">
                              <OutputSettings />
                         </div>
                    </div>
               </div>
               <Vray />
               {/* Renderer/Plugins option  */}
               {/* <Arnold  /> */}
               {/* <Corona  /> */}

               {/* Render Flags */}
               <div className="accordion-item">
                    <h2 className="accordion-header" id="heading5">
                         <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                              Render flags
                         </button>
                    </h2>
                    <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#param-settings">
                         <div className="accordion-body">
                              <RenderFlags />
                         </div>
                    </div>
               </div>

               {/* Render Setting */}
               <div className="accordion-item">
                    <h2 className="accordion-header" id="heading6">
                         <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                              Render setting
                         </button>
                    </h2>
                    <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#param-settings">
                         <div className="accordion-body">
                              <RenderSetting />
                         </div>
                    </div>
               </div>
          </div>
     </>
     )
}

export default Max