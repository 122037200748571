// Declare type of dispatch
export const CHANGE_WIDTH :string = "CHANGE_WIDTH"

export type RenderReducerType =  ""

export interface RenderReducerActionInterface {
    type : RenderReducerActionEnum
    value ?: any
}

export enum RenderReducerActionEnum {
    ChangeWidth = "Render.Reducer/CHANGE_WIDTH",
    UpdateScene = "Render.Reducer/UPDATE_SCENE",
    ChangeHeight = "Render.Reducer/CHANGE_HEIGHT",
    ChangeStartFrame = "Render.Reducer/CHANGE_START_FRAME",
    ChangeEndFrame = "Render.Reducer/CHANGE_END_FRAME",
    ChangeFrameStep = "Render.Reducer/CHANGE_FRAME_STEP",
    SetRenderCamera = "Render.Reducer/SET_RENDER_CAMERA",
    SetRenderTile = "Render.Reducer/SET_RENDER_TILE",
    SetFramePerTask = "Render.Reducer/SET_FRAME_PER_TASK",
    SwitchLightState = "Render.Reducer/SWITCH_LIGHT_STATE",
    SetOutputFileType = "Render.Reducer/SET_OUTPUT_FILE_TYPE",
    SwitchRenderFlags = "Render.Reducer/switch_render_flags",
    SetRenderPriority = "Render.Reducer/SET_RENDER_PRIORITY",
    SetFarmDirectory = "Render.Reducer/SET_FARM_DIRECTORY",
    UpdateOutputProperties = "Render.Reducer/UPDATE_OUTPUT_PROPERTIES",
    SetVrayOptions = "Render.Reducer/ANALYZE::SET_VRAY_OPTIONS",
    UpdateNrrOutputProperties = "Render.Reducer/UPDATE_NRR_OUTPUT_PROPERTIES",

    //
    IsEnableBuildInFrameBuffer = "Render.Reducer/IS_ENABLE_BUILD_IN_FRAME_BUFFER",

    // Vray options
    SetPrimaryEngine = "Render.Reducer/VRAY::SET_PRIMARY_ENGINE",
    SetSecondaryEngine = "Render.Reducer/VRAY::SET_SECONDARY_ENGINE",
    SetIrradianceMap = "Render.Reducer/VRAY::SET_IRRADIANCE_MAP",
    SetImageSample = "Render.Reducer/VRAY::SET_IMAGE_SAMPLE",
    SetImageFilter = "Render.Reducer/VRAY::SET_IMAGE_FILTER",
    SetIsRenderRawImageFile = "Render.Reducer/ANALYZE::SET_IS_RENDER_RAW_IMAGE_FILE",
    SetVrayRawFileName = "Render.Reducer/ANALYZE::SET_VRAY_RAW_FILE_NAME",
    SeparateRenderChannel = "Render.Reducer/ANALYZE::SET_SEPARATE_RENDER_CHANNEL",
    SetSplitChannelFileName = "Render.Reducer/ANALYZE::SET_SPLIT_CHANNEL_FILE_NAME",

    // Corona Options

}