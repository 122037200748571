import React, { useEffect, useState } from 'react'
import './choose-temporary-directory.scss'
const {ipcRenderer} = window.require('electron')
interface ChooseTemporaryDirectoryProps {
}

type DBITemporary = {
    key : "srf_temp_path"
    value : string
}

export default function ChooseTemporaryDirectory({}: ChooseTemporaryDirectoryProps) {

    const [ Directory , SetDirection ] = useState<string>("")

    useEffect( () => {
        ipcRenderer.on("srf_temp_path", (event,arg) => {
            const TempData  = arg as DBITemporary
            SetDirection(TempData.value)
        })
    },[])

    return (<div>
        <p><b>Choose Temporary Directory</b></p>
        <input type="text" name="" id="" value={Directory}  />
    </div>)
}