import React, { useCallback, useEffect, useState } from 'react'
import './file-manager.scss'
import Breadcrumb from './Breadcrumb'
import RelativeTime from '../RelativeTime'
import DefineIcon from '../DefineIcon'
import FileSize from '../FileSize'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale.css'
import { CloudStorageAction, SpaceItem, SpaceItemType } from '../../../../../stores/reducers/spaces.interface';
import { connect } from 'react-redux'
import { RootState } from '../../../../../stores/stores'
import { useHistory } from 'react-router-dom'
import { IndexOf } from '../../../../../Interfaces/App.enums';
import { IWebUserConfig } from '../../../../../Interfaces/User'
import { LoginApiResponse } from '../../../../../Interfaces/Http'
import { IpcResponse, IpcSender } from '../../../../../Interfaces/IpcRenderer.enum';
import { isLabeledStatement } from 'typescript'
import { AskForAnalyzeScene } from '../Modals/AskForAnalyze'
import { AnalyzeSceneModal } from '../Modals/AnalyzeSceneModal'
import { RenderSceneModal } from '../Modals/RenderSceneModal'
import { NoItemInView } from './NoItem'
import { RootDirEmpty } from './RootDirEmpty'
import { NotFound } from './NotFound'
import { AuthorizationRequest } from '../../../../../apis/default'

const { ipcRenderer } = window.require('electron')

const MapStates = (States: RootState) => ({
     Storage: States.CloudStorage
})

const GridDispatcher = {
     Load: () => ({ type: CloudStorageAction.Load, payload: "" }),
     Fetch: (Prefix: string) => ({ type: CloudStorageAction.SagaFetch, value: Prefix }),
     RemoveObject: (Key: string) => ({ type: CloudStorageAction.RemoveObject, payload: Key }),
}
type FileManagerType = ReturnType<typeof MapStates> & typeof GridDispatcher & {
     Items: SpaceItem[],
     ViewMode: string,
     QuickTips?: boolean
}

export function getEnumKeyByEnumValue(myEnum: any, enumValue: number | string): string {
     let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
     return keys.length > 0 ? keys[0] : '';
}
// Download object
function DownloadObject(Key: SpaceItem): void {
     ipcRenderer.send('download-object', Key)
}

type SpaceItemListType = SpaceItem & {
     checked: boolean
}
function FileManager(Props: FileManagerType) {
     const ViewMode = Props.ViewMode
     const QuickTips = Props.QuickTips
     const history = useHistory()
     const [Items, setItems] = useState<SpaceItemListType[]>([])
     const [activeScene, setActiveScene] = useState<SpaceItemListType>()
     const [isAskForAnalyzeOpen, setAskForAnalyzeOpen] = useState(false)
     const [isOpenAnalyzeModal, setIsOpenAnalyzeModal] = useState(false)
     const [isOpenRenderModal, setIsOpenRenderModal] = useState(false)
     const [selectedRows, setSelectedRows] = useState<SpaceItemListType[]>([]);


     useEffect(() => {
          const theItems = Props.Items.map(i => ({ ...i, checked: false }) as SpaceItemListType)
          setItems(theItems)
     }, [Props.Items])

     // Delete File function
     const handleDelete = () => {
          for (const item of selectedRows) {
               ipcRenderer.send(IpcSender.DeleteObject, item.key)
               setSelectedRows([])
          }
          //ipcRenderer.send(IpcSender.DeleteObject,'')
     };
     const ClearSelected = () => {
          setSelectedRows([])
     }

     const sceneHistoryResult = () => {
          return <span>No result available </span>
     }
     useEffect(() => {
          ipcRenderer.on(IpcResponse.DeleteObject, (event, ObjectKey, response) => {
               // Remove objects from list
               Props.RemoveObject(ObjectKey)
               console.log("Remove object key ", ObjectKey)
          })
     }, [])

     const renderItem = () => {
          // Loading
          if (Props.Storage.isLoading) {
               return (
                    <div className="d-flex h-100">
                         <div className="text-center m-auto">
                              <div className="spinner-border spinner-border-lg fast" role="status">
                                   <span className="sr-only">Loading...</span>
                              </div>
                         </div>
                    </div>
               )
          }
          // Return if root folder is empty
          if (Items.length === 0 && window.location.pathname === "/" && Props.Storage.query === "") {
               return <RootDirEmpty />
          }
          // Return if current folder empty
          if (typeof Items === "string") {
               return (
                    <div className="notification-screen">
                         <div className="text-center m-auto">
                              <h4>No Items</h4>
                              <p>Please select a folder to see its contents</p>
                         </div>
                    </div>
               )
          }
          // return if there is no item for search query
          if (Items.length === 0 && Props.Storage.query)
               return <NotFound query={Props.Storage.query} />
          // Return if current folder is empty
          if (Items.length === 0)
               return <NoItemInView />

          function GoItem(item: SpaceItemListType, SubKey: string) {
               setSelectedRows([])
               console.log(`Clicked -> ${getEnumKeyByEnumValue(SpaceItemType, item.type)} :`, item.key.split("/").slice(1).join("/").replace(/\/\//g, "/"));
               if (item.type === SpaceItemType.Scene) {
                    setAskForAnalyzeOpen(true)
                    setActiveScene(item)
               }

               // On clicked to Drive or Folder
               if ([SpaceItemType.Drive, SpaceItemType.Folder].indexOf(item.type) !== IndexOf.NotFound) {
                    Props.Load()
                    history.push(`${SubKey}`)
               }
          }
          return <div className="row ps-3 me-0 pb-2">{
               Items.map((item, i) => {
                    var SliceTimes = window.location.pathname.split('/').slice(1).filter(e => e !== "").length + 1
                    var SubKey = item.key.split("/").slice(SliceTimes).join("/")
                    var id = i + 1;

                    return (
                         <div className={`item d-flex${item.type === SpaceItemType.Drive || item.type === SpaceItemType.Folder ? " folder" : ""}`} key={id} >
                              <div className="form-check my-auto" hidden={[SpaceItemType.Drive, SpaceItemType.Folder].indexOf(item.type) !== IndexOf.NotFound}>
                                   <input className="form-check-input select-item-checkbox" type="checkbox" checked={item.checked} onChange={(e) => {

                                        var index = Items.findIndex(i => i.key === item.key)
                                        const newItems = Items
                                        if (e.target.checked) {
                                             newItems[index].checked = true
                                             setItems(newItems)
                                             return setSelectedRows([...selectedRows, item])
                                        }
                                        newItems[index].checked = false
                                        setItems(newItems)
                                        setSelectedRows(selectedRows.filter(i => i.key !== item.key))
                                   }} />
                              </div>
                              <div className="item-body d-flex" onClick={() => { GoItem(item, SubKey) }} >

                                   {/* Tippy: hover to show more content */}
                                   <Tippy placement="right" content={
                                        <div className="row p-1">
                                             <div className="col-12 border-bottom mb-3 pb-2">
                                                  <div className="text-break">
                                                       {item.type === SpaceItemType.Drive ? `Drive (${item.name}:/)` : item.name}
                                                  </div>
                                             </div>
                                             {
                                                  (item.type === SpaceItemType.Scene) ?
                                                       <>
                                                            <div className="col-4 text-warning">Scene History: </div>
                                                            <div className="col-8">{sceneHistoryResult()}</div>
                                                       </>
                                                       : <></>
                                             }
                                             <div className="col-4 text-warning">Type: </div>
                                             <div className="col-8">{
                                                  item.type === SpaceItemType.Drive ? "Drive" :
                                                       item.type === SpaceItemType.Asset ? "Asset" :
                                                            item.type === SpaceItemType.Folder ? "Folder" :
                                                                 item.type === SpaceItemType.Scene ? "Scene" :
                                                                      item.type === SpaceItemType.Other ? "Other" : "Unknown"
                                             }</div>

                                             {[SpaceItemType.Drive, SpaceItemType.Folder].indexOf(item.type) !== IndexOf.NotFound ? "" :
                                                  <>
                                                       <div className="col-4 text-warning">Extension:</div>
                                                       <div className="col-8">{item.extension}</div>
                                                       <div className="col-4 text-warning">Modified: </div>
                                                       <div className="col-8">{RelativeTime(item.extra?.LastModified)}</div>
                                                  </>
                                             }
                                             {(item.size) ?
                                                  <>
                                                       <div className="col-4 text-warning">Size: </div>
                                                       <div className="col-8">{FileSize(item.size)}</div>
                                                  </>
                                                  : <></>
                                             }
                                        </div>
                                   }>
                                        <div className="item-thumbnail" onClick={() => { }}>
                                             {/* Render Icon thumbnail */}
                                             <div style={{ backgroundImage: `url(${DefineIcon(item.type, item.extension)})` }} />
                                        </div>
                                   </Tippy>
                                   {/* Render item name */}
                                   <div className="item-name">
                                        {item.type === SpaceItemType.Drive ? <>Drive (<span className="text-uppercase">{item.name}</span>:) </>
                                             : item.name}
                                   </div>
                                   <div className="list-info my-auto">
                                        {(item.type === SpaceItemType.Scene) ?
                                             <div className="col-12">{sceneHistoryResult()}</div> :
                                             <div className="col-12"></div>
                                        }

                                        {[SpaceItemType.Drive, SpaceItemType.Folder].indexOf(item.type) !== IndexOf.NotFound ?
                                             <div className="col-12"></div> :
                                             <div className="col-12">{RelativeTime(item.extra?.LastModified)}</div>
                                        }

                                        {(item.size) ?
                                             <div className="col-12">{FileSize(item.size)}</div> :
                                             <div className="col-12"></div>
                                        }
                                   </div>
                              </div>

                              {/* Render dropdown button */}
                              <div className="item-button-group m-auto" hidden={[SpaceItemType.Drive, SpaceItemType.Folder].indexOf(item.type) !== IndexOf.NotFound} >
                                   <Tippy content="More action">
                                        <button className="btn more-button" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                             <i className="fas fa-ellipsis-h fa-sm"></i>
                                        </button>
                                   </Tippy>
                                   <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-end">
                                        {/* {(item.type === SpaceItemType.Scene) ? <li><button type="button" className="dropdown-item" onClick={() => { GoItem(item, SubKey) }} >Analysis</button></li> :  <></>}
                                   { item.type == SpaceItemType.Other ? <></>:<>
                                        <li><button type="button" className="dropdown-item" onClick={() => {DownloadObject(item)}} >Download</button></li>
                                        <li><button type="button" className="dropdown-item">Move to</button></li>
                                   </>}
                                   <li><button type="button" className="dropdown-item">Rename</button></li> */}
                                        <li><button type="button" className="dropdown-item"
                                             onClick={() => {
                                                  // update to api
                                                  if(item.key.includes("autopackage"))
                                                  AuthorizationRequest.post('uwf/update-autopackage-folder')
                                                  ipcRenderer.send(IpcSender.DeleteObject, item.key)
                                             }} >Delete</button>
                                        </li>
                                   </ul>
                              </div>
                         </div>
                    )
               })
          }</div>

     }
     //  Render File manager

     return (
          <div className="file-manager">
               {/* Render Breadcrumb */}
               <Breadcrumb Selected={selectedRows.length != 0} DeleteHandle={handleDelete} LengthItem={selectedRows.length} ViewMode={ViewMode} ClearSelected={ClearSelected} />

               {/* Render Item */}
               <div className={(ViewMode === "list" ? "list-view" : "grid-view") + " " + (QuickTips ? "quick-tips-show" : "quick-tips-hide")}>
                    {
                         Props.Storage.isLoading ? "" :
                              Items.length === 0 ? "" :
                                   ViewMode === "list" ?
                                        <ul className="list-view-header">
                                             <li style={{ width: "40%", marginLeft: 12, marginRight: 45 }} className="d-flex">
                                                  <div className="form-check my-auto" hidden>
                                                       <input className="form-check-input mt-0" type="checkbox" checked={selectedRows.length != 0} onChange={e => {
                                                            if (e.target.checked) {
                                                                 const SelectedAllItems = Items.map(subItem => ({ ...subItem, checked: true }) as SpaceItemListType)
                                                                 setSelectedRows(SelectedAllItems)
                                                                 return setItems(SelectedAllItems)
                                                            }
                                                            setSelectedRows([])
                                                            return setItems(Items.map(subItem => ({ ...subItem, checked: false })))

                                                       }} />
                                                  </div>
                                                  <span className="ms-2">File name</span>
                                             </li>
                                             <ul style={{ width: "20%", paddingRight: 20 }} >
                                                  <li className="col-12">Scene History</li>
                                                  <li className="col-12">Modified</li>
                                                  <li className="col-12">Size</li>
                                             </ul>
                                             <li className="ms-auto me-3">Action</li>

                                        </ul>
                                        :
                                        <></>
                    }
                    {renderItem()}

                    {/* <AskForAnalyzeScene
                         isOpenModalAnalyze={isAskForAnalyzeOpen}
                         setIsOpenModalAnalyze={(state) => { setAskForAnalyzeOpen(state) }}
                         openAnalyzeModalCallback={(state) => { setIsOpenAnalyzeModal(state) }}
                         openRenderModalCallback={(state) => { setIsOpenRenderModal(state) }}
                    />
                    <AnalyzeSceneModal
                         isOpenModal={isOpenAnalyzeModal}
                         setIsOpenModal={(state) => { setIsOpenAnalyzeModal(state) }}
                         item={activeScene}
                    />
                    <RenderSceneModal
                         item={activeScene}
                         isOpenModal={isOpenRenderModal}
                         setIsOpenModal={(state) => { setIsOpenRenderModal(state) }}
                    /> */}

               </div>
               <div className="bottom-bar d-flex">
                    <small className="my-auto ms-1">
                         <i className="fad fa-file me-2 ms-2 mt-1" ></i>
                         {Props.Items.length} items
                    </small>
               </div>
          </div>
     )
}

export default connect(MapStates, GridDispatcher)(FileManager)