export enum RenderJobState {
  UserAcceptTest = "UserAcceptTest",
  SystemAcceptPreview = "SystemAcceptPreview",
  ReadyForRender = "ReadyForRender",
  GettingStarted = "GettingStarted",
  WaitingForRestart = "WaitingForRestart",
  WaitingForStart = "WaitingForStart",
  UserPreviewStarted = "UserPreviewStarted",
  UserTestStarted = "UserTestStarted",
  UserAcceptStarted = "UserAcceptStarted",
  JobStarted = "JobStarted",
  WaitingForPause = "WaitingForPause",
  JobPaused = "JobPaused",
  WaitingForDelete = "WaitingForDelete",
  JobCompleted = "JobCompleted",
  Completed = "Completed",
  JobFailed = "JobFailed",
  JobRestarting = "JobRestarting",
  InAnalyzed = "In Analyzing",
  InRendering = "In Rendering"
}

export type IRenderTask = {
  nodes?: number;
  timeLeft?: number;
};
export interface IRender {
  id: number;
  job_status: RenderJobState;
  mod_animation_prepass: 0 | 1;
  scene_name: string;
  renderoutput_folder_name: string;
  job_priority: string;
  render_progress: number;
  render_credits_v2: string;
  FramesIncrement: string;
  FrameRangeEnd: string;
  FrameRangeStart: string;
  created_at: string;
  camera_v2: string;
  gcpu_type: "cpu" | "gpu";
  tasks?: IRenderTask;
  client_id: string;
  estimated_remaining_time: string;
  num_nodes_run: number;
  is_output_folder_available?: boolean;
}
export interface ApiListRendersResponse {
  status: string;
  data: {
    list: IRender[];
    page: number;
    limit: number;
    total_count: number;
  };
}
export interface RenderListInitialState {
  isLoading: boolean;
  items: IRender[];
  query?: string;
  query_results?: IRender[];
}

export enum RenderListActionType {
  SetLoading = "RenderList::SetLoading",
  SetQuery = "RenderList::SetQuery",
  SetQueryResults = "RenderList::SetQueryResults",
  RemoveItems = "RenderList::RemoveItems",
  SagaFetch = "RenderList::SagaFetch",
  SetItems = "RenderList::SetItems",
  ReRender = "RenderList::ReRender",
  Load = "RenderList::Load",
  SetNode = "RenderList::SetNode",
  setJobStatus = "RenderList::SetJobStatus"
}

export type SetNodePayload = {
  id: number;
  status?: RenderJobState;
  nodes?: number;
  timeLeft?: number;
};

export type ReduxRenderAction<T> = {
  payload: T;
  type: RenderListActionType;
};
