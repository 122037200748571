/************************************************************
* THIS FILE IS AUTOMATICALLY GENERATED
* DO NOT MODIFY THIS FILE DIRECTLY
************************************************************/
// This enum will be used in both of ElectronJS and ReactJS
// IPCRenderer is used to communicate between the main process and the renderer process
import * as Electron from "electron";
export type ipcPayload = {
  payload: any;
} & Electron.BrowserWindowConstructorOptions;
export enum IpcEvents {
  ShowMessageBox = "Window::ShowMessageBox"
}

export enum Language {
  English = "en",
  Vietnamese = "vi"
}

export enum GlobalErrorCode {
  MISSING_ASSET_LIST_FILE = "AUTOPACKAGE::MISSING_ASSET_FILE_LIST_FILE",
  MISSING_SCENE_ANALYZE_FILE = "AUTOPACKAGE::MISSING_SCENE_ANALYZE_FILE",
  MISSING_SCENE_INFO_FILE = "AUTOPACKAGE::MISSING_SCENE_INFO_FILE"
}
export type FullInforPayloadTypes = {
  id: string;
  progressDetail: IProgress[];
  progress: number;
  totalFileCount: number;
  loaded: number;
  totalSize: number;
  filesUploaded: number;
  bytesPerSecond: number;
};

export type IProgress = {
  name: string;
  progress: number;
  type?: any;
  props?: any;
  loaded?: number;
  total?: number;
};

export type MaxAssetsDirectory = {
  path: string;
  absolutePath?: string;
  relativePath?: string;
};

export type Software3DTypes = {
  name: string;
  version: number;
  path: string;
  mxp: string[];
  assets?: MaxAssetsDirectory[];
  interfaceUI?: string;
  interfaceUIs?: string[];
  installedPluginVersion: string;
};

export type FileSourceTypes = {
  relative: string;
  absolute: string;
};

export enum TaskPriority {
  Top = 100,
  High = 50,
  Medium = 25,
  Low = 10,
  Bottom = 0,
  Hidden = -1
}

export type RunningTaskTypes < T = any > = {
  taskId?: string;
  _id: string;
  type: string; // unique task id
  title: string; // Task name
  description: string; // Task description
  progress: number; // Task progress
  status: string; // Task status
  error?: string; // Task error
  hasProgress: boolean; // Task percentage
  context?: T;
  issAt: number; // Task start time
  priority: TaskPriority;
};

export enum TaskName {
  DOWNLOAD_OUTPUT = "DOWNLOAD_OUTPUT",
  UPLOADING_ASSETS = "UPLOADING_ASSETS",
  JOB = "JOB",
  WAITING_TO_UPLOAD = "WAITING_TO_UPLOAD",
  INSTALL_3DS_MAX_PLUGINS = "INSTALL_3DS_MAX_PLUGINS",
  INSTALL_MAYA_PLUGINS = "INSTALL_MAYA_PLUGINS"
}

export enum RunningTaskEvents {
  // This event will get/response type RunningTaskTypes
  DOWNLOAD_OUTPUT_PROGRESS = "Application/Context::DOWNLOAD_OUTPUT",
  DOWNLOAD_OUTPUT_COMPLETED = "Application/Context::DOWNLOAD_OUTPUT_COMPLETED",
  DOWNLOAD_OUTPUT_ERROR = "Application/Context::DOWNLOAD_OUTPUT_ERROR",
  DOWNLOAD_OUTPUT_STARTED = "Application/Context::DOWNLOAD_OUTPUT_STARTED"
}

export const UPDATE_TASK_EVENT = "Application/Context::UPDATE_TASK";

export enum TaskEvents {
  HAS_NEW_TASK = "Application/Context::HAS_NEW_TASK",
  HAS_TASK_UPDATED = "Application/Context::HAS_TASK_UPDATED"
}

export enum SuperTransferEvent {
  // DOWNLOAD
  TOTAL_DOWNLOAD_PROGRESS_BY_HTTP_PACKAGE = "TransferEvent::TOTAL_DOWNLOAD_PROGRESS_BY_HTTP_PACKAGE",
  // PROGRESS Events
  TOTAL_PROGRESS_BY_HTTP_PACKAGE = "TransferEvent::TOTAL_PROGRESS_BY_HTTP_PACKAGE",
  TOTAL_PROGRESS_BY_FILE_COUNT = "TransferEvent::TOTAL_PROGRESS_BY_FILE_COUNT",
  TOTAL_PROGRESS_WITH_FULL_INFO = "TransferEvent::TOTAL_PROGRESS_WITH_FULL_INFO",
  // JOB Infor
  ACTIVE_JOB_INFOR = "TransferEvent::ACTIVE_JOB_INFOR",
  // UPload events
  UPLOAD_STARTED = "TransferEvent::Started",
  HTTP_TRANSFER_PROGRESS = "TransferEvent::Progress",
  UPLOAD_COMPLETED = "TransferEvent::Completed",
  NEXT = "TransferEvent::Next",
  ON_UPLOAD_ITEM_ERROR = "TransferEvent::UploadItemError",
  UPLOAD_SESSION_CANCELLED = "TransferEvent::UploadSessionCancelled",

  // Request events
  REQUEST_STOP_UPLOAD = "TransferEvent::RequestStopUpload"
}

export enum IpcSenderDatabase {
  Insert = "Database::Insert",
  Update = "Database::Update",
  Delete = "Database::Delete",
  Select = "Database::Select"
}

export enum InvokeHandleMutation {
  RequestSetting = "InvokeHandle::Mution::RequestSetting",
  Scan3dsSoftware = "InvokeHandle::Scan3dsSoftware",
  setRemoveAssetsTime = "InvokeHandle::setRemoveAssetsTime",
  getCurrent3dsMaxVersion = "InvokeHandle::getCurrent3dsMaxVersion",
  getLatest3dsMaxVersion = "InvokeHandle::getLatest3dsMax",
  getPcName = "InvokeHandle::getPcName",
  SwitchToClients = "InvokeHandle::SwitchToClients",
  SwitchBackAdmin = "InvokeHandle::SwitchBackAdmin",
  IsSwitchedFromAdmin = "InvokeHandle::IsSwitchedFromAdmin"
}

export enum IpcSender {
  UpdatePcInfor = "Application::UpdatePcInfo",
  GetOutputFolderPath = "InvokeHandle::GetOutputFolderPath",
  ShowMessageBox = "Window::ShowMessageBox::Response",
  OpenFileDialog = "Sender::OpenFileDialog",
  SaveFileDialog = "Sender::SaveFileDialog",
  OpenDirectoryDialog = "Sender::OpenDirectoryDialog",
  HideThisWindow = "Sender::HideThisWindow",
  ShowThisWindow = "Sender::ShowThisWindow",
  CloseThisWindow = "Sender::CloseThisWindow",
  MinimizeThisWindow = "Sender::MinimizeThisWindow",
  MaximizeThisWindow = "Sender::MaximizeThisWindow",
  Hide = "Sender::Hide",
  Show = "Sender::Show",
  Close = "Sender::Close",
  Minimize = "Sender::Minimize",
  Maximize = "Sender::Maximize",
  CmdOpenUrl = "Sender::CmdOpenUrl",
  CmdOpenFile = "Sender::CmdOpenFile",
  CmdExecute = "Sender::CmdExecute",
  Logout = "Sender::Logout",
  InsertRenderQueue = "Sender::InsertRenderQueue",
  InjectAssetTrackings = "Sender::InjectAssetTrackings",
  FindAssets = "Sender::FindAssets",
  CreateWindowWithStaticRoute = "Sender::CreateWindowWithStaticRoute",
  CreateWindowWithDynamicRoute = "Sender::CreateWindowWithDynamicRoute",
  SaveToDatabase = "Sender::SaveToDatabase",
  LoadFromDatabase = "Sender::LoadFromDatabase",
  OpenTemporaryChooseModal = "Sender::OpenTemporaryChooseModal",
  Open3DApplicationPreferences = "Sender::Open3DApplicationPreferences",
  OpenStartRenderDialog = "Sender::OpenStartRenderDialog",
  RestoreThisWindow = "Sender::RestoreThisWindow",
  Install3dsMaxPlugin = "Sender::installplugin_3dsMax",
  Remove3dsMaxPlugin = "Sender::removeplugin_3dsMax",
  OpenedSettingDialog = "Sender::OpenedSettingDialog",
  OpenFolderDialog = "Sender::OpenFolderDialog",
  OpenFileDialog2 = "Sender::OpenFileDialog2",
  DeleteObject = "Sender::DeleteObject",
  CreateFolder = "Sender::CreateFolder",
  SetAutoSimulateTrayWindow = "Sender::SetAutoSimulateTrayWindow",
  setTemporaryPath = "Sender::SetTemporaryPath",
  setOutputFolder = "Sender::SetOutputFolder",
  SetMaxUploadParallel = "Sender::SetMaxUploadParallel",
  dropUploadFiles = "Sender::DropUploadFiles",
  SyncOutput = "Sender::SyncOutput",
  getTemporaryPath = "Sender::GetTemporaryPath",
  setCredentials = "Sender::SetCredentials",
  OpenOutputFolder = "Sender::OpenOutputFolder",
  ShowDashboard = "Sender::ShowDashboard",
  SignInFailed = "Sender::SignInFailed",
  SetCredentials = "Sender::SetCredentials",
  ReScanButtonClickHandler = "Sender::ReScanButtonClickHandler",
  PluginConstructor = "Sender::PluginConstructor",
  JobHasbeenCreated = "Sender::JobHasbeenCreated",
  ValidateOutputFolders = "InvokeSender::ValidateOutputFolder",
  RequestFolderPath = "InvokeSender::RequestFolderPath",
  createSrfFolder = "InvokeSender::CreateSrfFolder",
  ShowAutoPackageFolder = "Sender::ShowAutoPackageFolder",
  OnInstallPluginError = "Sender::OnInstallPluginError",
  OnInstallPluginOK = "Sender::OnInstallPluginOK",
  SwitchCredentials = "Sender::SwitchCredentials"
}

export type LoginApiResponse = {
  expires_in: number;
  token: string;
  token_type: "bearer";
};

export enum SettingOptions {
  SetAutoSimulateTrayWindow = "Setting::AutoSimulateTrayWindow",
  SetTemporaryPath = "Setting::TemporaryPath"
}

export enum IpcResponse {
  ShowMessageBox = "Window::ShowMessageBox::Response",
  OpenFileDialog = "Response::OpenFileDialog",
  SaveFileDialog = "Response::SaveFileDialog",
  OpenDirectoryDialog = "Response::OpenDirectoryDialog",
  HideThisWindow = "Response::HideThisWindow",
  ShowThisWindow = "Response::ShowThisWindow",
  CloseThisWindow = "Response::CloseThisWindow",
  MinimizeThisWindow = "Response::MinimizeThisWindow",
  MaximizeThisWindow = "Response::MaximizeThisWindow",
  Hide = "Response::Hide",
  Show = "Response::Show",
  Close = "Response::Close",
  Minimize = "Response::Minimize",
  Maximize = "Response::Maximize",
  List3DApplications = "Response::List3dsApplications",
  StartRenderDialog = "Response::StartRenderDialog",
  SettingDialog = "Response::SettingDialog",
  OpenFolderDialog = "Response::OpenFolderDialog",
  OpenFileDialog2 = "Reponse::OpenFileDialog2",
  DeleteObject = "Reponse::DeleteObject",
  ReloadClouStorage = "Reponse::ReloadClouStorage",
  SyncOutput = "Sender::SyncOutput"
}
