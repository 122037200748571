import { IrradMapMode, LightCacheMode, PHPBOOL, VrayGIPrimaryType, VrayGISecondaryType, ImageSamplerType, JobType } from '../../Interfaces/App.enums';
import { BmpType, ExrCompression, ExrMainFormat, ExrType, FrontVrayConfiguration, IGammaConfig, IImageSampler, IVrayOptions, PngType, SceneInterface,TifCompression,TifType,_3dSoftwareInterface } from "../../Interfaces/Renderer";
import * as jwt from 'jsonwebtoken'
import { Override } from '../../Helpers/types';
import { ISceneAnalyze } from './SceneAnalysis.interface';


// Default render output setup
// Renderoutput này sử dụng PHPBOOL,
// Khi submit lên API, các tham số không giữ nguyên ở dạng boolean true/false
// Mà tùy theo tham số sẽ có một trạng thái on off 1 0 khác nhau
export type OutputParamsProperty = GammaCorrectionType & {
    /**
     * Output extension configuration
     * @example
     */
     multipassoutput_folder?: "",
     outputNameFile?: string,
     output_extension?: string,
     // Png
     png_type?: string, // Colors
     png_alpha?: PHPBOOL // ratio
     png_interlaced?: PHPBOOL // ratio
     // Bmp
     bmp_type?: BmpType,
     // Jpg / Jpeg
     jpg_quality?: number,
     jpg_smoothing?: number,
     // Cjpg
     cjpg_quality?: string,
     cjpg_smoothing?: string,
     // Cjp
     cjp_quality?: string,
     cjp_smoothing?: string,
     // Tga
     tga_alphasplit?: PHPBOOL,
     tga_colordepth?: number,
     tga_compressed ?: PHPBOOL,
     tga_premultalpha?: PHPBOOL,
     // Exr
     exr_compression?: ExrCompression,
     exr_main_layer_format?: ExrMainFormat,
     exr_main_layer_type ?:ExrType
     exr_storage ?: PHPBOOL,
     exr_image_region?: PHPBOOL,
     exr_render_elements ?: PHPBOOL,
     exr_isAutoAddRenderElements ?: PHPBOOL,
    exr_num_of_element_layer?: number,
    exr_element_layer_name?: string,
     // Tif
     tif_dpi?: number,// Dots per inch
     tif_type?: TifType,
     tif_alpha?:PHPBOOL,
     tif_compression?: TifCompression
}

// ?
type PHPBOOLToBoolean<Type> = {
    [Property in keyof Type]: Type[Property] extends PHPBOOL ? boolean : Type[Property]
};
// Override the default type from PHPBOOL to native boolean
// Chuyển đổi tất cả kiểu dữ liệu của các trường PHPBOOL thành boolean native.
export type OutputParamsBoolean = Override<OutputParamsProperty,{
    png_alpha?: boolean // ratio
    png_interlaced?: boolean // ratio
    tga_alphasplit?: boolean,
    tga_compressed ?: boolean,
    tga_premultalpha?: boolean,
    exr_storage ?: boolean,
    exr_image_region?: boolean,
    exr_render_elements ?: boolean,
    exr_isAutoAddRenderElements ?: boolean,
    tif_alpha?:boolean,
    gamma_correction ?: boolean,
    input_gamma?:number
    output_gamma?: number
}>

export enum OutputParamsPropertyKeys {
    multipassoutput_folder = "multipassoutput_folder",
    outputNameFile = "outputNameFile",
    output_extension = "output_extension",
    // Png
    png_type = "png_type",
    png_alpha = "png_alpha",
    png_interlaced = "png_interlaced",
    // Bmp
    bmp_type = "bmp_type",
    // Jpg / Jpeg
    jpg_quality = "jpg_quality",
    jpg_smoothing = "jpg_smoothing",
    // Cjpg
    cjpg_quality = "cjpg_quality",
    cjpg_smoothing = "cjpg_smoothing",
    // Cjp
    cjp_quality = "cjp_quality",
    cjp_smoothing = "cjp_smoothing",
    // Tga
    tga_alphasplit = "tga_alphasplit",
    tga_colordepth = "tga_colordepth",
    tga_compressed = "tga_compressed",
    tga_premultalpha = "tga_premultalpha",
    // Exr
    exr_compression = "exr_compression",
    exr_main_layer_format = "exr_main_layer_format",
    exr_type = "exr_type",
    exr_storage = "exr_storage",
    exr_image_region = "exr_image_region",
    exr_render_elements = "exr_render_elements",
    exr_isAutoAddRenderElements = "exr_isAutoAddRenderElements",
    // Tif
    tif_dpi = "tif_dpi",
    tif_type = "tif_type",
    tif_alpha = "tif_alpha",
    tif_compression = "tif_compression",

    // Gamma correction
    input_gamma = "input_gamma",
    output_gamma = "output_gamma",
    gamma_correction  = "gamma_correction ",


    // Buffer Vray
    output_saveRawFile = "output_saveRawFile",
    output_splitgbuffer = "output_splitgbuffer",
}

export type RenderFlags = {
    rf_compute_advanced_lighting?: PHPBOOL
    rf_convert_area_lights_to_points_sources?: PHPBOOL
    rf_force_two_sided?: PHPBOOL
    rf_perform_color_check?: PHPBOOL
    rf_perform_displacement_mapping?: PHPBOOL
    rf_perform_render_effects?: PHPBOOL
    rf_render_flags?: PHPBOOL
    rf_render_hidden_objects?: PHPBOOL
    rf_toggles_output_dithering_paletted?: PHPBOOL
    rf_toggles_output_dithering_true_color?: PHPBOOL
    rf_use_advanced_lighting?: PHPBOOL
    rf_use_atmospherics_effects?: PHPBOOL
    rf_use_super_black?: PHPBOOL
}

export type GammaCorrectionType = {
    input_gamma?:string
    output_gamma?: string
    gamma_correction?: PHPBOOL
}


// body's Interface of http body when submit to api
export type HttpSceneBody  = OutputParamsProperty & RenderFlags &  FrontVrayConfiguration & GammaCorrectionType & {
    mod_animation_prepass ?: PHPBOOL
    analyze_result : any;
    scene_info : any;
    camera?: string
    selected_cameras?: string[]
    csrf_name?: string
    csrf_value?: string
    display_cloud_type?: string
    end_frame?: number
    farm_manager ?:"deadline"
    frame?: number
    frame_per_task?: number
    frame_steps?: number
    gcpu_type?: string
    job_type?: JobType
    lights?: string[] // this list only active light sent to deadline monitor
    multipassoutput_folder?: string
    outputNameFile?: string
    output_extension?: string
    ratio?: number
    render_priority?: RenderPriorityNumber
    renderer_version?: string
    renderheight?: number
    renderoutput_path?: string
    renderwidth?: number
    scene_3dApp?: string
    scene_history_info_id?: number
    scene_name?: string
    scene_path?: string
    send_from_scene_analyze?: string
    software_version?: string
    srf_cloud_type?: "awss3"|"dbx"|"gdrive"|""
    start_frame?: number
    user_name?: string
    warning_file_type_error?: string
    warning_scene_name_error?: string
    working_folder_path?: string
}

export interface RenderDatabaseQuery {
    key : "render_query_queue"
    created_at : number
    body : HttpSceneBody
    status : "queued" | "sent"
}

export interface SceneBodyInterface {
    files: string[] // assets of scene file
    maxPath : string // path to scene file
    scene : SceneInterface
    version : number //
    FarmDirectory: string;
    autoStartRender :boolean
}
export interface SceneResponseInterface {
    body : SceneBodyInterface
    _3dEngine : _3dSoftwareInterface
    FarmDirectory : string
}

export enum RenderPriorityValue {
    Low = "low",
    Medium = "medium",
    High = "high",
}
export enum RenderPriorityNumber {
    Low = 10,
    Medium = 20,
    High = 30,
}
export type RenderPriorityType = RenderPriorityValue
export type RenderConfigurationInterface = OutputParamsProperty & RenderFlags &{
    frame_per_task:number
    gamma_config?: IGammaConfig
    render_priority?: RenderPriorityNumber,
    // String or ruled string
    camera?: string,
    display_cloud_type?: string,
    farm_manager?: string,
    gcpu_type?: string,
    job_type?: string,
    lights?: [],
    software_version?: string,
    // Boolean ?
    output_grammar_corection?: PHPBOOL,
    send_from_scene_analyze?: string,
    renderoutput_path?: string,
    // Number types
    user_name?: string,
    working_folder_path?: string
    end_frame?: number,
    scene_history_info_id?: number,
    warning_file_type_error?: number,
    warning_scene_name_error?: number,

}


/**
 * Output extension configuration
 * bmp, png,...
 *
 */
export type OutputExtension = {

}

export interface RenderOptionsInterface extends SceneResponseInterface {
    render_configuration:RenderConfigurationInterface;
    Analysis ?: ISceneAnalyze
}

export interface JwtPayload extends jwt.JwtPayload {
    user_name: string;
    email: string;
    full_name: string;
    is_admin?: any;
    created_at: Date;
    googletag_display_permission: number;
    socket_private_id: string;
    socket_public_id: string;
}
