import React, { useState, useEffect } from 'react'
import './main-window.scss'
import BottomBar from './Layout/BottomBar'
import CloudStorage from './Components/CloudStorage'
import RenderJobs from './Components/RenderJobs'
import PluginsManager from './Components/PluginsManager'
import { useShortcut, useShortcutMap } from '../../Helpers/Shortcut'
import Titlebar from './Layout/Titlebar'
import { GetWaiter } from '../../Helpers/Waiter'
import { IWebUserConfig } from '../../Interfaces/User'
import Page404 from '../Page404'
import { SuperTransferEvent } from '../../Interfaces/IpcRenderer.enum'

const electron = window.require('electron')
const { ipcRenderer } = window.require("electron")
ipcRenderer.on("set_jwt", (event, jwt) => {
    localStorage.setItem("access_token", jwt)
})

interface MainWindowProps {
}
export default function MainWindow({ }: MainWindowProps) {
    const [tab, setTab] = useState(2);
    const [isLoading, setIsLoading] = useState(false);


    const refresh = () => {
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)

        }, 500);

    }

    useShortcutMap((shortcut) => {
        switch (shortcut) {
            case "Ctrl+1":
                return setTab(1)
            case "Ctrl+2":
                return setTab(2)
            case "Ctrl+3":
                return setTab(3)
            case "Ctrl+0":
                return console.log("Requesting support ...")
        }
    });

    useEffect(() => {
        ipcRenderer.on(SuperTransferEvent.UPLOAD_COMPLETED, (event, context) => {
            refresh()
        })
        // check is user_profiles has enabled
        GetWaiter<IWebUserConfig>(() => JSON.parse(localStorage.getItem('user_profiles') || "{}"))
            .then(() => {
                console.log("Storage initialed")
            })
            .catch(err => console.log(err))

    }, [])


    // listen on tab changes
    useEffect(() => {
        console.log(tab);

        ipcRenderer.invoke('task-job-counters').then((taskCount) => {
            if (taskCount === 0) {
                ipcRenderer.invoke('common-server-requests');
            }
        })

    }, [tab])

    return (
        <>
            <Titlebar />
            <div className="App">
                <div className="AppContent">
                    {/* Navigation */}
                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <button className={`nav-link ${tab === 1 ? "active" : ""}`} onClick={() => setTab(1)}>
                                <i className="fad fa-hdd me-2"></i>SRF SPACES
                            </button>
                        </li>
                        <li className="nav-item">
                            <button className={`nav-link ${tab === 2 ? "active" : ""}`} onClick={() => setTab(2)}>
                                <i className="fad fa-tasks-alt me-2"></i>RENDER JOBS
                            </button>
                        </li>
                        <li className="nav-item">
                            <button className={`nav-link ${tab === 3 ? "active" : ""}`} onClick={() => setTab(3)}>
                                <i className="fad fa-terminal me-2"></i>PLUGIN
                            </button>
                        </li>
                        <li className="nav-item ms-auto">
                            <button className="nav-link" onClick={refresh}>
                                <i className="fad fa-redo-alt me-2"></i>REFRESH
                            </button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link" onClick={() => electron.shell.openExternal('https://superrendersfarm.com/account/render-dashboard')}>
                                <i className="fad fa-home me-2"></i>RENDER DASHBOARD
                            </button>
                        </li>
                    </ul>
                    {/* Tab content */}
                    <div className="tabContent" >
                        {
                            !isLoading ? (tab === 1 ? <CloudStorage />
                                : tab === 2 ? <RenderJobs />
                                    : tab === 3 ? <PluginsManager />
                                        : <Page404 />) : <></>
                        }
                    </div>
                </div>
            </div>
            {/* Bottombar */}

            <BottomBar />
        </>
    )
}