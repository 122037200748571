import { ISceneAnalyze } from '../../screens/RenderNow/SceneAnalysis.interface';

export enum AnalysisActionType {
    Reload = 'Reload',
    SagaFetch = 'Analysis::SagaFetch',
    SetItems = 'Analysis::SetItems',
    SetLoading = 'Analysis::SetLoading',
    Search = 'Analysis::Search',
    RemoveItems = 'Analysis::RemoveItems'
}

export type AnalysisAction<T> = {
    payload : T,
    type : AnalysisActionType
}

export interface AnalysisStates {
    isLoading : boolean,
    query_results : ISceneAnalyze[],
    query : string,
    items : ISceneAnalyze[],
}