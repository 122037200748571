export interface SpaceItemMetadata {
    key : string;
    description?: string;
    value : any
}

export interface SpaceItemExtra {
    Region: "us-east-1" | "ap-southeast-1";
    Etag : string;
    LastModified ?: Date;
    ContentLength : number;
}

//export type SpaceItemType = "DRIVE" | "FOLDER" | "SCENE" | "ASSET" | "OTHER"
export enum SpaceItemType {
    Drive = 100,
    Folder = 90,
    Scene = 80,
    Compressed = 70,
    Executable = 60,
    Asset = 50,
    Other = 40,
}
export enum SpaceItemTypeName {

}
export interface SpaceItem {
    name : string;
    path ?: string;
    key : string;
    url ?: string;
    type : SpaceItemType
    isCompressed ?: boolean;
    isSelected ?: boolean;
    isDownloadable ?: boolean;
    extension ?: string;
    isPublic ?: boolean
    size ?: number;
    parent ?: string;
    metadata ?: SpaceItemMetadata[]
    extra ?: SpaceItemExtra
}

export interface Spaces {
    breadcrumbs: string[]
    currentPath: string
    parentPath ?: string
    rootPath: string
    items: SpaceItem[],
    query_results ?: SpaceItem[],
    query ?: string,
    isLoading: boolean,
}

export interface SpacesAction<Type> {
    type : string;
    payload : Type
}

export enum CloudStorageAction {
    INITIALIZE_STORAGE  = "INITIALIZE_STORAGE",
    SET_BREADCRUMBS = "SET_BREADCRUMBS",
    SET_CURRENT_PATH = "SET_CURRENT_PATH",
    SET_PARENT_PATH = "SET_PARENT_PATH",
    SET_ROOT_PATH = "SET_ROOT_PATH",
    SET_ITEMS = "SET_ITEMS",
    SET_IS_SELECTED = "SET_IS_SELECTED",
    SET_IS_DOWNLOADABLE = "SET_IS_DOWNLOADABLE",
    SET_METADATA = "SET_METADATA",
    SET_EXTRA = "SET_EXTRA",
    SET_IS_COMPRESSED = "SET_IS_COMPRESSED",
    SET_IS_PUBLIC = "SET_IS_PUBLIC",
    SET_SIZE = "SET_SIZE",
    SET_UPDATED_AT = "SET_UPDATED_AT",
    SET_PARENT = "SET_PARENT",
    SET_NAME = "SET_NAME",
    SET_PATH = "SET_PATH",
    SET_KEY = "SET_KEY",
    SET_URL = "SET_URL",
    SET_TYPE = "SET_TYPE",
    SET_IS_SELECTED_ALL = "SET_IS_SELECTED_ALL",
    SET_IS_DOWNLOADABLE_ALL = "SET_IS_DOWNLOADABLE_ALL",
    Migrate = "Migrate",
    SetItems = "CloudStorage::SetItems",
    SagaFetch = "CloudStorage::SagaFetch",
    SagaFinder = "CloudStorage::SagaFinder",
    Search = "CloudStorage::Finder",
    Load = "CloudStorage::Load",
    Reload = "CloudStorage::Reload",
    Loaded = "CloudStorage::Loaded",
    Delete = "CloudStorage::Delete",
    RemoveObject = "CloudStorage::RemoveObject",
}