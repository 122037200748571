import { put, takeEvery, all } from 'redux-saga/effects'
import { SagaHelloWorld } from './sagas/default.saga'
import { watchFetchCloudObjects, watchFetchAnalysisList, watchFetchListRenderList } from './sagas/watcher'

export function* DefaultSaga() {
    yield all([
        SagaHelloWorld(),
        watchFetchCloudObjects(),
        watchFetchAnalysisList(),
        watchFetchListRenderList()
    ])
}