import React, { useState } from 'react'
import './render-without-analyze.scss'

interface RenderWithoutAnalyzeProps {
}

export default function RenderWithoutAnalyze({}: RenderWithoutAnalyzeProps) {
     const [hardware, setHardware] = useState("CPU")
     const [gamma, setGamma] = useState(false)
     function switchGamma() {
          gamma?setGamma(false):setGamma(true)
     }
     const [renderFlags, setRenderFlags] = useState(false)
     function switchRenderFlags() {
          renderFlags?setRenderFlags(false):setRenderFlags(true)
     }
     return (
          <div className="render-without-analyze">
               <div className="card-body">
                    <div className="form-group mb-3">
                         <small className="text-uppercase" >
                              Scene Path*
                              <small className="text-muted text-capitalize">
                                   <i className="fad fa-folder ms-2" /> Your project saved in Farm hard drive as N:/.
                              </small>
                         </small>
                         <div className="form-control-wrapper form-control-icon-left" style={{ position: "relative" }}>
                              <input id="scene_path" type="text" className="form-control srf_require_field" disabled
                                   placeholder="Scene Path" style={{ paddingLeft: "35px",paddingTop:"7px" }} />
                              <div className="text-light" style={{
                                   width: "38px",
                                   height: "37px",
                                   lineHeight: "37px",
                                   textAlign: "center",
                                   position: "absolute",
                                   top: "0px",
                              }}>
                                   <i className="fa fa-folder" />
                              </div>
                         </div>
                    </div>
                    <small className="text-uppercase" >Select a hardware</small>
                    <div className="hardware-select mb-3">
                         <div className={`radio-button btn btn-dark me-3 ${hardware==="CPU"?"active":""}`}  onClick={()=> setHardware("CPU")}>
                              <div className="form-check mb-0">
                                   <input className="form-check-input" id="CPU" type="radio" name="hardware" checked={hardware==="CPU"} />
                                   <label className="form-check-label" htmlFor="CPU">CPU</label>
                              </div>
                         </div>
                         <div className={`radio-button btn btn-dark ${hardware==="GPU"?"active":""}`}  onClick={()=> setHardware("GPU")} >
                              <div className="form-check mb-0">
                                   <input className="form-check-input" id="GPU" type="radio" name="hardware" checked={hardware==="GPU"}/>
                                   <label className="form-check-label" htmlFor="GPU">GPU</label>
                              </div>
                         </div>
                    </div>
                    <div className="d-flex gap-3 mb-3">
                         <div className="form-group col">
                              <small className="text-uppercase">Software Version*</small>

                    
                              <select className="form-select">
                                   <option>Choose software version...</option>
                                   <option value="3dsmax_2022">Autodesk 3ds Max 2022</option>
                                   <option value="3dsmax_2021">Autodesk 3ds Max 2021</option>
                                   <option value="3dsmax_2020">Autodesk 3ds Max 2020</option>
                                   <option value="3dsmax_2019">Autodesk 3ds Max 2019</option>
                                   <option value="3dsmax_2018">Autodesk 3ds Max 2018</option>
                                   <option value="3dsmax_2017">Autodesk 3ds Max 2017</option>
                                   <option value="3dsmax_2016">Autodesk 3ds Max 2016</option>
                                   <option value="3dsmax_2015">Autodesk 3ds Max 2015</option>
                              </select>
                         </div>
                         <div className="form-group col">
                              
                              <small className="text-uppercase">Render Engine Version*</small>

                              <select className="form-select">
                                   <option>Choose engine version...</option>
                                   <optgroup label="Redshift">
                                        <option value="redshift3_0_gpu" disabled={hardware!=="GPU"}>Redshift 3.0 (GPU)</option>
                                   </optgroup>
                                   <optgroup label="V-Ray">
                                        <option value="vray_5_cpu" disabled={hardware!=="CPU"}>V-Ray 5 (CPU)</option>
                                        <option value="vray_5_gpu" disabled={hardware!=="GPU"}>V-Ray 5 (GPU)</option>
                                        <option value="vray_next_cpu" disabled={hardware!=="CPU"}>V-Ray Next 4.3 (CPU)</option>
                                        <option value="vray_next_gpu" disabled={hardware!=="GPU"}>V-Ray Next 4.3 (GPU)</option>
                                        <option value="vray_4_2_cpu" disabled={hardware!=="CPU"}>V-Ray Next 4.2 (CPU)</option>
                                        <option value="vray_3">V-Ray 3.x</option>
                                        <option value="vray4phoenix4">V-Ray 4 Phoenix 4</option>
                                        <option value="vray3phoenix3">V-Ray 3 Phoenix 3</option>
                                   </optgroup>
                                   <optgroup label="Corona">
                                        <option value="corona6">Corona 6</option>
                                        <option value="corona5">Corona 5</option>
                                        <option value="corona4">Corona 4</option>
                                        <option value="corona3">Corona 3</option>
                                        <option value="corona2">Corona 2</option>
                                        <option value="corona17">Corona 1.7</option>
                                        <option value="corona16">Corona 1.6</option>
                                   </optgroup>
                                   <optgroup label="Arnold">
                                        <option value="arnold4_0_1_cpu" disabled={hardware!=="CPU"}>MAXtoA 4 (CPU)</option>
                                        <option value="arnold4_0_1_gpu" disabled={hardware!=="GPU"}>MAXtoA 4 (GPU)</option>
                                        <option value="arnold3">MAXtoA 3</option>
                                   </optgroup>
                                   <optgroup label="3ds Max">
                                        <option value="quicksilver_hardware">Quicksilver Hardware</option>
                                        <option value="art">ART</option>
                                        <option value="scanline">Scanline</option>
                                        <option value="veu_file">VEU File</option>
                                   </optgroup>
                              </select>
                         </div>
                    </div>
                    <div className="nav nav-tabs mb-2" id="v-pills-tab" role="tablist" aria-orientation="vertical">

                         <a className="nav-item nav-link active" id="v-pills-render-max" data-bs-toggle="pill"
                              href="#render_type_animation" role="tab" aria-controls="render_type_animation"
                              aria-selected="true">
                              Animation
                         </a>
                         <a className="nav-item nav-link" id="v-pills-render-maya" data-bs-toggle="pill"
                              href="#render_type_singlestill" role="tab" aria-controls="render_type_singlestill"
                              aria-selected="false">
                              Still Image
                         </a>
                    </div>
                    <div className="tab-content card card-body mb-3" id="v-pills-tabContent">
                         <div className="tab-pane fade show active" id="render_type_animation" role="tabpanel"
                              aria-labelledby="render_type_animation-tab">
                              <div className="row">
                                   <div className="col-lg-2" id="start_frame_form_group">
                                        <div className="form-group">
                                             <label id="start_frame_label" className="form-label semibold">
                                                  Range From*
                                             </label>
                                             <div className="form-control-wrapper">
                                                  <input id="start_frame" type="number"
                                                       className="form-control srf_require_field"
                                                       placeholder="Start From" />
                                                  <small className="text-muted" />
                                             </div>
                                        </div>
                                   </div>
                                   <div className="col-lg-2" id="end_frame_form_group">
                                        <div className="form-group">
                                             <label id="end_frame_label" className="form-label semibold">
                                                  To*
                                             </label>
                                             <div className="form-control-wrapper">
                                                  <input id="end_frame" type="number"
                                                       className="form-control srf_require_field" placeholder="To" />
                                                  <small className="text-muted" />
                                             </div>
                                        </div>
                                   </div>
                                   <div className="col-lg-3" id="frame_steps_form_group">
                                        <div className="form-group">
                                             <label id="frame_steps_label" className="form-label semibold">
                                                  Every Nth Frame*
                                             </label>
                                             <div className="form-control-wrapper">
                                                  <input id="frame_steps" type="number" min={1}
                                                       className="form-control srf_require_field"
                                                       placeholder="Frame Steps" />
                                                  <small className="text-muted" />
                                             </div>
                                        </div>
                                   </div>
                                   <div className="col-lg-5" id="renderoutput_file_form_group">
                                        <div className="form-group m-0">
                                             <label className="form-label semibold">Render Output File*</label>
                                             <div className="form-control-wrapper">
                                                  <input id="renderoutput_file" type="text" className="form-control"
                                                       placeholder="e.g: filename" />
                                                  <small className="text-muted">
                                                       Output File allowed: png/tif/tga/bmp/jpg/exr and hdr
                                                  </small>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className="row">
                                   <div className="col-lg-7">
                                        <div id="camera_form_group" className="form-group">
                                             <label id="camera_label" className="form-label semibold">
                                                  Camera
                                             </label>
                                             <div className="form-control-wrapper" id="camera_form_group_container_1">
                                                  <input id="camera" type="text" className="form-control"
                                                       placeholder="Leave blank to select active camera in your scene" />
                                             </div>
                                        </div>
                                   </div>
                                   <div className="col-lg-5">
                                        <div id="batch_form_group" className="form-group mb-0">
                                             <label id="batch_label" className="form-label semibold">
                                                  Batch To Render
                                             </label>
                                             <div className="form-control-wrapper">
                                                  <input id="batch" type="text" className="form-control"
                                                       placeholder="Leave blank if not use" />
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className="mod-animation-prepass-vray d-block d-lg-flex mt-2">
                                   <label id="render_type_label" className="form-label me-2  my-auto">
                                        V-Ray Options:
                                   </label>
                                   <div className="form-check">
                                        <input type="checkbox" id="check-prepass-vray" className="form-check-input" />
                                        <label htmlFor="check-prepass-vray" className="form-check-label">
                                             GI: Mode Animation Prepass/Rendering (
                                             <a className="text-success"
                                                  href="https://support.superrendersfarm.com/hc/en-us/articles/360040794194-Animation-prepass-rendering-with-V-Ray-Next"
                                                  target="_blank">
                                                  see more
                                             </a>
                                             )
                                        </label>
                                   </div>
                              </div>
                         </div>
                         <div className="tab-pane fade" id="render_type_singlestill" role="tabpanel"
                              aria-labelledby="render_type_singlestill-tab">
                              <div className="row">
                                   <div className="col-lg-3" id="image_width_form_group">
                                        <div className="form-group">
                                             <label id="image_width_label" className="form-label semibold">
                                                  Width*
                                             </label>
                                             <div className="form-control-wrapper">
                                                  <input id="image_width" type="number" className="form-control"
                                                       placeholder="Image Width" />
                                                  <small className="text-muted" />
                                             </div>
                                        </div>
                                   </div>
                                   <div className="col-lg-3" id="image_height_form_group">
                                        <div className="form-group">
                                             <label id="image_height_label" className="form-label semibold">
                                                  Height*
                                             </label>
                                             <div className="form-control-wrapper">
                                                  <input id="image_height" type="number" className="form-control"
                                                       placeholder="Image Height" />
                                                  <small className="text-muted" />
                                             </div>
                                        </div>
                                   </div>
                                   <div className="col-lg-6" id="renderoutput_file_form_group">
                                        <div className="form-group m-0">
                                             <label className="form-label semibold">Render Output File*</label>
                                             <div className="form-control-wrapper">
                                                  <input id="renderoutput_file" type="text" className="form-control"
                                                       placeholder="e.g: filename" />
                                                  <small className="text-muted">
                                                       Output File allowed: png/tif/tga/bmp/jpg/exr and hdr
                                                  </small>
                                             </div>
                                        </div>
                                   </div>
                                   <div className="col-lg-12">
                                        <div id="camera_form_group" className="form-group">
                                             <label id="camera_label" className="form-label semibold">
                                                  Camera
                                             </label>
                                             <div className="form-control-wrapper" id="camera_form_group_container_1">
                                                  <input id="camera" type="text" className="form-control"
                                                       placeholder="Leave blank to select active camera in your scene" />
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
                    <div className="d-flex">
                         <div className="col-lg-10">
                              <div className="form-check form-switch">
                                   <input className="form-check-input" type="checkbox" role="switch" id="render-flags" onClick={()=>switchRenderFlags()} />
                                   <label className="form-check-label" htmlFor="render-flags">Render Flags</label>
                              </div>
                              {
                                   renderFlags?<div className="row" id="render-flags-box">
                                   <div className="col-4 mb-2">
                                        <div className="form-check">
                                             <input id="rf_perform_color_check" type="checkbox"
                                                  className="form-check-input" />
                                             <label htmlFor="rf_perform_color_check"
                                                  className="form-check-label">
                                                  Perform Color Check
                                             </label>
                                        </div>
                                   </div>
                                   <div className="col-4 mb-2">
                                        <div className="form-check">
                                             <input id="rf_force_two_sided" type="checkbox"
                                                  className="form-check-input" />
                                             <label htmlFor="rf_force_two_sided" className="form-check-label">
                                                  Force Two-Sided
                                             </label>
                                        </div>
                                   </div>
                                   <div className="col-4 mb-2">
                                        <div className="form-check">
                                             <input id="rf_render_hidden_objects" type="checkbox"
                                                  className="form-check-input" />
                                             <label htmlFor="rf_render_hidden_objects"
                                                  className="form-check-label">
                                                  Render Hidden Objects
                                             </label>
                                        </div>
                                   </div>
                                   <div className="col-4 mb-2">
                                        <div className="form-check">
                                             <input id="rf_use_atmospherics_effects" type="checkbox"
                                                  className="form-check-input" />
                                             <label htmlFor="rf_use_atmospherics_effects"
                                                  className="form-check-label">
                                                  Use Atmospherics Effects
                                             </label>
                                        </div>
                                   </div>
                                   <div className="col-4 mb-2">
                                        <div className="form-check">
                                             <input id="rf_use_super_black" type="checkbox"
                                                  className="form-check-input" />
                                             <label htmlFor="rf_use_super_black" className="form-check-label">
                                                  Use Super Black
                                             </label>
                                        </div>
                                   </div>
                                   <div className="col-4 mb-2">
                                        <div className="form-check">
                                             <input id="rf_perform_displacement_mapping" type="checkbox"
                                                  className="form-check-input" />
                                             <label htmlFor="rf_perform_displacement_mapping"
                                                  className="form-check-label">
                                                  Perform Displacement Mapping
                                             </label>
                                        </div>
                                   </div>
                                   <div className="col-4 mb-2">
                                        <div className="form-check">
                                             <input id="rf_perform_render_effects" type="checkbox"
                                                  className="form-check-input" />
                                             <label htmlFor="rf_perform_render_effects"
                                                  className="form-check-label">
                                                  Perform Render Effects
                                             </label>
                                        </div>
                                   </div>
                                   <div className="col-4 mb-2">
                                        <div className="form-check">
                                             <input id="rf_use_advanced_lighting" type="checkbox"
                                                  className="form-check-input" />
                                             <label htmlFor="rf_use_advanced_lighting"
                                                  className="form-check-label">
                                                  Use Advanced Lighting
                                             </label>
                                        </div>
                                   </div>
                                   <div className="col-4 mb-2">
                                        <div className="form-check">
                                             <input id="rf_compute_advanced_lighting" type="checkbox"
                                                  className="form-check-input" />
                                             <label htmlFor="rf_compute_advanced_lighting"
                                                  className="form-check-label">
                                                  Compute Advanced Lighting
                                             </label>
                                        </div>
                                   </div>
                                   <div className="col-4">
                                        <div className="form-check">
                                             <input id="rf_toggles_output_dithering_paletted" type="checkbox"
                                                  className="form-check-input" />
                                             <label htmlFor="rf_toggles_output_dithering_paletted"
                                                  className="form-check-label">
                                                  Toggles Output Dithering (paletted)
                                             </label>
                                        </div>
                                   </div>
                                   <div className="col-4">
                                        <div className="form-check">
                                             <input id="rf_toggles_output_dithering_true_color"
                                                  type="checkbox" className="form-check-input" />
                                             <label htmlFor="rf_toggles_output_dithering_true_color"
                                                  className="form-check-label">
                                                  Toggles Output Dithering (true-color)
                                             </label>
                                        </div>
                                   </div>
                                   <div className="col-4">
                                        <div className="form-check">
                                             <input id="rf_convert_area_lights_to_points_sources"
                                                  type="checkbox" className="form-check-input" />
                                             <label htmlFor="rf_convert_area_lights_to_points_sources"
                                                  className="form-check-label">
                                                  Convert Area Lights To Points Sources
                                             </label>
                                        </div>
                                   </div>
                              </div> : <></>
                              }
                              
                         </div>

                         <div className="col-lg-2">
                              <div className="form-check form-switch">
                                   <input className="form-check-input" type="checkbox" role="switch" id="gamma" onClick={()=>switchGamma()} />
                                   <label className="form-check-label" htmlFor="gamma">Gamma Correction</label>
                              </div>
                              {
                                   gamma?<>
                                   <div className="form-group mb-2">
                                        <label htmlFor="input_gamma" className="control-label">
                                             Input Gamma*
                                        </label>
                                        <input id="input_gamma" type="number"
                                             className="form-control srf_require_field" placeholder="Input Gamma"
                                             defaultValue="2.2" />
                                   </div>
                                   <div className="form-group">
                                        <label htmlFor="output_gamma" className="control-label">
                                             Output Gamma*
                                        </label>
                                        <input id="output_gamma" type="number"
                                             className="form-control srf_require_field" placeholder="Output Gamma"
                                             defaultValue={1} />
                                   </div>
                                   </> : <></>
                              }
                         </div>
                    </div>
               
           
               </div>
          </div>
     )
}