import React from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../../../stores/stores'
import './render-flags.scss'
import { RenderFlagDispatcher } from './RenderFlags.dispatchs'


const MapStates = (States:RootState) => ({
     Scene : States.Render
})

type RenderFlagsProps = typeof RenderFlagDispatcher & ReturnType<typeof MapStates>
function RenderFlags(Renders: RenderFlagsProps) {

     const Scene = Renders.Scene

     return (
          <div className="row">
               <div className="col-4">
                    <div className="form-check">
                         <input  onChange={(e) => {Renders.switch_render_flags(e.target.id)}} id="rf_perform_color_check" checked={Scene.render_configuration.rf_perform_color_check == "1"} name="rf_perform_color_check" type="checkbox" className="form-check-input" />
                         <label htmlFor="rf_perform_color_check" className="form-check-label">Perform Color Check</label>
                    </div>
               </div>
               <div className="col-4">
                    <div className="form-check">
                         <input  onChange={(e) => {Renders.switch_render_flags(e.target.id)}} id="rf_force_two_sided" checked={Scene.render_configuration.rf_force_two_sided == "1"} name="rf_force_two_sided" type="checkbox" className="form-check-input" />
                         <label htmlFor="rf_force_two_sided" className="form-check-label">Force Two-Sided</label>
                    </div>
               </div>
               <div className="col-4">
                    <div className="form-check">
                         <input  onChange={(e) => {Renders.switch_render_flags(e.target.id)}} id="rf_render_hidden_objects" checked={Scene.render_configuration.rf_render_hidden_objects == "1"} name="rf_render_hidden_objects" type="checkbox" className="form-check-input" />
                         <label htmlFor="rf_render_hidden_objects" className="form-check-label">Render Hidden Objects</label>
                    </div>
               </div>
               <div className="col-4">
                    <div className="form-check">
                         <input  onChange={(e) => {Renders.switch_render_flags(e.target.id)}} id="rf_use_atmospherics_effects" checked={Scene.render_configuration.rf_use_atmospherics_effects == "1"} name="rf_use_atmospherics_effects" type="checkbox" className="form-check-input" />
                         <label htmlFor="rf_use_atmospherics_effects" className="form-check-label">Use Atmospherics Effects</label>
                    </div>
               </div>
               <div className="col-4">
                    <div className="form-check">
                         <input  onChange={(e) => {Renders.switch_render_flags(e.target.id)}} id="rf_use_super_black" checked={Scene.render_configuration.rf_use_super_black == "1"} name="rf_use_super_black" type="checkbox" className="form-check-input" />
                         <label htmlFor="rf_use_super_black" className="form-check-label">Use Super Black</label>
                    </div>
               </div>
               <div className="col-4">
                    <div className="form-check">
                         <input  onChange={(e) => {Renders.switch_render_flags(e.target.id)}} id="rf_perform_displacement_mapping" checked={Scene.render_configuration.rf_perform_displacement_mapping == "1"} name="rf_perform_displacement_mapping" type="checkbox" className="form-check-input" />
                         <label htmlFor="rf_perform_displacement_mapping" className="form-check-label">Perform Displacement Mapping</label>
                    </div>
               </div>
               <div className="col-4">
                    <div className="form-check">
                         <input  onChange={(e) => {Renders.switch_render_flags(e.target.id)}} id="rf_perform_render_effects" checked={Scene.render_configuration.rf_perform_render_effects == "1"} name="rf_perform_render_effects" type="checkbox" className="form-check-input" />
                         <label htmlFor="rf_perform_render_effects" className="form-check-label">Perform Render Effects</label>
                    </div>
               </div>
               <div className="col-4">
                    <div className="form-check">
                         <input  onChange={(e) => {Renders.switch_render_flags(e.target.id)}} id="rf_use_advanced_lighting" checked={Scene.render_configuration.rf_use_advanced_lighting == "1"} name="rf_use_advanced_lighting" type="checkbox" className="form-check-input" />
                         <label htmlFor="rf_use_advanced_lighting" className="form-check-label">Use Advanced Lighting</label>
                    </div>
               </div>
               <div className="col-4">
                    <div className="form-check">
                         <input  onChange={(e) => {Renders.switch_render_flags(e.target.id)}} id="rf_compute_advanced_lighting" checked={Scene.render_configuration.rf_compute_advanced_lighting == "1"} name="rf_compute_advanced_lighting" type="checkbox" className="form-check-input" />
                         <label htmlFor="rf_compute_advanced_lighting" className="form-check-label">Compute Advanced Lighting</label>
                    </div>
               </div>
               <div className="col-4">
                    <div className="form-check">
                         <input  onChange={(e) => {Renders.switch_render_flags(e.target.id)}} id="rf_toggles_output_dithering_paletted" checked={Scene.render_configuration.rf_toggles_output_dithering_paletted == "1"} name="rf_toggles_output_dithering_paletted" type="checkbox" className="form-check-input" />
                         <label htmlFor="rf_toggles_output_dithering_paletted" className="form-check-label">Toggles Output Dithering (paletted)</label>
                    </div>
               </div>
               <div className="col-4">
                    <div className="form-check">
                         <input  onChange={(e) => {Renders.switch_render_flags(e.target.id)}} id="rf_toggles_output_dithering_true_color" checked={Scene.render_configuration.rf_toggles_output_dithering_true_color == "1"} name="rf_toggles_output_dithering_true_color" type="checkbox" className="form-check-input" />
                         <label htmlFor="rf_toggles_output_dithering_true_color" className="form-check-label">Toggles Output Dithering (true-color)</label>
                    </div>
               </div>
               <div className="col-4">
                    <div className="form-check">
                         <input  onChange={(e) => {Renders.switch_render_flags(e.target.id)}} id="rf_convert_area_lights_to_points_sources" checked={Scene.render_configuration.rf_convert_area_lights_to_points_sources == "1"} name="rf_convert_area_lights_to_points_sources" type="checkbox" className="form-check-input" />
                         <label htmlFor="rf_convert_area_lights_to_points_sources" className="form-check-label">Convert Area Lights To Points Sources</label>
                    </div>
               </div>  
          </div>
     )
}
export default connect( MapStates, RenderFlagDispatcher)(React.memo(RenderFlags))