import axios from 'axios';
import React ,{useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../../../stores/stores'
import './account-title-bar.scss'
import { AccountTitleBarDispatcher } from './AccountTitleBar.dispatch';
import { API } from '../../../../../Interfaces/Api.enum';
import { LoginApiResponse } from '../../../../../Interfaces/Http';
import { IWebUserConfig } from '../../../../../Interfaces/User';
import { GetWaiter } from '../../../../../Helpers/Waiter';
import Modal from 'react-modal';
import { InvokeHandleMutation, IpcSender } from '../../../../../Interfaces/IpcRenderer.enum';
const {ipcRenderer} = window.require('electron')
const electron = window.require('electron')

const MapStates = (States:RootState) => ({
    Account: States.Account
})

type AccountProps = {

}
type AccountTitleBarProps = ReturnType<typeof MapStates> & AccountProps & typeof AccountTitleBarDispatcher


function LogoutHandler () {
    ipcRenderer.send('logout')
    localStorage.clear()
}


function AccountTitleBar(Props: AccountTitleBarProps) {
    const [AboutModal, setAboutModal] = useState(false)

    useEffect( () => {
        // Check is valid user and logout
        if( localStorage.getItem("access_token") === null ){
            ipcRenderer.send('logout')
        }

        ipcRenderer.on(IpcSender.SwitchCredentials, (event, credential) => {
            localStorage.setItem("access_token", credential)
        })

        const AuthenHeader = {
            headers : {
                Authorization : `Bearer ${localStorage.getItem("access_token") as string}`
            }
        }
        axios.get(API.GetCurrentUserProfile, AuthenHeader ).then( async ( response ) => {
            const userConfig = response.data as IWebUserConfig
            localStorage.setItem("user_profiles", JSON.stringify(userConfig))
            Props.SetFullName(userConfig.user.full_name)
            Props.SetMoney(userConfig.user.render_credits)
            // save user configs
            ipcRenderer.send("setUserConfigs", { key : "userProfile", data :userConfig} )
            // Save account credentials
            ipcRenderer.invoke(IpcSender.setCredentials, {
                username:userConfig.user.full_name,
                jwt : localStorage.getItem("access_token")
            })

            ipcRenderer.invoke(InvokeHandleMutation.setRemoveAssetsTime,  userConfig.user.last_scheduled_clean_input_at )

            if(userConfig.user.ban_prestep  === "deactivate_user") {
                // request ban user immediately
                await axios.put(API.SelfDisable, {} , AuthenHeader,  )
                // logout
                ipcRenderer.send('logout')
                return;
            }

            if(userConfig.user.flag_enabled ==0 ){
                // logout
                ipcRenderer.send('logout')
                return;
            }

        }).finally(() => {
            console.log("initialized application")
        }).catch( err => {
            // force logout
            ipcRenderer.send('logout')
        })

    },[])
    const money = Props.Account.credits
    return (
        <>
        <div className="dropdown my-auto account-title-bar">
            <button className="titlebar-button userBtn" type="button" id="user-button" data-bs-toggle="dropdown" aria-expanded="false">
                <div className="d-flex">
                    <div className="user-name text-truncate me-1">
                        {Props.Account.fullname}

                    </div>
                    <i className="fal fa-angle-down mt-1 me-2"></i>
                    <div className="ms-auto" >
                        <i className="fad fa-money-check me-2"></i>
                        <span className="current-credit">
                            {money ? ("$"+Math.round(money*100)/100) : <i className="fad spinner-third" /> } 
                        </span>
                    </div>
                </div>
            </button>

            <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-lg-end" aria-labelledby="user-button">
                <li>
                    <button type="button" className="dropdown-item" onClick={() => { 
                        electron.shell.openExternal('https://superrendersfarm.com/account/settings')
                    }}>
                        <i className="fad fa-user-cog me-1"></i> Account details
                    </button>
                </li>
                <li>
                    <button type="button" className="dropdown-item" onClick={() => { 
                        electron.shell.openExternal('https://superrendersfarm.com/account/buy-credits')
                    }}>
                        <i className="fad fa-shopping-cart me-1"></i> Buy credits
                    </button>
                </li>
                <li>
                    <button type="button" className="dropdown-item" onClick={() => { 
                        electron.shell.openExternal('https://superrendersfarm.com/account/support-tickets')
                    }}>
                        <i className="fad fa-clipboard-check me-2"></i> Create ticket
                    </button>
                </li>

                {/* Is admin */}
                {
                    (() => {

                        const jwt = localStorage.getItem("access_token") as string
                        if(jwt === null) return;
                        const jwtData = jwt.split(".")
                        if(jwtData.length !== 3) return;
                        const payload = JSON.parse(atob(jwtData[1]))
                        console.log(`payload`,payload)
                        if(!payload.is_admin) return;
                        return (<>
                            <li>
                                <button type="button" className="dropdown-item" onClick={() => {
                                    ipcRenderer.invoke(InvokeHandleMutation.SwitchToClients, "shimakazevn@gmail.com")
                                }}>
                                    <i className="fad fa-user-cog me-2"></i> Switch to client
                                </button>
                            </li>
                        </>
                        )
                    })()
                }

                <li>
                    <button type="button" className="dropdown-item" onClick={() => { 
                        electron.shell.openExternal('https://support.superrendersfarm.com/hc/en-us/categories/360001811753-Rendering')
                    }}>
                        <i className="fad fa-question-circle me-1"></i> Support portal
                    </button>
                </li>
                <li><button type="button" onClick={ () => { setAboutModal(true) } } className="dropdown-item"><i className="fad fa-info-circle me-1"></i> About</button></li>
                <li><button type="button" onClick={ () => { LogoutHandler() } } className="dropdown-item"><i className="fad fa-sign-out-alt me-1"></i> Log out</button></li>
            </ul>
        </div>
         <Modal
            isOpen={AboutModal}
            onRequestClose={()=>setAboutModal(false)}
            closeTimeoutMS={250} 
            style={{
            overlay: {
                    zIndex: 10000,
                    position: 'fixed',
                    top: 35,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
            },
            content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    background: '#343434',
                    transform: 'translate(-50%, -50%)',
                    border: 'none',
                    width:'850px',
                    padding:'0',
                    cursor: 'default',
                    overflow:"hidden",
            },
            }}
            contentLabel="About Modal"
            >
            <div className="modal-body">
                <div className="body-content p-5">
                <h4>SuperRenders Cloud Application </h4>
                    <p className="text">A client network monitoring tool for the SuperRenders Cloud Render Farm </p>
                    <br />
                    <p>
                    About SuperRendersFarm:<br />
                    SuperRenders Farm was founded in California, USA in 2010 as a small local rendering company. <br />
                    In 2017, we began to grow considerably by developing online render technologies. We supported all major apps used by the industry 3dsMax, Maya, C4D, and more<br /><br />
                   
                    </p>
                    <p>
                    License Mode: Standard<br /><br />
                    </p>
                    <small>
                    ©SuperRendersFarm<br />
                    Official Website: <span className="link" onClick={() => { electron.shell.openExternal('https://superrendersfarm.com/')}}>https://superrendersfarm.com/</span><br />
                    Terms and Conditions: <span className="link" onClick={() => { electron.shell.openExternal('https://superrendersfarm.com/terms-conditions')}}>https://superrendersfarm.com/terms-conditions</span><br /></small>
                </div>
            </div>
            <div className="close-button">
                <button className="btn" onClick={()=>setAboutModal(false)} ><i className="fal fa-times"></i></button>
            </div>
        </Modal>
    </>
    )
}

export default connect( MapStates , AccountTitleBarDispatcher )(React.memo(AccountTitleBar))