import React, { useState } from 'react'
import ESImport from '../../../esimport';
import { SceneInterface, SrfMaxError, SrfMaxInfo, SrfMaxWarning } from '../../../Interfaces/Renderer';
import './analysis-results.scss'
import SpaceIcon from "../../../assets/SVG/style-dark-1/05.svg"
import SuccessFileIcon from "../../../assets/SVG/style-dark-2/17.svg"


const {ipcRenderer} = window.require("electron")
interface AnalysisResultsProps {
     scene_analyze?: SceneInterface
}

function flatMap(array: any[], fn: (data: any) => void) {
     var result: any[] = [];
     for (var i = 0; i < array.length; i++) {
          var mapping = fn(array[i]);
          result = result.concat(mapping);
     }
     return result
}
const Codeblock =(text:string) => (<code>{text}</code>)

type ListItemType = keyof SrfMaxError | keyof SrfMaxWarning | keyof SrfMaxInfo
export default function AnalysisResults({scene_analyze}: AnalysisResultsProps) {
     const [Scene, setScene] = useState(scene_analyze)

     function RenderItemList( listType : keyof SceneInterface , items : SceneInterface | undefined ) {
          // is items undefined
          if(!items)
               return <div>items undefined</div>
          // Render warning list item
          if(listType == "warning" || listType == "error" || listType == "info")
          {
               if(items[listType].length == 0 )
                    return "No warning"
               // Return list message and attachment with items
               return items[listType].map( (element : any, index:number) => {
                    var MessageResults,Messages:string = ""
                    // Get first object of item
                    switch (listType) {
                         case "error":
                              MessageResults =  items[listType][index][Object.keys(items[listType][index])[0] as keyof SrfMaxError];
                              break;
                         case "warning":
                              MessageResults =  items[listType][index][Object.keys(items[listType][index])[0] as keyof SrfMaxWarning];
                              break;
                         case "info":
                              MessageResults =  items[listType][index][Object.keys(items[listType][index])[0] as keyof SrfMaxInfo];
                              break;
                         default:
                              break;
                    }
                    if(!MessageResults)
                         return <div>Empty</div>

                    Messages = MessageResults.message.replace("___srf_value___", MessageResults.value as string)

                    // ReWrite text
                    const ReplaceText = 'Shift + T'
                    const TemplateResult = flatMap(Messages.split(ReplaceText), (string) => {
                         return [string,Codeblock(ReplaceText) ];
                    });
                    TemplateResult.pop()

                    return (<div>
                         {TemplateResult}
                         {MessageResults.expandable ? (<>
                              <span className="btn btn-sm btn-primary" data-bs-toggle="collapse"
                              data-bs-target="#assets-list" aria-expanded="false"
                              aria-controls="assets-list"> <i className="fal fa-arrow-down" aria-hidden="true"></i> View details</span>

                              <div className="collapse" id="assets-list">
                                   <div className="card card-body">
                                        {MessageResults.items.map( (Item, indexing) => (
                                             <a
                                             title="Click to try search this with SRF Asset Finders"
                                             className="pointer"
                                             onClick={() => { ipcRenderer.send("Dialog.FindAssetDirectory", Item)}} >
                                                  {Item} <br/>
                                             </a>
                                        ))}
                                   </div>
                              </div>
                         </>) : <div></div>}
                         <br />
                    </div>)
               }
               )
          }
    
     }
     if (RenderItemList("error", Scene ) === "No warning" && RenderItemList("warning", Scene ) === "No warning" && RenderItemList("info", Scene ) === "No warning")
     {
          return(
               <div className="analysis-results">
                    <div className="d-flex h-100">
                         <div className="m-auto">
                              <div className="d-flex">
                                   <div className="col-5 me-5 pe-5">
                                        <div className="logo mt-4" style={{backgroundSize:"cover",height:"250px",width:"250px",backgroundRepeat:"no-repeat",backgroundImage:`url("${SpaceIcon}")`}}/>
                                   </div>
                                   <div>
                                        <div className="pb-5">
                                             <div className="title-a">
                                                  <h4 className="fw-bold">Great! Your scene look good!</h4>
                                                  <p>You has got no errors and warnings in your scene</p>
                                             </div>
                                        </div>

                                        <div className="box-info">
                                             <div className="d-flex h-100">
                                                  <div className="m-auto">
                                                       <div className="d-flex">
                                                            <div className="logo" style={{backgroundSize:"cover",height:"80px",width:"95px",backgroundRepeat:"no-repeat",backgroundImage:`url("${SuccessFileIcon}")`}}/>
                                                            <div className="my-auto ms-3 h6">No information...</div>
                                                       </div>
                                                      
                                                  </div>
                                             </div>
                                        </div>
                                   </div>                
                              </div>
                         </div>
                    </div>
               </div>
          )
     }
     return (
          <div className='analysis-results'>
        
               <h5 className="text-danger"><i className="fad fa-exclamation-triangle me-2"></i>Error</h5>
               {RenderItemList("error", Scene )}
               <hr />
               <h5 className="text-warning"><i className="fad fa-exclamation-triangle me-2"></i>Warning</h5>
               {RenderItemList("warning", Scene )}
               <hr />
               <h5 className="text-info"><i className="fad fa-info-circle me-2"></i> Info</h5>
               {RenderItemList("info", Scene )}
          </div>
     )
}