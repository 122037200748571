import React, { useEffect, useState } from 'react'
import './uploading-progress.scss'
import FolderPlusIcon from "../../assets/SVG/style-dark-1/2.svg"
import CancelUploadIcon from "../../assets/SVG/style-dark-1/Upload-Error.svg"
import Modal from 'react-modal';
import { IpcSender, RunningTaskEvents, RunningTaskTypes, SuperTransferEvent, TaskEvents, TaskName, TaskPriority, UPDATE_TASK_EVENT } from '../../Interfaces/IpcRenderer.enum';
import NoActions from '../../screens/TrayWindow/NoActions';
import { Stores } from '../../stores/stores';
import { RenderListActionType } from '../../stores/reducers/render_list.interface';
import { IndexOf } from '../../Interfaces/App.enums';
import { API } from 'apis/base.api';

interface UploadingProgressProps {
}
const { ipcRenderer } = window.require("electron")

export default function UploadingProgress({ }: UploadingProgressProps) {
     const [isUploadCompleted, setIsUploadCompleted] = useState<boolean>(false);
     const [isCreateJob, setIsCreateJob] = useState<boolean>(false);
     const [Tasks, setTasks] = useState<RunningTaskTypes[]>([])
     const [isShowNotification, setIsShowNotification] = useState<boolean>(false);
     const [CancelUploadModal, setCancelUploadModal] = useState<boolean>(false);
     const [isDownloadCompleted, setIsDownloadCompleted] = useState<boolean>(false);

     useEffect(() => {
          let tasksLength = 0
          setInterval(async () => {

               // update srf space to create sync queue
               if (tasksLength !== 0) {
                    const userProfile = localStorage.getItem('user_profiles')
                    if (!userProfile) return;
                    const userProfileObj = JSON.parse(userProfile)
                    const { user } = userProfileObj
                    await API.guard().post('/on-local-server-jobs/sync/new', {
                         uwf_id: user.uwf_id,
                         cloud_type: 'awss3'
                    })
               }
          }, 60000)

          ipcRenderer.on(UPDATE_TASK_EVENT, (event, data: RunningTaskTypes[]) => {
               tasksLength = data.filter(t => t.type == TaskName.UPLOADING_ASSETS || t.type == TaskName.WAITING_TO_UPLOAD).length
               setTasks(data)
          })

          ipcRenderer.on(TaskEvents.HAS_NEW_TASK, (event, data: any) => {
               setTimeout(() => {
                    setIsShowNotification(true)
               }, 1500);
          })
          ipcRenderer.on(RunningTaskEvents.DOWNLOAD_OUTPUT_STARTED, (event, data: any) => {
               setIsDownloadCompleted(false)

          })

          ipcRenderer.on(IpcSender.JobHasbeenCreated, (event, data: any) => {
               setIsShowNotification(false)

          })

          ipcRenderer.on(RunningTaskEvents.DOWNLOAD_OUTPUT_COMPLETED, (event, data: any) => {

               // Show download done when has no
               if (!data.AutoSync)
                    setIsDownloadCompleted(true)

               setIsShowNotification(false)

               // refresh list render jobs
               Stores.dispatch({ type: RenderListActionType.SagaFetch })
          })

          ipcRenderer.on(SuperTransferEvent.UPLOAD_STARTED, (event, data: any) => {

               setIsUploadCompleted(false)

          })

          ipcRenderer.on(SuperTransferEvent.UPLOAD_COMPLETED, (event, context) => {
               setIsShowNotification(false)
               setIsUploadCompleted(true)
               if (context.scene_name) {
                    setIsCreateJob(true)
               }
               else setIsCreateJob(false)

          })
          ipcRenderer.on(SuperTransferEvent.REQUEST_STOP_UPLOAD, (event, data: any) => {
               setIsShowNotification(false)
          })

     }, [])
     const toggleNotification = () => {
          setIsShowNotification(!isShowNotification)

     }

     function secondsToString(milliseconds: number) {

          milliseconds = milliseconds * 1000
          // TIP: to find current time in milliseconds, use:
          // var  current_time_milliseconds = new Date().getTime();

          function numberEnding(_number: number) {
               return (_number > 1) ? 's' : '';
          }

          var temp = Math.floor(milliseconds / 1000);
          var years = Math.floor(temp / 31536000);
          if (years) {
               return years + ' year' + numberEnding(years);
          }

          var days = Math.floor((temp %= 31536000) / 86400);
          if (days) {
               return days + ' day' + numberEnding(days);
          }
          var hours = Math.floor((temp %= 86400) / 3600);
          if (hours) {
               return hours + ' hour' + numberEnding(hours);
          }
          var minutes = Math.floor((temp %= 3600) / 60);
          if (minutes) {
               return minutes + ' minute' + numberEnding(minutes);
          }
          var seconds = temp % 60;
          if (seconds) {
               return seconds + ' second' + numberEnding(seconds);
          }
          return 'almost done..'; //'just now' //or other string you like;
     }

     const RenderTaskList = () => {

          return Tasks.map((task, index) => {

               // skip hidden task
               if (task.priority == TaskPriority.Hidden) return <></>

               const Speed = (task?.context?.bytesPerSecond / 1024 / 1024)
               const TotalSize = (task?.context?.totalSize / 1024 / 1024 / 1024)
               const LoadedSize = (task?.context?.loaded / 1024 / 1024)
               const EstimatedRemainingTime = Math.round((TotalSize * 1024 - LoadedSize) / Speed)
               return (
                    <div className="task-item" key={task.taskId}>
                         <div className="d-flex gap-1 align-items-center align-content-center">
                              <div className="px-2 my-auto">
                                   {
                                        task.type === "UPLOADING_ASSETS" ?
                                             <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-cloud-upload-fill ld mt-1" viewBox="0 0 16 16">
                                                  <path fillRule="evenodd" d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0zm-.5 14.5V11h1v3.5a.5.5 0 0 1-1 0z" />
                                             </svg>
                                             : task.type === "DOWNLOAD_OUTPUT" ?
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-cloud-download-fill" viewBox="0 0 16 16">
                                                       <path fillRule="evenodd" d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.5a.5.5 0 0 1 1 0V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0zm-.354 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V11h-1v3.293l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z" />
                                                  </svg>
                                                  : task.type === "WAITING_TO_UPLOAD" ?
                                                       <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-hourglass-split" viewBox="0 0 16 16">
                                                            <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
                                                       </svg>
                                                       : <></>
                                   }
                              </div>
                              <div className="ms-auto col">
                                   <div className="d-flex">
                                        <span>{task.title}</span>
                                        <div className="ms-auto" hidden={task.type === "WAITING_TO_UPLOAD"}>{Math.round(task.progress)}% <span hidden={!['UPLOADING_ASSETS', 'WAITING_TO_UPLOAD', 'DOWNLOAD_OUTPUT'].includes(task.type) && Speed != 0}>of {`${TotalSize.toFixed(2)} GB`} / {Speed.toFixed(2)} MB/s </span></div>
                                   </div>
                                   <div className="progress" hidden={task.type === "WAITING_TO_UPLOAD"}>
                                        <div className="progress-bar progress-bar-striped progress-bar-animated fast bg-primary" role="progressbar"
                                             aria-valuenow={Math.floor(task.progress)} aria-valuemin={0} aria-valuemax={100} style={{ width: `${Math.floor(task.progress)}%` }} ></div>
                                   </div>
                                   <div className="description">
                                        <small className="text-secondary me-3">
                                             <span className='text-white' hidden={!['WAITING_TO_UPLOAD', 'DOWNLOAD_OUTPUT'].includes(task.type) && Speed != 0}>{secondsToString(EstimatedRemainingTime)} left</span> {task.description}
                                        </small>
                                        <div className="ms-auto">
                                             <small className="cancel-btn text-danger" hidden={!['DOWNLOAD_OUTPUT'].includes(task.type)} onClick={() => setCancelUploadModal(true)}>CANCEL
                                             </small>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <Modal
                              isOpen={CancelUploadModal}
                              onRequestClose={() => setCancelUploadModal(false)}
                              closeTimeoutMS={250}
                              style={{
                                   overlay: {
                                        zIndex: 10000,
                                        position: 'fixed',
                                        top: 35,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                   },
                                   content: {
                                        top: '50%',
                                        left: '50%',
                                        right: 'auto',
                                        bottom: 'auto',
                                        marginRight: '-50%',
                                        background: '#343434',
                                        transform: 'translate(-50%, -50%)',
                                        border: 'none',
                                        width: '700px',
                                        padding: '0',
                                        cursor: 'default',
                                        overflow: "hidden",
                                        borderRadius: '10px',

                                   },
                              }}
                              contentLabel="Cancle Upload"
                         >
                              <div className="d-flex p-5 gap-5">
                                   <div className="col-5">
                                        <div className="logo mx-auto my-4" style={{ backgroundSize: "cover", height: "160px", width: "190px", backgroundRepeat: "no-repeat", backgroundImage: `url("${CancelUploadIcon}")` }} />
                                   </div>
                                   <div className="my-auto">
                                        <div className="title-a pb-3">
                                             <h4 className="fw-bold">Are you sure you want to cancel this upload?</h4>
                                        </div>
                                        <div className="d-flex gap-4 mt-3">
                                             <button className="rounder-btn"
                                                  onClick={() => {
                                                       const id = task.context.id
                                                       console.log(`Sending stop request to task id: `, id)
                                                       ipcRenderer.send(SuperTransferEvent.REQUEST_STOP_UPLOAD, id)
                                                       setCancelUploadModal(false)
                                                  }}>yes
                                             </button>
                                             <button className="rounder-btn" onClick={() => setCancelUploadModal(false)}>no</button>
                                        </div>
                                   </div>
                              </div>
                         </Modal>
                    </div>
               )
          })

     }

     return (
          <>
               <div className="ms-auto my-auto me-3">
                    <div className="notificaiton-btn position-relative" onClick={toggleNotification}>
                         <i className="fad fa-bell me-2" ></i>
                         <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                              {Tasks.length}
                              <span className="visually-hidden">unread messages</span>
                         </span>
                    </div>
               </div>
               <div className={`uploading-progress${isShowNotification ? " show" : " hide"}`} >
                    <div className="d-flex bg-dark py-1">
                         <small className="ms-3 mt-1 py-1 me-auto">
                              BACKGROUND TASKS
                         </small>
                         <div className="ms-auto me-3 mt-1">
                              <div className="btn close-notification badge p-0" onClick={() => setIsShowNotification(false)}>
                                   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-compact-down" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67z" />
                                   </svg>
                              </div>
                         </div>
                    </div>
                    <div className="task-list">
                         {RenderTaskList()}
                    </div>
               </div>
               <Modal
                    isOpen={isUploadCompleted && !isCreateJob}
                    onRequestClose={() => setIsUploadCompleted(false)}
                    closeTimeoutMS={250}
                    style={{
                         overlay: {
                              zIndex: 10000,
                              position: 'fixed',
                              top: 35,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              backgroundColor: 'rgba(0, 0, 0, 0.8)',
                         },
                         content: {
                              top: '50%',
                              left: '50%',
                              right: 'auto',
                              bottom: 'auto',
                              marginRight: '-50%',
                              background: '#343434',
                              transform: 'translate(-50%, -50%)',
                              border: 'none',
                              width: '700px',
                              padding: '0',
                              cursor: 'default',
                              overflow: "hidden",
                              borderRadius: '10px',

                         },
                    }}
                    contentLabel="Upload Success modal"
               >
                    <div className="d-flex p-5 gap-5">
                         <div className="col-5">
                              <div className="logo mx-auto my-4" style={{ backgroundSize: "cover", height: "160px", width: "180px", backgroundRepeat: "no-repeat", backgroundImage: `url("${FolderPlusIcon}")` }} />
                         </div>
                         <div className="my-auto">
                              <div className="title-a pb-3">
                                   <h4 className="fw-bold">Upload success !</h4>
                                   <p>Your files has been uploaded</p>
                              </div>
                              <button className="rounder-btn" onClick={() => setIsUploadCompleted(false)}> ok </button>
                         </div>
                    </div>
               </Modal>
               <Modal
                    isOpen={isDownloadCompleted}
                    onRequestClose={() => setIsDownloadCompleted(false)}
                    closeTimeoutMS={250}
                    style={{
                         overlay: {
                              zIndex: 10000,
                              position: 'fixed',
                              top: 35,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              backgroundColor: 'rgba(0, 0, 0, 0.8)',
                         },
                         content: {
                              top: '50%',
                              left: '50%',
                              right: 'auto',
                              bottom: 'auto',
                              marginRight: '-50%',
                              background: '#343434',
                              transform: 'translate(-50%, -50%)',
                              border: 'none',
                              width: '650px',
                              padding: '0',
                              cursor: 'default',
                              overflow: "hidden",
                         },
                    }}
                    contentLabel="Download success Modal"
               >
                    <div className="d-flex p-5 gap-5">
                         <div className="col-5">
                              <i className="fad fa-check-circle fa-10x"></i>
                         </div>
                         <div className="my-auto">
                              <div className="title-a pb-3">
                                   <h4 className="fw-bold">Download success !</h4>
                                   <p>Your Output has been Downloaded</p>
                              </div>
                              <button className="rounder-btn" onClick={() => setIsDownloadCompleted(false)}> ok </button>
                         </div>
                    </div>
                    <div className="close-button">
                         <button className="btn" onClick={() => setIsDownloadCompleted(false)} ><i className="fal fa-times"></i></button>
                    </div>
               </Modal>
          </>
     )
}