import React , {useState} from 'react'
import { connect } from 'react-redux'
import { RenderNowMapDispatchs } from '../../../../../screens/RenderNow/RenderNow.dispatch'
import { RenderOptionsInterface } from '../../../../../screens/RenderNow/RenderNow.interface'
import { RootState } from '../../../../../stores/stores'
import './scene-path.scss'

// Map redux store states to component props
// If redux store has been changed, this component will be re-rendered
const MapStates = (States:RootState) => ({
     RenderScene : States.Render
})
type MapStateInterface = ReturnType<typeof MapStates>
type PathProps =  MapStateInterface
function ScenePath( Renders : PathProps) {

     const [RenderScene, setRenderScene] = useState(Renders.RenderScene)
     return (
          <div className="d-flex scene-path">
               <div className="col-1 mt-auto">
                    <label className="form-label">Scene Path</label>
               </div>
               <div className="input-group">
                    <input type="text" className="form-control" value={ RenderScene.body.maxPath } disabled />
               </div>
          </div>
     )
}

export default connect( MapStates)(React.memo(ScenePath))