import { Reducer } from "react";
import { CHANGE_WIDTH, RenderReducerActionEnum, RenderReducerActionInterface } from "./render.interface";
import { BmpType, ExrCompression, ExrMainFormat, ExrType, MaxLighting, PngType, SceneInterface, TifCompression, TifType, _3dSoftwareInterface } from '../../Interfaces/Renderer';
import {  RenderConfigurationInterface, RenderOptionsInterface, SceneResponseInterface } from "../../screens/RenderNow/RenderNow.interface";
import { PHPBOOL, RenderEngineVersion } from "../../Interfaces/App.enums";

/**
 * Start render with default datas
 * this is a sample scene json
 */
const RenderReducerIntialStates : RenderOptionsInterface =  {
    body: {
        scene: {
            MaxPath: "",
            error: [],
            warning: [],
            params: {
                scene_param: {
                    startFrame: -1,
                    endFrame: -1,
                    height: 0,
                    width: 0,
                    maxPath: "",
                    incrementStep: 0,
                    sceneNameClean: "",
                    render_engine: RenderEngineVersion.Vray_5_Cpu,
                    output_full_path: "",
                    outputNameFile: "",
                    outputNameType: ".png"
                },
                camera_param: {
                    activeCamera: "",
                    cameras_list: [],
                    num_cameras: 0
                },
                // Idk with this idea :))
                output_param: {
                    output_file_name: "",
                    output_extension: "",
                    multipassoutput_folder: "",
                    outputNameFile: "",
                    // Png
                    png_type: "", // Colors
                    png_alpha: true, // ratio
                    png_interlaced: true, // ratio
                    // Bmp
                    bmp_type: BmpType.Paletted,
                    // Jpg / Jpeg
                    jpg_quality: 0,
                    jpg_smoothing: 0,
                    // Cjpg
                    cjpg_quality: "",
                    cjpg_smoothing: "",
                    // Cjp
                    cjp_quality: "",
                    cjp_smoothing: "",
                    // Tga
                    tga_alphasplit: true,
                    tga_colordepth: 0,
                    tga_compressed : true,
                    tga_premultalpha: true,
                    // Exr
                    exr_compression: ExrCompression.None,
                    exr_main_layer_format: ExrMainFormat.Integer,
                    exr_main_layer_type :ExrType.ARGB,
                    exr_storage : true,
                    exr_image_region: true,
                    exr_render_elements: true,
                    // Tif
                    tif_dpi: 72,// Dots per inch
                    tif_type: TifType._8BitGrayScale,
                    tif_alpha:true,
                    tif_compression: TifCompression.None,

                    // Gamma
                    input_gamma: 2.2,
                    output_gamma: 1.0,
                    gamma_correction: false,
                },
                lighting_param: {
                    lighting_list: [],
                    num_lightings: 0
                },
                corona_option: {},
                arnold_option: {},
                vray_option: {
                    output_splitgbuffer: true,
                    output_saveRawFile: true,
                },
                element_param: {}
            },
            info: [],
        },
        files: [],
        maxPath: "",
        version: 2020,
        FarmDirectory : "",
        autoStartRender : false
    },
    _3dEngine: "3dsmax",
    render_configuration: {
        frame_per_task: 1,
        rf_compute_advanced_lighting: PHPBOOL.False,
        rf_convert_area_lights_to_points_sources: PHPBOOL.False,
        rf_force_two_sided: PHPBOOL.False,
        rf_perform_color_check: PHPBOOL.False,
        rf_perform_displacement_mapping: PHPBOOL.False,
        rf_perform_render_effects: PHPBOOL.False,
        rf_render_flags: PHPBOOL.False,
        rf_render_hidden_objects: PHPBOOL.False,
        rf_toggles_output_dithering_paletted: PHPBOOL.False,
        rf_toggles_output_dithering_true_color: PHPBOOL.False,
        rf_use_advanced_lighting: PHPBOOL.False,
        rf_use_atmospherics_effects: PHPBOOL.False,
        rf_use_super_black: PHPBOOL.False,
    },
    FarmDirectory: ""
}

export function RenderReducer( state = RenderReducerIntialStates , action  : RenderReducerActionInterface ) : RenderOptionsInterface  {
    var RefreshState :RenderOptionsInterface = state
    // Use handlers with params
    switch (action.type) {

        case RenderReducerActionEnum.SetFarmDirectory :
            RefreshState.FarmDirectory = action.value
            return RefreshState

        case RenderReducerActionEnum.ChangeWidth : //"CHANGE_WIDTH":
            RefreshState.body.scene.params.scene_param.width = action.value
            return {...state,...RefreshState}

        case RenderReducerActionEnum.ChangeHeight : //"CHANGE_HEIGHT":
            RefreshState.body.scene.params.scene_param.height = action.value
            return {...state,...RefreshState}

        case RenderReducerActionEnum.UpdateScene : //"UPDATE_SCENE" :
            // Global override initial state
            const ReturnValue :RenderOptionsInterface = {...state,...action.value}
            // // Override output params
            ReturnValue.body.scene.params.output_param = {
                ...state.body.scene.params.output_param,
                ...action.value.body.scene.params.output_param
            }
            // // Override scene params
            ReturnValue.body.scene.params.scene_param = {
                ...state.body.scene.params.scene_param,
                ...action.value.body.scene.params.scene_param
            }
            // Override vray options
            ReturnValue.body.scene.params.vray_option = {
                ...state.body.scene.params.vray_option,
                ...action.value.body.scene.params.vray_option
            }
            //
            console.log(action.value)
            return ReturnValue

        case RenderReducerActionEnum.ChangeStartFrame : //"CHANGE_START_FRAME":
            RefreshState.body.scene.params.scene_param.startFrame = action.value
            return RefreshState

        case RenderReducerActionEnum.ChangeEndFrame: //"CHANGE_END_FRAME":
            RefreshState.body.scene.params.scene_param.endFrame = action.value
            return RefreshState

        case RenderReducerActionEnum.ChangeFrameStep : //"CHANGE_FRAME_STEP":
            RefreshState.body.scene.params.scene_param.incrementStep = action.value
            return RefreshState

        case RenderReducerActionEnum.SetRenderCamera : //"SET_RENDER_CAMERA":
            RefreshState.body.scene.params.camera_param.activeCamera = action.value
            return RefreshState

        case RenderReducerActionEnum.SetRenderTile : //"SET_RENDER_TILE":
            RefreshState.body.scene.params.scene_param.endFrame = action.value
            return RefreshState

        case RenderReducerActionEnum.SetFramePerTask : //"SET_FRAME_PER_TASK":
            RefreshState.render_configuration.frame_per_task = action.value
            return RefreshState

        case RenderReducerActionEnum.SetRenderPriority : //"SET_RENDER_PRIORITY" :
            RefreshState.render_configuration.render_priority = action.value as number
            return RefreshState

        case RenderReducerActionEnum.SwitchLightState : //"SWITCH_LIGHT_STATE" :
            // Find light index
            var LightIndex = <number>action.value
            // Get Current Light State
            var CurrentLight = state.body.scene.params.lighting_param.lighting_list[LightIndex]
            // Update light index state
            RefreshState.body.scene.params.lighting_param.lighting_list[LightIndex] = {
                light_name : CurrentLight.light_name,
                light_state : !CurrentLight.light_state
            }
            console.log(`Changed light ${CurrentLight.light_name} to ${!CurrentLight.light_state}`)
            // Return new light list states
            return {...state,...RefreshState}

        case RenderReducerActionEnum.SwitchRenderFlags : //"switch_render_flags":
            var RefreshState = state
            var Field = <keyof RenderConfigurationInterface> action.value
            var PreState = state.render_configuration[Field] as PHPBOOL
            var ReverseState = (Pre:PHPBOOL) : PHPBOOL => Pre == PHPBOOL.True ? PHPBOOL.False : PHPBOOL.True
            RefreshState.render_configuration = { ...RefreshState.render_configuration, ... {[Field] : ReverseState(PreState)} }
            return {...state,...RefreshState}

        case RenderReducerActionEnum.SetOutputFileType : //"SET_OUTPUT_FILE_TYPE":
            var RefreshState :RenderOptionsInterface = state
            RefreshState.body.scene.params.scene_param.outputNameType = action.value
            return RefreshState

        case RenderReducerActionEnum.UpdateOutputProperties : //"UPDATE_OUTPUT_PROPERTIES":
            var RefreshState :RenderOptionsInterface = state
            RefreshState.body.scene.params.output_param = {
                ...RefreshState.body.scene.params.output_param,
                [action.value.name] : action.value.value
            }
            return {...state,...RefreshState}

        case RenderReducerActionEnum.UpdateNrrOutputProperties : //"UPDATE_NRR_OUTPUT_PROPERTIES":
            var RefreshState :RenderOptionsInterface = state
            RefreshState.body.scene.params.output_param = {
                ...RefreshState.body.scene.params.output_param,
                [action.value.name] : action.value.value
            }
            return RefreshState

        case RenderReducerActionEnum.SetVrayOptions : //"ANALYZE::SET_VRAY_OPTIONS":
            var RefreshState :RenderOptionsInterface = state
            RefreshState.body.scene.params.vray_option = {
                ...RefreshState.body.scene.params.vray_option,
                [action.value.name] : action.value.value
            }
            return {...state,...RefreshState}

        case RenderReducerActionEnum.IsEnableBuildInFrameBuffer :
            var RefreshState :RenderOptionsInterface = state
            RefreshState.body.scene.params.vray_option.output_on = action.value
            return {...state,...RefreshState}

        case RenderReducerActionEnum.SetIsRenderRawImageFile : //"ANALYZE::SET_IS_RENDER_RAW_IMAGE_FILE" :
            var RefreshState :RenderOptionsInterface = state
            RefreshState.body.scene.params.vray_option.output_saveRawFile = action.value
            return {...state,...RefreshState}

        case RenderReducerActionEnum.SetVrayRawFileName :
            var RefreshState :RenderOptionsInterface = state
            RefreshState.body.scene.params.vray_option.output_rawFileName = action.value
            return {...state,...RefreshState}

        case RenderReducerActionEnum.SeparateRenderChannel :
            var RefreshState :RenderOptionsInterface = state
            RefreshState.body.scene.params.vray_option.output_splitgbuffer = action.value
            return {...state,...RefreshState}

        case RenderReducerActionEnum.SetSplitChannelFileName :
            var RefreshState :RenderOptionsInterface = state
            RefreshState.body.scene.params.vray_option.output_splitfilename = action.value
            return {...state,...RefreshState}

        default:
          return state
        }
}