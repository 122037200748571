import axios from "axios";
import { basename, join } from "path";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { DeleteJob, PauseJob, ResumeJob } from "../../../../apis/Job";
import NoAction from "../../../../assets/SVG/style-dark-1/36.svg";
import {
  IpcSender,
  IpcResponse,
  SuperTransferEvent,
  IProgress,
} from "../../../../Interfaces/IpcRenderer.enum";
import { IWebUserConfig } from "../../../../Interfaces/User";
import {
  IRender,
  RenderJobState,
  RenderListActionType,
  IRenderTask,
} from "../../../../stores/reducers/render_list.interface";

import { RootState, Stores } from "../../../../stores/stores";
import RelativeTime from "../CloudStorage/RelativeTime";
import QuickTipsForNewb from "./QuickTipsForNewb";
import "./render-job.scss";
import RenderCollapse from "./RenderCollapse";
import RenderInfo from "./RenderInfo";
import RenderStatus from "./RenderStatus";
const { ipcRenderer } = window.require("electron");
const electron = window.require("electron");

const MapStates = (state: RootState) => ({
  RenderList: state.RenderList,
});

const MapDispatch = {
  SagaFetch: () => ({ type: RenderListActionType.SagaFetch }),
  SetLoading: (loading: boolean) => ({
    type: RenderListActionType.SetLoading,
    payload: loading,
  }),
  Load: () => ({ type: RenderListActionType.Load }),
};

type RenderJobProps = ReturnType<typeof MapStates> & typeof MapDispatch & {};

function RenderList(Props: RenderJobProps) {
  const [OutputFolderPath, setOutputFolderPath] = useState("");
  const RenderList = Props.RenderList.items;
  const [toggle, setToggle] = useState(true);
  const [icon, setIcon] = useState("fa-chevron-right");
  const [quickTip, setQuickTip] = useState<boolean>(
    localStorage.getItem("quick-tip2") === "true"
  );
  const [uploadFiles, setUploadFiles] = useState<IProgress[]>([]);


  useEffect(() => {
    localStorage.setItem("quick-tip2", quickTip.toString());
  }, [quickTip]);
  const ToggleQuickTips = () => {
    setQuickTip(!quickTip);
  };
  ipcRenderer.on(IpcSender.JobHasbeenCreated, (event, context, body) => {
    Props.SagaFetch();
  })

  useEffect(() => {
    ipcRenderer.on(
      SuperTransferEvent.TOTAL_PROGRESS_WITH_FULL_INFO,
      (event: any, data: IProgress[]) => {
        setUploadFiles(data);
      }
    );
  }, []);


  useEffect(() => {
    Props.SetLoading(true);
    // Fetch list
    Props.SagaFetch();
  }, []);

  useEffect(() => {
    ipcRenderer.invoke(IpcSender.GetOutputFolderPath).then((data) => {
      setOutputFolderPath(data);
    });
    // Request open change temporary folder dialog
    ipcRenderer.on(IpcResponse.OpenDirectoryDialog, (event: any, args: any) => {
      const NewDirectory = args.filePaths[0] || "null";
      setOutputFolderPath(NewDirectory);
    });
  }, []);

  function toggleButton() {
    !toggle ? setToggle(true) : setToggle(false);
    !toggle ? setIcon("fa-chevron-right") : setIcon("fa-chevron-down");
  }

  if (Props.RenderList.isLoading) {
    return (
      <div style={{ height: "calc(100vh - 130px)" }}>
        <div className="text-center d-flex h-100">
          <div className="m-auto">
            <h5 className="mb-3">Loading your render jobs...</h5>
            <div
              className="spinner-border spinner-border-lg fast"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (RenderList.length === 0) {
    return (
      <div style={{ height: "calc(100vh - 230px)" }}>
        <div className="text-center d-flex h-100">
          <div className="m-auto">
            <div
              className="logo mx-auto mb-3"
              style={{
                backgroundSize: "cover",
                height: "200px",
                width: "200px",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url("${NoAction}")`,
              }}
            />
            <h5 className="mb-5 pb-5">
              {" "}
              You have no active render jobs at the moment.
            </h5>
          </div>
          <div className="fixed-bottom mb-4 pb-2 mx-3">
            <QuickTipsForNewb />
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      {/*
      // Old versions
      <div className="quick-tips-renderJobs alert alert-danger" role="alert">
        <ul className="mb-0">
          <li>
            The current application version will no longer be supported after October 1st, 2023.
            Please visit <b
              style={{ cursor: 'pointer' }}
              onClick={() => {
                electron.shell.openExternal(
                  "https://superrendersfarm.com"
                );
              }}>superrendersfarm.com</b> to upgrade to the latest version.
          </li>
        </ul>
      </div> */}
      <div className="quick-tips-renderJobs alert alert-success" role="alert">
        <div className={`content ${!quickTip ? "show" : "hide"}`}>
          <div className="input-group input-group-sm pe-3 mb-2">
            <span
              className="input-group-text text-light"
              id="download-location"
            >
              Default download location:
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Default download location"
              aria-label="default download location"
              aria-describedby="download-location"
              value={OutputFolderPath}
              disabled
            />
            <button
              className="btn open-folder-btn"
              type="button"
              id="download-location"
              onClick={() => {
                ipcRenderer.send(IpcSender.OpenOutputFolder, "", null)
              }}
            >
              Open Folder
            </button>
            <button
              className="btn change-folder-btn"
              type="button"
              id="download-location"
              onClick={() => {
                ipcRenderer.send(IpcSender.setOutputFolder);
              }}
            >
              Change Folder
            </button>
          </div>
          <ul className="mb-0">
            <li>
              By default, this application automatically downloads the render
              job output submitted by this computer to a Default download
              location.
            </li>
            <li>
              Render Job tab in this application just provide some basic feature
              (quick update render progress and download render output). Full
              operations of the render job features, feel free to visit{" "}
              <strong
                onClick={() => {
                  electron.shell.openExternal(
                    "https://superrendersfarm.com/account/render-dashboard"
                  );
                }}
              >
                our website version
              </strong>
              .
            </li>
          </ul>
        </div>
        <span className="toggle-button" onClick={ToggleQuickTips}>
          {!quickTip ? (
            <i className="fad fa-times"></i>
          ) : (
            <i className="fad fa-arrow-down"></i>
          )}
        </span>
      </div>
      <div
        className={`render-jobs ${!quickTip ? "quick-tips-show" : "quick-tips-hide"
          }`}
      >
        {/* Render Job */}
        <div className="render-body">
          {RenderList.map((item, index) => {
            return (
              <div className="render-item" key={item.id}>
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn toggle-button"
                    onClick={() => toggleButton()}
                    hidden
                  >
                    <i className={`mt-1 fad ${icon}`}></i>
                  </button>
                  <div className="col">
                    <RenderInfo
                      id={item.id}
                      name={basename(item.scene_name)}
                      frame={`${item.FrameRangeStart} - ${item.FrameRangeEnd}`}
                      camera={item.camera_v2}
                      priority={item.job_priority}
                      renderBy={item.gcpu_type}
                      submitted={RelativeTime(item.created_at)}
                    />
                  </div>
                  <div className="col-3 me-3">
                    <RenderStatus
                      tasks={item.tasks || ({} as IRenderTask)}
                      cost={item.render_credits_v2}
                      status={item.job_status}
                      percentage={item.render_progress}
                      numNodeRun={item.num_nodes_run}
                      estimatedRemainingTime={item.estimated_remaining_time}
                      renderoutput_folder_name={item.renderoutput_folder_name}
                      jobId={item.id}
                    />
                  </div>
                  <div className="ms-auto my-auto">
                    <button
                      className="btn btn-sm btn-dark me-2"
                      onClick={async () => {
                        if (!item.renderoutput_folder_name) return;
                        const Profile = JSON.parse(
                          localStorage.getItem("user_profiles") as string
                        ) as IWebUserConfig;
                        const SrfSpaces = Profile.user.srfspaces;
                        const OutputPath = join(
                          SrfSpaces,
                          "SuperRendersOutput",
                          item.renderoutput_folder_name,
                          "/"
                        );
                        ipcRenderer.send(IpcSender.SyncOutput, { Key: OutputPath, JobId: item.id, AutoSync: false });
                        Props.SagaFetch();
                      }}
                    >
                      Download Output
                    </button>
                    <button
                      className="btn btn-sm btn-dark"
                      disabled={!item.is_output_folder_available}
                      onClick={() => {
                        ipcRenderer.send("open", join(OutputFolderPath, "SuperRendersOutput", item.renderoutput_folder_name, "/"))
                      }}
                    >
                      Open Output
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default connect(MapStates, MapDispatch)(React.memo(RenderList));
