import React from 'react'
import { PHPBOOL } from '../../../../../Interfaces/App.enums'
import './auto-gi-mode.scss'

type AutoGIModeProps = {
     setGiMode: (state:PHPBOOL) => void,
     giMode: boolean
}

export default function AutoGIMode({setGiMode,giMode}: AutoGIModeProps) {
     return (
          <div className="form-check form-switch mb-3">
               <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="auto-gi-mode"
                    data-bs-toggle="collapse"
                    data-bs-target="#global-illumination"
                    aria-expanded="false"
                    aria-controls="global-illumination"
                    checked={giMode}
                    onChange={e=> setGiMode(e.target.checked ? PHPBOOL.On : PHPBOOL.Off)}
                    />
               <label className="form-check-label" htmlFor="auto-gi-mode">Auto GI Mode animation Prepass/Rendering</label>
          </div>
     )
}