import React, { useState } from 'react'
import './dropable-zone.scss'
import { IpcResponse, IpcSender } from '../../Interfaces/IpcRenderer.enum'
import Dropable from '../Dropable'
import UploadMediasIcon from "../../assets/SVG/style-dark-1/Upload-Media.svg"
const electron = window.require("electron")

const { ipcRenderer } = window.require("electron")
interface DropableZoneProps {
}

export default function DropableZone({ }: DropableZoneProps) {

     const [isUploading, setIsUploading] = useState(false)
     const [autoSimulateLocalPath, setAutoSimulateLocalPath] = useState(true)

     const ToggleAutoSimulateLocalPath = () => {
          autoSimulateLocalPath ? setAutoSimulateLocalPath(false) : setAutoSimulateLocalPath(true)
     }

     function UploadFiles() {
          ipcRenderer.send(IpcSender.OpenFileDialog, decodeURIComponent(window.location.pathname), autoSimulateLocalPath)
     }
     function UploadFolder() {
          ipcRenderer.send(IpcSender.OpenFolderDialog, decodeURIComponent(window.location.pathname), autoSimulateLocalPath)
     }

     if (isUploading) {
          return <div>Uploading...</div>
     }
     return (
          <Dropable>
               <div className="dropable-zone">
                    <div className="col-12 mt-auto me-5">
                         <div>
                              <div className="modal-header pb-0">
                                   <h5 className="fw-bold mb-3">Upload your assets</h5> <br />
                                   <p style={{
                                        fontSize: '.89rem'
                                   }}>
                                        * Add the files and folders you want to upload to SuperRenders Farm <br />
                                        * Auto keep local path is used to simulate an absolute path just like one on your local computer <a className='text-primary' style={{ cursor: 'pointer' }} onClick={() => {
                                             electron.shell.openExternal('https://support.superrendersfarm.com/hc/en-us/articles/360062299047-how-to-add-an-absolute-path-with-simulate-local-path-feature')
                                        }}>(learn more)</a>
                                   </p>
                              </div>
                         </div>
                         <div className="form-check mb-3">
                              <input type="checkbox" className="form-check-input" name="" id="s" checked={autoSimulateLocalPath} onChange={() => ToggleAutoSimulateLocalPath()} />
                              <label className="form-check-label" htmlFor="s">
                                   Auto keep local path
                              </label>
                         </div>
                         <div className="d-flex gap-4">
                              <button type="button" className="add-files-button me-auto" onClick={() => UploadFiles()}>
                                   ADD FILES
                              </button>
                              <button type="button" className="add-files-button ms-auto" onClick={() => UploadFolder()}>
                                   ADD FOLDERS
                              </button>
                         </div>
                    </div>

               </div>
          </Dropable>
     )
}