import React from 'react'
import './vray.scss'
import AutoGIMode from '../../components/AutoGIMode'
import Tips from '../../components/Tips'
import GlobalIllumination from './GlobalIllumination'
import ImageSamplesAndFilter from './ImageSamplesAndFilter'
import VrayFrameBuffer from './VrayFrameBuffer'
import { RootState } from '../../../../../stores/stores'
import { connect } from 'react-redux'
import { IndexOf, PHPBOOL, RenderEngineVersion } from '../../../../../Interfaces/App.enums'
import { IVrayOptions } from '../../../../../Interfaces/Renderer'
import { RenderReducerActionEnum } from '../../../../../stores/reducers/render.interface'

const mapStates = (states:RootState) => ({
     Render:states.Render
})
const vrayDispatchMapper = {
     updateVrayOption : <Type extends any >(name : keyof IVrayOptions , value: Type ) => ({ type : RenderReducerActionEnum.SetVrayOptions, value : {name,value} })
}

type VrayProps =  ReturnType<typeof mapStates> & typeof vrayDispatchMapper & {
}

// Vray options configuration
function Vray( Props : VrayProps) {
     const Scene = Props.Render?.body?.scene

     // is this scene using vray engine
     // if this scene analyzed and it is not using vray engine, then return null
     if(Scene && Scene?.params?.scene_param?.render_engine &&
          [
               RenderEngineVersion.Vray_5,
               RenderEngineVersion.Vray3phoenix3,
               RenderEngineVersion.Vray4phoenix4,
               RenderEngineVersion.Vray_3,
               RenderEngineVersion.Vray_5_Cpu,
               RenderEngineVersion.Vray_5_Gpu,
               RenderEngineVersion.Vray_Next_Cpu,
               RenderEngineVersion.Vray_Next_Gpu,
               RenderEngineVersion.Vray_4_2_Cpu
          ]
          .indexOf(Scene.params.scene_param.render_engine) === IndexOf.NotFound)
     {
          return <div></div>
     }

     // this scene using vray engine
     return (
          <div className="accordion-item">

               <h2 className="accordion-header" id="heading4">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                         Vray option
                    </button>
               </h2>
               <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#param-settings">
                    <div className="accordion-body">
                         {/* GI MODE */}
                         <AutoGIMode
                              // check is GlobalIllumination checked
                              giMode={Props?.Render?.body?.scene?.params?.vray_option?.gi_on || false }
                              // Callback handle
                              setGiMode={(giMode:PHPBOOL)=> {
                                   console.log(giMode);
                                   Props.updateVrayOption("gi_on",giMode)
                              }}
                          />
                         {/* Tips */}
                         <Tips />
                         {/* Global Illumination */}
                         <GlobalIllumination />
                         {/* Image Samples and Image Filter */}
                         <ImageSamplesAndFilter />
                         {/* Vray frame buffer */}
                         <VrayFrameBuffer />
                    </div>
               </div>
          </div>
     )
}


export default connect(mapStates, vrayDispatchMapper)(React.memo(Vray))