import React, { useEffect, useState } from "react";
import { IndexOf } from "types/indexOf.type";
import {
     RunningTaskTypes,
     UPDATE_TASK_EVENT,
} from "../../../../../Interfaces/IpcRenderer.enum";
import {
     RenderJobState,
     IRender,
     IRenderTask,
} from "../../../../../stores/reducers/render_list.interface";
import "./render-status.scss";
const { ipcRenderer } = window.require("electron");
const electron = window.require("electron");
interface RenderStatusProps {
     percentage: number;
     status: RenderJobState;
     cost: string;
     tasks: IRenderTask;
     renderoutput_folder_name?: string;
     numNodeRun?: number;
     estimatedRemainingTime?: string;
     jobId: number
}

export default function RenderStatus(Props: RenderStatusProps) {
     const [toggleHover, setToggleHover] = useState(false);
     const [Tasks, setTasks] = useState<RunningTaskTypes[]>([]);
     useEffect(() => {
          ipcRenderer.on(UPDATE_TASK_EVENT, (event, data: RunningTaskTypes[]) => {
               setTasks(data);
          });
     }, []);
     function getUsedCost(value: string) {
          return parseFloat(value).toFixed(2);
     }
     function getPercentage(value: number) {
          if (!value) {
               return "0%";
          }
          else return value.toFixed(0) + "%";
     }
     const task = Tasks.find(
          (t) =>
               t.context?.Key && t.context.JobId === Props.jobId
     );
     if (task) {
          return (
               <div className="render-status">
                    <span className="d-flex info">
                         <div className="text-primary">Downloading output...</div>
                         <span className="ms-auto">
                              Cost:{" "}{isNaN(parseFloat(Props.cost)) ? "$0" : "$" + getUsedCost(Props.cost)}
                         </span>
                    </span>

                    <div className="progress">
                         <div
                              className="progress-bar bg-primary progress-bar progress-bar-striped progress-bar-animated fast"
                              role="progressbar"
                              style={{ width: `${task?.progress}%`, minWidth: "0%" }}
                         ></div>
                    </div>
                    <small className="d-flex text-muted">
                         <span>{task?.description}</span>
                         <span className="ms-auto">{getPercentage(task.progress)}</span>
                    </small>
               </div>
          )
     }
     return (
          <div className="render-status" onMouseEnter={() => setToggleHover(true)} onMouseLeave={() => setToggleHover(false)} >
               <span className="d-flex info">
                    {Props.status === RenderJobState.UserAcceptTest ? (
                         <div className="text-muted"> User Accept Test </div>
                    ) : Props.status === RenderJobState.SystemAcceptPreview ? (
                         <div className="text-light"> In Queue... </div>
                    ) : Props.status === RenderJobState.ReadyForRender ? (
                         <div className="text-light"> Ready For Render </div>
                    ) : Props.status === RenderJobState.GettingStarted ? (
                         <div className="text-light"> Getting Started </div>
                    ) : Props.status === RenderJobState.WaitingForRestart ? (
                         <div className="text-warning"> Waiting For Restart </div>
                    ) : Props.status === RenderJobState.WaitingForStart ? (
                         <div className="text-warning"> Waiting For Start </div>
                    ) : Props.status === RenderJobState.UserPreviewStarted ? (
                         <div className="text-warning"> User Preview Started </div>
                    ) : Props.status === RenderJobState.UserTestStarted ? (
                         <div className="text-muted"> User Test Started </div>
                    ) : [RenderJobState.UserAcceptStarted, RenderJobState.InAnalyzed].indexOf(Props.status) !== IndexOf.NotFound ? (
                         <div className="text-primary"> In Analyzing </div>
                    ) : Props.status === RenderJobState.JobStarted || Props.status === RenderJobState.InRendering ? (
                         <div className="text-primary"> In Rendering... </div>
                    ) : Props.status === RenderJobState.WaitingForPause ? (
                         <div className="text-warning"> Waiting For Pause </div>
                    ) : Props.status === RenderJobState.JobPaused ? (
                         <div className="text-warning"> Paused </div>
                    ) : Props.status === RenderJobState.WaitingForDelete ? (
                         <div className="text-danger"> Deleting </div>
                    ) : Props.status === RenderJobState.JobCompleted || Props.status === RenderJobState.Completed ? (
                         <div className="text-success"> Job Completed </div>
                    ) : Props.status === RenderJobState.JobFailed ? (
                         <div className="text-danger"> Error </div>
                    ) : Props.status === RenderJobState.JobRestarting ? (
                         <div className="text-primary"> Job Restarting </div>
                    ) : (
                         <div className="text-danger">Error</div>
                    )
                    }
                    <span className="ms-auto">
                         Cost:{" "}
                         {isNaN(parseFloat(Props.cost)) ? "$0" : "$" + getUsedCost(Props.cost)}
                    </span>
               </span>

               <div className="progress">
                    {(() => {
                         switch (Props.status) {

                              // Muted
                              case RenderJobState.UserAcceptTest:
                              case RenderJobState.UserTestStarted:
                                   return (
                                        <div
                                             className="progress-bar bg-muted progress-bar progress-bar-striped progress-bar-animated fast"
                                             role="progressbar"
                                             style={{ width: `${Props.percentage}%`, minWidth: "0%" }}
                                        ></div>)

                              // Primary
                              case RenderJobState.SystemAcceptPreview:
                              case RenderJobState.GettingStarted:
                              case RenderJobState.UserAcceptStarted:
                              case RenderJobState.JobStarted:
                              case RenderJobState.JobRestarting:
                              case RenderJobState.InAnalyzed:
                              case RenderJobState.InRendering:
                                   return (
                                        <div
                                             className="progress-bar bg-primary progress-bar progress-bar-striped progress-bar-animated fast"
                                             role="progressbar"
                                             style={{ width: "100%", minWidth: "0%" }}
                                        ></div>)

                              // Success
                              case RenderJobState.ReadyForRender:
                                   return (
                                        <div
                                             className="progress-bar bg-success progress-bar progress-bar-striped progress-bar-animated fast"
                                             role="progressbar"
                                             style={{ width: "100%", minWidth: "0%" }}
                                        ></div>)
                              case RenderJobState.JobCompleted:
                              case RenderJobState.Completed:
                                   return (
                                        <div
                                             className="progress-bar bg-success progress-bar"
                                             role="progressbar"
                                             style={{ width: "100%", minWidth: "0%" }}
                                        ></div>)

                              // Warning
                              case RenderJobState.WaitingForRestart:
                              case RenderJobState.WaitingForDelete:
                              case RenderJobState.WaitingForPause:
                                   return (
                                        <div
                                             className="progress-bar bg-warning progress-bar progress-bar-striped progress-bar-animated fast"
                                             role="progressbar"
                                             style={{ width: `${Props.percentage}%`, minWidth: "0%" }}
                                        ></div>)
                              case RenderJobState.JobPaused:
                                   return (
                                        <div
                                             className="progress-bar bg-warning progress-bar progress-bar-striped progress-bar-animated fast"
                                             role="progressbar"
                                             style={{ width: `${Props.percentage}%`, minWidth: "0%" }}
                                        ></div>)

                              // Light
                              case RenderJobState.WaitingForStart:
                                   return (
                                        <div
                                             className="progress-bar bg-light progress-bar progress-bar-striped progress-bar-animated fast"
                                             role="progressbar"
                                             style={{ width: "100%", minWidth: "0%" }}
                                        ></div>)

                              // Black
                              case RenderJobState.UserPreviewStarted:
                                   return (
                                        <div
                                             className="progress-bar bg-black progress-bar progress-bar-striped progress-bar-animated fast"
                                             role="progressbar"
                                             style={{ width: `${Props.percentage}%`, minWidth: "0%" }}
                                        ></div>)

                              // Danger
                              case RenderJobState.JobFailed:
                                   return (
                                        <div
                                             className="progress-bar bg-danger progress-bar "
                                             role="progressbar"
                                             style={{ width: `${Props.percentage}%`, minWidth: "0%" }}
                                        ></div>)
                         }

                         // Not listed in cases
                         return (<div
                              className="progress-bar bg-danger progress-bar "
                              role="progressbar"
                              style={{ width: `100%`, minWidth: "0%" }}
                         ></div>)

                    })()}
               </div>
               <small className="d-flex text-muted">

                    <span hidden={Props.status !== RenderJobState.JobStarted} >
                         {toggleHover ? Props.estimatedRemainingTime === "NA" ? `` : Props.estimatedRemainingTime : `${Props.numNodeRun} nodes rendering`
                         }
                    </span>

                    <span className="ms-auto">{getPercentage(Props.percentage)}</span>
               </small>
          </div>
     );
}
