import {
    useState
} from "react"

export type useStateMiddlewareDispatch < Type, PayloadType > = [
    state: Type,
    setStateMiddleware: (state: Type, Payload: PayloadType) => void
]

export type ReturnError = {
    message ?:string
    next  : boolean
}

export function useStateMiddlware < Type, PayloadType > (
    Default: Type,
    Condition: (Default: Type, Payload?: PayloadType) => ReturnError,
    ErrorHandle: (error:string) => void
): useStateMiddlewareDispatch < Type, PayloadType > {
    // create state
    const [state, setState] = useState < Type > (Default)

    // Check condition before update state
    function setStateMiddleware(newState: Type, data: PayloadType) {
        const Checking = Condition(newState, data) as ReturnError

        if (Checking.next) {
            setState(newState)
        } else
            // Return error
            ErrorHandle(Checking?.message || "No Error")
    }

    // return state
    return [state, setStateMiddleware]
}