import React, { useState } from 'react'
import { connect } from 'react-redux'
import { IrradMapMode, LightCacheMode, VrayGIPrimaryType, VrayGISecondaryType } from '../../../../../../Interfaces/App.enums'
import { VrayDispatchMapper } from '../vrayDispatch'
import './global-illumination.scss'
import {RootState} from '../../../../../../stores/stores'

const MapStates = (States : RootState) => ({
     Render : States.Render
})

type GlobalIlluminationProps = ReturnType<typeof MapStates> & typeof VrayDispatchMapper & {

}

function GlobalIllumination( Props : GlobalIlluminationProps) {
     const [primaryEngine, setPrimaryEngine] = useState<VrayGIPrimaryType>(Props?.Render?.body?.scene?.params?.vray_option?.gi_primary_type?.gi_primary_type_key || VrayGIPrimaryType.IrradianceMap)
     const [secondaryEngine, setSecondaryEngine] = useState<VrayGISecondaryType>(Props?.Render?.body?.scene?.params?.vray_option?.gi_secondary_type?.gi_secondary_type_key || VrayGISecondaryType.None )
     const [irradianceMap, setIrradianceMap] = useState<IrradMapMode>(IrradMapMode.AddToCurrentMap)
     const [lightCache, setLightCache] = useState<LightCacheMode>(Props?.Render?.body?.scene?.params?.vray_option?.lightcache_mode?.lightcache_mode_key || LightCacheMode.SingleFrame)
     return (
     <div className="card mb-3 collapse show" id="global-illumination">
          <div className="card-header">
               <h6 className="card-title">Global Illumination</h6>
          </div>
          <div className="card-body">
               <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="flexCheckChecked" checked/>
                    <label className="form-check-label" htmlFor="flexCheckChecked">
                         Enable GI
                    </label>
               </div>
               <div className="row mb-3">
                    <div className="col-6">
                         <span className="input-group-text" >Primary engine </span>
                         <select className="form-select" aria-label="Primary engine" id="vray_primary_engine" onChange={e=> setPrimaryEngine(e.target.value as VrayGIPrimaryType)}>
                              <option value={VrayGIPrimaryType.IrradianceMap} selected>Irradiance map</option>
                              <option value={VrayGIPrimaryType.PhotonMap}>Photon map</option>
                              <option value={VrayGIPrimaryType.BruteForce}>Brute force</option>
                              <option value={VrayGIPrimaryType.LightCache}>Light cache</option>
                         </select>
                    </div>
                    <div className="col-6">
                         <span className="input-group-text" >Secondary engine </span>
                         <select className="form-select" aria-label="Secondary engine" id="vray_secondary_engine" onChange={e=> setSecondaryEngine(e.target.value as VrayGISecondaryType)}>
                              <option value={VrayGISecondaryType.None}>None</option>
                              <option value={VrayGISecondaryType.PhotonMap}>Photon map</option>
                              <option value={VrayGISecondaryType.BruteForce}>Brute force</option>
                              <option value={VrayGISecondaryType.LightCache}>Light cache</option>
                         </select>
                    </div>
               </div>
               {
                    primaryEngine==="0"?(
                         <div className="card mb-3" id="irradiance-map">
                              <div className="card-header">
                                   <h6 className="card-title">Irradiance Map</h6>
                              </div>
                              <div className="card-body">
                                   <div className="row mb-3">
                                        <div className="col-6">
                                             <span className="input-group-text" >Mode </span>
                                             <select id="vray_mode_irradiance_map" className="form-control" name="adv_irradmap_mode" onChange={e=> setIrradianceMap(parseInt(e.target.value) as IrradMapMode )}>
                                                  <option value={IrradMapMode.SingleFrame} selected>Single frame</option>
                                                  <option value={IrradMapMode.MultiFrameIncremental}>Multiframe incremental</option>
                                                  <option value={IrradMapMode.FromFile}>From file</option>
                                                  <option value={IrradMapMode.AddToCurrentMap}>Add to current map</option>
                                                  <option value={IrradMapMode.IncrementalAddToCurrentMap}>Incremental add to current map</option>
                                                  <option value={IrradMapMode.Bucket}>Bucket mode</option>
                                                  <option value={IrradMapMode.AnimationPrepass}>Animation (prepass)</option>
                                                  <option value={IrradMapMode.AnimationRendering}>Animation (rendering)</option>
                                             </select>
                                        </div>
                                        <div className="col-6">
                                             {
                                                  irradianceMap=== IrradMapMode.FromFile?(
                                                       <>
                                                            <span className="input-group-text" >File path* </span>
                                                            <input type="file" className="form-control"/>
                                                       </>
                                                  ):""
                                             }
                                        </div>
                                   </div>

                              </div>
                         </div>
                    ):""
               }
               {
                    ( primaryEngine ==="3" || secondaryEngine==="3" )? (
                    <div className="card" id="light-cache">
                         <div className="card-header">
                              <h6 className="card-title">Light Cache</h6>
                         </div>
                         <div className="card-body">
                              <div className="row mb-3">
                                   <div className="col-6">
                                        <span className="input-group-text" >Mode </span>
                                        <select id="vray_mode_light_cache" name="lightcache_mode" className="form-control"
                                             value={Props?.Render?.body?.scene?.params?.vray_option?.lightcache_mode?.lightcache_mode_key || LightCacheMode.SingleFrame}
                                             onChange={e=> setLightCache(parseInt(e.target.value) as LightCacheMode)}>
                                             <option value={LightCacheMode.SingleFrame} selected>Single frame</option>
                                             <option value={LightCacheMode.FlyThrough}>Fly through</option>
                                             <option value={LightCacheMode.FromFile}>From file</option>
                                             <option value={LightCacheMode.ProgressivePathTracking}>Progressive path tracking</option>
                                        </select>
                                   </div>
                                   <div className="col-6">
                                        {
                                             lightCache===LightCacheMode.FromFile?(
                                                  <>
                                                       <span className="input-group-text" >File path* </span>
                                                       <input type="file" className="form-control"/>
                                                  </>
                                             ):""
                                        }
                                   </div>
                              </div>
                         </div>
                    </div>
                    ):""
               }
          </div>
     </div>

     )
}

export default connect(MapStates, VrayDispatchMapper)(React.memo(GlobalIllumination))