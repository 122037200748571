import { RenderListActionType } from "../reducers/render_list.interface";
import { put, takeEvery, all } from 'redux-saga/effects'
import { SagaFetchListRenderAsync } from "./render_list.worker";
import { CloudStorageAction } from "../reducers/spaces.interface";
import { FetchAsync } from "./spaces.worker";
import { AnalysisActionType } from "../reducers/analysis.interface";
import { SagaFetchListAnalysisAsync } from "./analysis.worker";

export function* watchFetchListRenderList() {
    yield takeEvery(RenderListActionType.SagaFetch , SagaFetchListRenderAsync)
}

export function* watchFetchCloudObjects() {
    yield takeEvery(CloudStorageAction.SagaFetch , FetchAsync)
}

// Client ask for fetch list analysis
export function* watchFetchAnalysisList() {
    yield takeEvery( AnalysisActionType.SagaFetch , SagaFetchListAnalysisAsync)
}