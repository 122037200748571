import React from 'react'
import { connect } from 'react-redux'
import { OutputParamsPropertyKeys } from '../../../../../../screens/RenderNow/RenderNow.interface'
import { RenderReducerActionEnum } from '../../../../../../stores/reducers/render.interface'
import { RootState } from '../../../../../../stores/stores'
import './vray-frame-buffer.scss'

const MapState = (states : RootState) =>({
     Render : states.Render
})

const MapDispatch = {
     SetIsRenderRawImageFile : (isRenderRawImageFile : boolean) => ({ type : RenderReducerActionEnum.SetIsRenderRawImageFile, value : isRenderRawImageFile }),
     SetVrayRawFileName : (filename : string) => ({ type : RenderReducerActionEnum.SetVrayRawFileName, value : filename }),
     SetSeparateRenderChannel : (splitChannel : boolean) => ({ type : RenderReducerActionEnum.SeparateRenderChannel, value : splitChannel }),
     SetSeparateFileName : (filename : string) => ({ type : RenderReducerActionEnum.SetSplitChannelFileName, value : filename }),
     SetIsEnableBuildInFrameBuffer: (isUseBuildInFrameBuffer : boolean) => ({ type : RenderReducerActionEnum.IsEnableBuildInFrameBuffer, value : isUseBuildInFrameBuffer }),
}

type VrayFrameBufferProps = ReturnType<typeof MapState> & typeof  MapDispatch & {
}

 function VrayFrameBuffer( Props : VrayFrameBufferProps) {
     return (
          <div className="card" >
               <div className="card-header">
                    <h6 className="card-title">VRay Frame Buffer</h6>
               </div>
               <div className="card-body">
                    <div className="form-check form-switch mb-3">
                         <input className="form-check-input" type="checkbox" role="switch" id="buildIn-frame-buffer"
                              onChange={e => {
                                   Props.SetIsEnableBuildInFrameBuffer(e.target.checked)
                              }}
                              // value={Props.Render.isEnableBuildInFrameBuffer}
                              checked={Props?.Render?.body?.scene?.params?.vray_option?.output_on}
                              />
                         <label className="form-check-label" htmlFor="buildIn-frame-buffer">Build-in frame buffer</label>
                    </div>
                    <div className="row mb-3">
                         <div className="col-6">
                              <div className="form-check">
                                   <input className="form-check-input" type="checkbox" onChange={e => {
                                        Props.SetIsRenderRawImageFile(e.target.checked)
                                   }}
                                   checked={Props?.Render?.body?.scene?.params?.vray_option?.output_saveRawFile}
                                   id="vRay-raw-image-file"/>
                                   <label className="form-check-label" htmlFor="vRay-raw-image-file">
                                        V-ray raw image file
                                   </label>
                              </div>
                         </div>
                         <div className="col-6">
                              <div className="form-check">
                                   <input className="form-check-input" type="checkbox" onChange={e => {
                                        Props.SetSeparateRenderChannel(e.target.checked)
                                   }}
                                   checked={Props?.Render?.body?.scene?.params?.vray_option?.output_splitgbuffer}
                                   id="separate-render-channels"/>
                                   <label className="form-check-label" htmlFor="separate-render-channels">
                                        Separate render channels
                                   </label>
                              </div>
                         </div>
                         <div className="col-6">
                              <span className="input-group-text" id="scene-path">V-ray raw file name</span>
                              <input type="text" className="form-control" id="scene-path" aria-describedby="scene-path"
                                   onChange={ e => {
                                             Props.SetVrayRawFileName(e.target.value)
                                   }}
                                   value={Props?.Render?.body?.scene?.params?.vray_option?.output_rawFileName}
                                   placeholder="rawFileName.Ext" />
                         </div>
                         <div className="col-6">
                              <span className="input-group-text" id="scene-path">Split channels file name</span>
                              <input type="text"
                                   onChange={e=> {
                                        Props.SetSeparateFileName(e.target.value)
                                   }}
                                   value={Props?.Render?.body?.scene?.params?.vray_option?.output_splitfilename}
                                   className="form-control" id="scene-path" aria-describedby="scene-path" placeholder="SplitFileName.Ext" />
                         </div>
                    </div>
               </div>
          </div>
     )
}

export default connect(
     MapState,
     MapDispatch
)(React.memo(VrayFrameBuffer))