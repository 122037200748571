import Tippy from '@tippyjs/react'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import NotificationCenter from '../../../../components/NotificationCenter'
import UploadingProgress from '../../../../components/UploadingProgress'
import { RootState, Stores } from '../../../../stores/stores'
import './bottom-bar.scss'
import isOlderThanVersion from 'Helpers/version-compare'
import axios from 'axios'

const { ipcRenderer } = window.require("electron")

const MapStates = (States: RootState) => ({
     Notification: States.Notification,
     spaceItems: States.CloudStorage.items
})

const MapDispatch = {

}

type BottomBarProps = ReturnType<typeof MapStates> & typeof MapDispatch & {

}

function BottomBar(Props: BottomBarProps) {

     var TextColor =
          Props.Notification.loadTime < 5000 ? 'text-success' :
               Props.Notification.loadTime < 10000 ? 'text-warning' : 'text-danger'
     var IconSpeed =
          Props.Notification.loadTime < 2000 ? 'fa-signal' :
               Props.Notification.loadTime < 3000 ? 'fa-signal-4' :
                    Props.Notification.loadTime < 4500 ? 'fa-signal-3' :
                         Props.Notification.loadTime < 7000 ? 'fa-signal-2' :
                              Props.Notification.loadTime < 10000 ? 'fa-signal-1' : 'fa-signal-1'
     const [version, setVersion] = useState<string>('')

     useEffect(() => {
          ipcRenderer.invoke('app-version')
               .then(v => {
                    setVersion(v)
               })
     }, [])

     return (
          <small className="bottom-bar fixed-bottom d-flex">


               <div className="ping my-auto ms-2">
                    <Tippy content={
                         <span className={TextColor}> {Props.Notification.loadTime} ms</span>
                    }>
                         <i className={`fad ${IconSpeed} ${TextColor}`} ></i>
                    </Tippy>
               </div>
               <span className="ms-2 my-auto">{version}</span>

               <div className="notification-text my-auto ms-2">
                    {/* {Props.Notification.message} */}
               </div>
               <UploadingProgress />
               <NotificationCenter />
          </small>
     )
}

export default connect(MapStates, MapDispatch)(BottomBar)