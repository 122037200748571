import { ExrMainFormat, ExrType, PngType } from './../../Interfaces/Renderer';
import axios from "axios";
import { Stores } from "../../stores/stores";
import { HttpSceneBody, RenderDatabaseQuery, RenderConfigurationInterface, JwtPayload} from './RenderNow.interface';
import * as path from "path"
import { CloudType, VrayGlobalIlluminationPrimaryName, VrayGIPrimaryType, VrayGlobalIlluminationSecondaryName, VrayGISecondaryType, IrradMapMode, IrradMapModeName, JobType, LightCacheMode, FarmManagerSoftware, SceneAnalyzeStatus, RenderProcessor, IndexOf, PHPBOOL} from '../../Interfaces/App.enums';
import Logger from "../../Helpers/Log";
import ESImport from "../../esimport";
import { IpcSender } from "../../Interfaces/IpcRenderer.enum";
import * as jwt from 'jsonwebtoken'
import { IWebUserConfig } from '../../Interfaces/User';
import { GetWaiter } from '../../Helpers/Waiter';
const {ipcRenderer} = window.require("electron")
// lightcache_loadFileName

export async function StartRenderAnalyzedSceneNow( event : React.MouseEvent<HTMLButtonElement, MouseEvent> ) {
    const RenderSceneJson = Stores.getState().Render
    const RenderConfigs : RenderConfigurationInterface = RenderSceneJson.render_configuration
    const Scene = RenderSceneJson.body.scene
    // Wait to get user configs from web service
    await GetWaiter<IWebUserConfig['user']>(() => JSON.parse(localStorage.getItem("user_profiles") || "{}"))
    const UserProfileConfigs = JSON.parse(localStorage.getItem("user_profiles") || "{}")
    // check is user configs is null
    if( UserProfileConfigs == {}) {
        // show message box
        ipcRenderer.send(IpcSender.ShowMessageBox, {
            title : "Oops,Something went wrong, please logout and try again!",
        })
        return 0;
    }

    const UserCfg : IWebUserConfig['user'] =  UserProfileConfigs.user as IWebUserConfig['user']
    console.table(UserCfg)
    const AuthenticatedUser = jwt.decode(localStorage.getItem("access_token") || "") as JwtPayload || null
    console.log(AuthenticatedUser.user_name)
    const ScenePath = RenderSceneJson.body.scene.params.scene_param.maxPath;
    const SceneName = path.basename(ScenePath)
    // create new object
    let Final: HttpSceneBody = {
        analyze_result : JSON.stringify({...RenderSceneJson.body.scene, maxPath : undefined, params : undefined}),
        scene_info : JSON.stringify(RenderSceneJson.body.scene.params),
    }

    // Check is this job need to be rendering with prepass
    function AnimationPrepassMode( Job : HttpSceneBody) : PHPBOOL {
        // All job has been set to Animation as default mode
        // Make sure this is a animation job
        if(Job.job_type == JobType.Animation){
            // If this job is using Vray Engine
            // Check for using prepass rendering
            // And check is custom GI mode was on
            if( Job.gi_on == PHPBOOL.On &&
            (
                Job.gi_primary_type == VrayGIPrimaryType.IrradianceMap &&
                [IrradMapMode.FromFile , IrradMapMode.AnimationRendering , undefined].indexOf(Job.adv_irradmap_mode) == IndexOf.NotFound
            ) ||
            (
                Job.lightcache_mode != LightCacheMode.FromFile &&
                Job.gi_primary_type == VrayGIPrimaryType.LightCache ||
                Job.gi_secondary_type == VrayGISecondaryType.LightCache
            )
            ){
                return PHPBOOL.True
            }
            // If this is Corona job, then check for corona options
        }
        // Use prepass for all other jobs type not supported yet
        return PHPBOOL.False
    }
    try {
        console.log(`Scene width : ${RenderSceneJson.body.scene.params.scene_param.width}, Scene height : ${RenderSceneJson.body.scene.params.scene_param.height}`)
        // Mapping active to light string array
        Final.lights = Scene.params.lighting_param.lighting_list.filter(light => light.light_state).map(light=> light.light_name)
        // Parse Vray render settings
        if(Scene.params.vray_option) {
            console.log(`Light cache`,Scene.params.vray_option.lightcache_loadFileName)
            Final.gi_on = Scene.params.vray_option.gi_on ? PHPBOOL.On : PHPBOOL.Off

            if(Scene?.params?.vray_option?.lightcache_loadFileName != undefined)
                Final.lightcache_loadFileName = Scene.params.vray_option.lightcache_loadFileName

            if(Scene?.params?.vray_option?.adv_irradmap_loadFileName != undefined)
                Final.adv_irradmap_loadFileName = Scene.params.vray_option.adv_irradmap_loadFileName

            if(Scene?.params?.vray_option?.gi_primary_type?.gi_primary_type_key != undefined)
                Final.gi_primary_type = Scene.params.vray_option.gi_primary_type?.gi_primary_type_key

            if(Scene?.params?.vray_option?.gi_secondary_type?.gi_secondary_type_key != undefined)
                Final.gi_secondary_type = Scene.params.vray_option.gi_secondary_type.gi_secondary_type_key

            if(Scene?.params?.vray_option?.adv_irradmap_mode?.adv_irradmap_mode_key != undefined)
                Final.adv_irradmap_mode = Scene.params.vray_option.adv_irradmap_mode.adv_irradmap_mode_key

            if(Scene?.params?.vray_option?.lightcache_mode?.lightcache_mode_key != undefined)
                Final.lightcache_mode = Scene.params.vray_option.lightcache_mode.lightcache_mode_key

            if(Scene?.params?.vray_option?.imageSampler_type?.imageSampler_type_key != undefined)
                Final.imageSampler_type = Scene.params.vray_option.imageSampler_type.imageSampler_type_key

            if(Scene?.params?.vray_option?.output_on)
                Final.output_on = Scene.params.vray_option.output_on ? PHPBOOL.On : PHPBOOL.Off

            if(Scene?.params?.vray_option?.filter_on)
                Final.filter_on = Scene.params.vray_option.filter_on ? PHPBOOL.On : PHPBOOL.Off

            //
            Final.output_saveRawFile = Scene.params.vray_option.output_saveRawFile ? PHPBOOL.On : PHPBOOL.Off
            Final.output_splitgbuffer = Scene.params.vray_option.output_splitgbuffer ? PHPBOOL.On : PHPBOOL.Off
        }

        // Override test vray
        // Final.lightcache_loadFileName = "/superrenders_hain_srfspaces/TETOVA_BEAUTY/vraymap/abcss.vrlmap"

        // Map static params
        Final.farm_manager = FarmManagerSoftware.Deadline
        Final.srf_cloud_type = CloudType.AwsS3
        Final.display_cloud_type = CloudType.AwsS3
        // Config Renderers
        // Render engine
        const RenderEngine = RenderSceneJson.body.scene.params.scene_param.render_engine
        console.log(`Render Engine : ${RenderEngine}`)
        Final.renderer_version = RenderEngine // fixed vray_5
        Final.software_version =  RenderSceneJson.Analysis?.software_version || "3dsmax_2022"
        Final.scene_3dApp = <string> RenderSceneJson._3dEngine
        // Scene config
        Final.working_folder_path = path.dirname(RenderSceneJson.body.maxPath)
        Final.scene_path =  RenderSceneJson.body.maxPath
        Final.scene_name = path.basename(RenderSceneJson.body.maxPath)
        Final.user_name = AuthenticatedUser.user_name as string

        // Render configs
        Final.render_priority = RenderConfigs.render_priority ? RenderConfigs.render_priority : 10
        Final.gcpu_type = RenderProcessor.CPU
        // Output
        Final.outputNameFile = Scene.params.output_param.outputNameFile || Scene.params.scene_param.outputNameFile
        var removedSpeciationHtmlName = path.basename(RenderSceneJson.body.maxPath).replaceAll(/[\s.\_\-]/gm, "")
        if(Scene.params.scene_param.outputNameType == "") {
            alert('Please select output type');
            return;
        }

        Final.renderoutput_path = path.join(removedSpeciationHtmlName,  Final.outputNameFile +  Scene.params.scene_param.outputNameType )
        Final.output_extension = Scene.params.scene_param.outputNameType

        // Gamma Correction
        Final.input_gamma = Scene?.params?.output_param?.input_gamma?.toString()
        Final.output_gamma= Scene?.params?.output_param?.output_gamma?.toString()
        Final.gamma_correction = Scene?.params?.output_param?.gamma_correction? PHPBOOL.True: PHPBOOL.False
        // PNG
        Final.png_interlaced =   Scene?.params?.output_param?.png_interlaced? PHPBOOL.True: PHPBOOL.False
        Final.png_alpha = Scene?.params?.output_param?.png_alpha? PHPBOOL.True: PHPBOOL.False
        Final.png_type = Scene?.params?.output_param?.png_type

        // Bmp
        Final.bmp_type = Scene?.params?.output_param?.bmp_type

        // Jpg / Jpeg
        Final.jpg_quality = Scene?.params?.output_param?.jpg_quality
        Final.jpg_smoothing = Scene?.params?.output_param?.jpg_smoothing

        // Cjpg
        Final.cjpg_quality = Scene?.params?.output_param?.cjpg_quality
        Final.cjpg_smoothing = Scene?.params?.output_param?.cjpg_smoothing

        // Cjp
        Final.cjp_quality = Scene?.params?.output_param?.cjp_quality
        Final.cjp_smoothing = Scene?.params?.output_param?.cjp_smoothing

        // Tga
        Final.tga_alphasplit = Scene?.params?.output_param?.tga_alphasplit? PHPBOOL.True: PHPBOOL.False
        Final.tga_colordepth = Scene?.params?.output_param?.tga_colordepth

        // Exr
        Final.exr_compression = Scene?.params?.output_param?.exr_compression
        Final.exr_main_layer_format = Scene?.params?.output_param?.exr_main_layer_format || ExrMainFormat.Integer
        Final.exr_main_layer_type = Scene?.params?.output_param?.exr_main_layer_type || ExrType .ARGB
        Final.exr_storage = Scene?.params?.output_param?.exr_storage? PHPBOOL.True: PHPBOOL.False
        Final.exr_image_region = Scene?.params?.output_param?.exr_image_region? PHPBOOL.True: PHPBOOL.False
        Final.exr_isAutoAddRenderElements = Scene?.params?.output_param?.exr_isAutoAddRenderElements? PHPBOOL.On: PHPBOOL.Off

        // Tif
        Final.tif_dpi = Scene?.params?.output_param?.tif_dpi
        Final.tif_type = Scene?.params?.output_param?.tif_type
        Final.tif_alpha = Scene?.params?.output_param?.tif_alpha? PHPBOOL.True: PHPBOOL.False
        Final.tif_compression = Scene?.params?.output_param?.tif_compression
        // Scene resolution
        Final.renderwidth = Scene.params.scene_param.width
        Final.renderheight = Scene.params.scene_param.height
        Final.job_type = JobType.Animation

        // If job created by job creation
        Final.send_from_scene_analyze = SceneAnalyzeStatus.SceneAnalyzed
        Final.scene_history_info_id =  RenderSceneJson.Analysis?.scene_analysis_history_id || 0

        // Map user configs
        // Map other params T_T
        // Moi tay qua T_T
        Final.camera = Scene.params.camera_param.activeCamera
        // Final.selected_cameras = [Scene.params.camera_param.activeCamera]
        Final.frame_per_task = RenderConfigs.frame_per_task
        Final.frame_steps = Scene.params.scene_param.incrementStep
        Final.start_frame = Scene.params.scene_param.startFrame
        Final.end_frame = Scene.params.scene_param.endFrame
        // Render Flags
        Final.rf_compute_advanced_lighting =  RenderConfigs.rf_compute_advanced_lighting
        Final.rf_convert_area_lights_to_points_sources=  RenderConfigs.rf_convert_area_lights_to_points_sources
        Final.rf_force_two_sided =  RenderConfigs.rf_force_two_sided
        Final.rf_perform_color_check =  RenderConfigs.rf_perform_color_check
        Final.rf_perform_displacement_mapping =  RenderConfigs.rf_perform_displacement_mapping
        Final.rf_perform_render_effects =  RenderConfigs.rf_perform_render_effects
        Final.rf_render_hidden_objects =  RenderConfigs.rf_render_hidden_objects
        Final.rf_toggles_output_dithering_paletted =  RenderConfigs.rf_toggles_output_dithering_paletted
        Final.rf_toggles_output_dithering_true_color =  RenderConfigs.rf_toggles_output_dithering_true_color
        Final.rf_use_advanced_lighting =  RenderConfigs.rf_use_advanced_lighting
        Final.rf_use_atmospherics_effects =  RenderConfigs.rf_use_atmospherics_effects
        Final.rf_use_super_black =  RenderConfigs.rf_use_super_black

        // Check for is need to using prepass render
        Final.mod_animation_prepass = PHPBOOL.False

        // This is analyzed scene,
        // so we need to remove some elements
        // delete Final.scene_info
        // delete Final.analyze_result

        Logger("PREPASS_RENDER:SUCCESS", Final.mod_animation_prepass )

        // Delete undefined params
        Object.keys(Final).forEach( (key) => Final[key as keyof HttpSceneBody] === undefined && delete Final[key as keyof HttpSceneBody])
        console.log(Final)
        // create scene
        const CREATE_JOB_API = `https://olapi.superrendersfarm.com/api/render-job/create/new`
        let Response = await axios.post(CREATE_JOB_API, Final , {
            headers : {
                Authorization : `Bearer ${localStorage.getItem("access_token")}`,
                "Content-Type" : "application/json"
            }
        })

        ipcRenderer.send(IpcSender.ShowMessageBox, {
            title : "Created render job"
        })

    // Catch exception when request couldn't be completed
    }catch (e) {
        alert(e)
    }
}
