import * as Aws from "aws-sdk";

// Local Storage Config
export const AUTHENTICATION_KEY_NAME = "access_token";

// Config Socket
export const SOCKET_IO_SERVER_URL = "https://socketio4.superrendersfarm.com/";

/**
 * Authentication
 */
export const UNAUTHENTICATION_ERROR_PATH = "/login";
export const AUTH_FAKER_TOKEN = `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvb2xhcGkuc3VwZXJyZW5kZXJzZmFybS5jb21cL2FwaVwvdXNlclwvbG9naW4iLCJpYXQiOjE2NDkyNDI2OTksImV4cCI6MTY1MTgzNDY5OSwibmJmIjoxNjQ5MjQyNjk5LCJqdGkiOiJSZGVad1hoa05LdW1QZXU4Iiwic3ViIjoxNzE4MiwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyIsInVzZXJfbmFtZSI6ImhhaW4iLCJlbWFpbCI6ImhhaW5Ac3VwZXJyZW5kZXJzZmFybS5jb20iLCJmdWxsX25hbWUiOiJIaW5hIiwiaXNfYWRtaW4iOnRydWUsImNyZWF0ZWRfYXQiOiIyMDIxLTA4LTMwVDA5OjI4OjE2LjAwMDAwMFoiLCJnb29nbGV0YWdfZGlzcGxheV9wZXJtaXNzaW9uIjowLCJzb2NrZXRfcHJpdmF0ZV9pZCI6IjFjZGUxYjliZTdhYmMxNzRiNWI5ZGFmNjJkYWM0N2I4Iiwic29ja2V0X3B1YmxpY19pZCI6IjRlY2E4MDg3ZmVmMDkxNzVmNTg5MzBiNzYzZDg1ODYwIn0.sb8ytrWsQFJ4dgC9tqW9ACwPOXPJx3Iw7QKwhENqVs0`;

/**
 * Config scene file supported
 */
export const SupportedSceneFileExtension: string[] = ["max", "ma", "mb", "houdini"];

// Config Cloud Storage Api
// AWS S3
export const AWS_CREDENTIALS = {
  accessKeyId: "AKIA3K6CKTAP4VARKJ7K",
  secretAccessKey: "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"
};
export const AWS_CONFIG_CREDENTIAL: Aws.Config = new Aws.Config({credentials: new Aws.Credentials(AWS_CREDENTIALS), region: "us-east-1"});

// GOOGLE DRIVE
export const GOOGLE_REFRESH_TOKEN = ``;
export const GOOGLE_CLIENT_ID = ``;
export const GOOGLE_ACCESS_TOKEN_KEY_NAME = "gaccess_token";
// DROPBOX
// WASABI

// Config apis
//

export const BASE_API_URL = `https://olapi.superrendersfarm.com/api/`;
export const API_REQUESTS_PARAM_CONFIG = {
  // Fetch jobs
  allJobs: {
    Limit: 2 // Maximum number of jobs to return
  },

  // Fetch Analysis
  allAnalysis: {
    Limit: 10 // Maximum number of jobs to return
  }
};

// Config farm specs
// Configs Deadline, Supported file extensions, Supported file types
export enum FARMS {
  SRF_SERVER_3 = "SRFSERVER3"
}

export enum DEADLINE_POOLS {
  GENERAL = "general_pool"
}

export enum SOFTWARES {
  AUTODESK_3DS_MAX = "3dsmax"
}

export enum SOFTWARES_VERSION {
  AUTODESK_3DS_MAX_2022 = "3dsmax_2022"
}

export enum RENDERER_VERSIONS {
  VRAY_5 = "vray_5"
}

export enum CLOUD_TYPES {
  AWS_S3 = "awss3"
}

export enum SOURCE_JOB_ADDRESS {
  WEB = "web",
  PLUGIN = "plugin"
}
